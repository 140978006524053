import { network } from '../../utils';

export const FETCH_LANGUAGES_BEGIN = 'FETCH_LANGUAGES_BEGIN';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAILURE = 'FETCH_LANGUAGES_FAILURE';
export const EDIT_USER_LANG_BEGIN = 'EDIT_USER_LANG_BEGIN';
export const EDIT_USER_LANG_SUCCESS = 'EDIT_USER_LANG_SUCCESS';
export const EDIT_USER_LANG_FAILURE = 'EDIT_USER_LANG_FAILURE';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

export const fetchLanguages = () =>
  network.fetchActionWrapper(`languages`, {
    begin: FETCH_LANGUAGES_BEGIN,
    success: FETCH_LANGUAGES_SUCCESS,
    failure: FETCH_LANGUAGES_FAILURE,
  });

export const updateUserLanguage = data =>
  network.fetchActionWrapper(
    'users/language',
    {
      begin: EDIT_USER_LANG_BEGIN,
      success: EDIT_USER_LANG_SUCCESS,
      failure: EDIT_USER_LANG_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const selectLanguage = code => ({
  type: SELECT_LANGUAGE,
  payload: {
    data: {
      langCode: code,
    },
  },
});
