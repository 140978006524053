import localForage from 'localforage';
import limits from '../constants/limits';

function shuffle(array) {
  const arrayCopy = [...array];
  for (let i = arrayCopy.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements arrayCopy[i] and arrayCopy[j]
    // we use "destructuring assignment" syntax to achieve that
    // you'll find more details about that syntax in later chapters
    // same can be written as:
    // let t = arrayCopy[i]; arrayCopy[i] = arrayCopy[j]; arrayCopy[j] = t
    // eslint-disable-next-line no-param-reassign
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }
  return arrayCopy;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function hasTermId(terms, termId) {
  for (let i = 0; i < terms.length; i += 1) {
    if (terms[i].id === termId) {
      return true;
    }
  }
  return false;
}

function getLessonTerms(sections, forGame = true) {
  if (!sections) {
    return [];
  }

  const terms = [];
  for (let i = 0; i < sections.length; i += 1) {
    if ((sections[i].display_type !== 'txt' && sections[i].display_type !== 'video') || !forGame) {
      if (sections[i].terms) {
        for (let j = 0; j < sections[i].terms.length; j += 1) {
          if (!hasTermId(terms, sections[i].terms[j].id)) {
            terms.push(sections[i].terms[j]);
          }
        }
      }
    }
  }
  return terms;
}

function preloadTerms(terms, component) {
  terms.forEach(term => {
    const newTerm = term;
    localForage.getItem(`${term.id}-image-rectangle`).then(imgMultimedia => {
      const hasImgMultimedia = !!imgMultimedia;
      newTerm.multimediaObjectUrl = imgMultimedia && imgMultimedia.url;

      if (hasImgMultimedia) {
        const img = new Image();
        img.src = imgMultimedia.url;
        img.onload = () => {
          component.setState(prevState => ({
            preloadedImgCounter: prevState.preloadedImgCounter + 1,
          }));
        };
      } else {
        component.setState(prevState => ({
          preloadedImgCounter: prevState.preloadedImgCounter + 1,
        }));
      }
    });

    localForage.getItem(`${term.id}-audio`).then(audio => {
      if (!audio) {
        component.setState(prevState => ({
          preloadedAudioCounter: prevState.preloadedAudioCounter + 1,
        }));
        return;
      }
      const AUDIO_EXTENSIONS = ['mp3', 'wav'];
      const AUDIO_MIMETYPES = {
        mp3: 'audio/mpeg',
        wav: 'audio/x-wav',
      };
      const urlExtension = audio.url.split('.').pop();
      const xhr = new XMLHttpRequest();
      const newUrl = `${audio.url}?${new Date().getTime()}`;
      xhr.open('get', newUrl, true);
      xhr.responseType = 'blob';
      // eslint-disable-next-line func-names,no-unused-vars
      xhr.onload = function(e) {
        if (this.status === 200) {
          let audioType = 'audio/mpeg';
          if (AUDIO_EXTENSIONS.includes(urlExtension)) {
            audioType = AUDIO_MIMETYPES[urlExtension];
          }
          const newBlob = new Blob([this.response], { type: audioType });
          // eslint-disable-next-line no-param-reassign
          term.audioObject = new Audio(URL.createObjectURL(newBlob));
          // eslint-disable-next-line no-param-reassign
          term.audioObject.onloadedmetadata = () => {
            component.setState(prevState => ({
              preloadedAudioCounter: prevState.preloadedAudioCounter + 1,
            }));
          };
        } else {
          component.setState(prevState => ({
            preloadedAudioCounter: prevState.preloadedAudioCounter + 1,
          }));
        }
      };
      xhr.send();
    });
    localForage.getItem(`${term.id}-video`).then(video => {
      if (!video) {
        component.setState(prevState => ({
          preloadedVideoCounter: prevState.preloadedVideoCounter + 1,
        }));
        return;
      }
      const xhr = new XMLHttpRequest();
      const newUrl = `${video.url}?${new Date().getTime()}`;
      xhr.open('get', newUrl, true);
      xhr.responseType = 'blob';
      // eslint-disable-next-line func-names,no-unused-vars
      xhr.onload = function(e) {
        if (this.status === 200) {
          const videoObject = document.createElement('video');
          const videoURL = URL.createObjectURL(this.response);
          videoObject.setAttribute('src', videoURL);
          // eslint-disable-next-line no-param-reassign
          term.multimediaObjectUrl = videoURL;
          // eslint-disable-next-line no-param-reassign
          term.videoObject = videoObject;
          component.setState(prevState => ({
            preloadedVideoCounter: prevState.preloadedVideoCounter + 1,
          }));
        } else {
          component.setState(prevState => ({
            preloadedVideoCounter: prevState.preloadedVideoCounter + 1,
          }));
        }
      };
      xhr.send();
    });
  });
}

function preloadSections(sections, component) {
  const newSections = sections;
  let sectionsAndTermsLength = 0;
  newSections.forEach(section => {
    if (section.terms) {
      sectionsAndTermsLength += section.terms.length;
    }
  });
  component.setState({
    preloadedSectionsAndTermsLength: sectionsAndTermsLength,
  });

  newSections.forEach(section => {
    if (section.terms) {
      preloadTerms(section.terms, component);
    }
  });
  component.setState({ preloadedSections: newSections });
}

function shuffleArray(array) {
  const newArray = array;
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

function dataURLToBlob(dataURL) {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = decodeURIComponent(parts[1]);

    return new Blob([raw], { type: contentType });
  }

  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}

function getSqlDateFormat(date) {
  const dateParts = date.split('-');
  // html date input saves date in YYYY-MM-DD format
  return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]} 00:00:00`;
}

function renderMessageModal(messageModal, message, confirmText) {
  messageModal.fire({
    type: 'error',
    text: message,
    confirmButtonColor: '#AF6098',
    showConfirmButton: true,
    confirmButtonText: confirmText,
  });
}

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  }
  return (
    navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)
  );
}

function isDailyLimitEnabled(attemptsObject, isRegularUser, dailyLimitEnabled) {
  return !!(attemptsObject !== null && isRegularUser && dailyLimitEnabled);
}

function renderLimitModal(limitModal, lessonInfo, lessonsAttempts, dailyLimit, modalType, t) {
  if (modalType !== 'startLimitModal') {
    limitModal.fire({
      type: 'info',
      title: t('messages.limitInfoTitle'),
      text: `${t('messages.limitInfo')} ${dailyLimit -
        1 -
        (!lessonInfo.isNew
          ? lessonsAttempts.oldLessonsAttempts
          : lessonsAttempts.newLessonAttempts)} ${t('messages.limitInfoEnd')}`,
      confirmButtonColor: '#AF6098',
      confirmButtonText: t('close'),
    });
    return true;
  }

  if (lessonsAttempts.oldLessonsAttempts >= dailyLimit && !lessonInfo.isNew) {
    limitModal.fire({
      type: 'warning',
      title: t('messages.limitTitle'),
      text: t('messages.oldLessonLimitReached'),
      confirmButtonColor: '#AF6098',
      confirmButtonText: t('close'),
    });
    return false;
  }

  if (
    (lessonsAttempts.newLessonAttempts >= dailyLimit || lessonInfo.canPlay === false) &&
    lessonInfo.isNew
  ) {
    limitModal.fire({
      type: 'warning',
      title: t('messages.limitTitle'),
      text: t('messages.newLessonLimitReached'),
      confirmButtonColor: '#AF6098',
      confirmButtonText: t('close'),
    });
    return false;
  }

  return true;
}

export function getProgressBarInfo(attempt, text) {
  return {
    text,
    label: `${attempt} / ${limits.DAILY_LIMIT}`,
    percentage: (attempt * 100) / limits.DAILY_LIMIT,
  };
}

export default {
  shuffle,
  getRandomInt,
  getLessonTerms,
  shuffleArray,
  dataURLToBlob,
  preloadSections,
  preloadTerms,
  getSqlDateFormat,
  renderMessageModal,
  isIOS,
  isDailyLimitEnabled,
  renderLimitModal,
  getProgressBarInfo,
};
