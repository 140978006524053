import PropTypes from 'prop-types';
import React from 'react';

import './index.css';

const FONT = {
  lesson: {
    large: 15,
    medium: 10,
    small: 5,
  },
  game: {
    large: 5,
    medium: 4,
    small: 2.5,
  },
};

const WORD_LENGTH_LIMIT = {
  small: 10,
  medium: 18,
};

class Term extends React.Component {
  handleClick = () => {
    const { onClick, index } = this.props;
    if (onClick && typeof onClick === 'function') {
      onClick(index);
    }
  };

  getContainerClassName = () => {
    const { renderType, onClick, originalType, allowClick } = this.props;
    const clickable = onClick && typeof onClick === 'function';
    let containerClassName;
    if (renderType === 'img/drop') {
      containerClassName = 'term--img-drop';
    } else if (renderType === 'txt') {
      containerClassName = 'term--txt';
    } else if (renderType === 'video') {
      containerClassName = 'term--video';
    } else {
      containerClassName = 'term';
    }

    if (clickable && allowClick) {
      containerClassName = `${containerClassName} term--clickable`;
    }
    if (!allowClick) {
      containerClassName = `${containerClassName} term--unclickable`;
    }
    if (originalType === 'txt') {
      containerClassName = `${containerClassName} term--first-letter-red`;
    }

    return containerClassName;
  };

  getLabelFontSize = (collectionSize, labelLength) => {
    const fontSize = collectionSize === 1 ? FONT.lesson : FONT.game;
    const fontSizeUnit = collectionSize === 1 ? 'vw' : 'em';
    const labelStyle = {};

    if (labelLength <= WORD_LENGTH_LIMIT.small) {
      labelStyle.fontSize = `${fontSize.large}${fontSizeUnit}`;
    } else if (WORD_LENGTH_LIMIT.small < labelLength && labelLength <= WORD_LENGTH_LIMIT.medium) {
      labelStyle.fontSize = `${fontSize.medium}${fontSizeUnit}`;
    } else {
      labelStyle.fontSize = `${fontSize.small}${fontSizeUnit}`;
    }

    return labelStyle;
  };

  renderLabelChar = (char, i) => (
    <span className={this.props.highlightedChars[i] === '1' ? 'highlighted' : ''} key={i}>
      {char}
    </span>
  );

  renderContent = () => {
    const { multimediaObjectUrl, collectionSize, nextTerm, renderType, label } = this.props;
    switch (renderType) {
      case 'img':
        if (!multimediaObjectUrl) {
          return null;
        }
        return (
          <div
            className="term__content--img"
            style={{ backgroundImage: `url(${multimediaObjectUrl})` }}
          />
        );

      case 'video':
        if (!multimediaObjectUrl) {
          return null;
        }
        return (
          <video
            className="term__content--video"
            onEnded={nextTerm}
            autoPlay
            preload
            muted
            playsinline
          >
            <source src={multimediaObjectUrl} type="video/mp4" />
          </video>
        );

      case 'txt':
      default:
        return (
          <p
            className="term__content--txt"
            style={this.getLabelFontSize(collectionSize, label.length)}
          >
            {[...label].map(this.renderLabelChar)}
          </p>
        );
    }
  };

  render() {
    const { collectionSize } = this.props;
    const style = {
      height: collectionSize > 2 ? '50%' : '100%',
      width: collectionSize > 1 ? '40%' : '90%',
    };

    return (
      <div
        className={this.getContainerClassName()}
        style={style}
        onClick={this.handleClick}
        onKeyPress={this.handleClick}
      >
        {this.renderContent()}
      </div>
    );
  }
}

Term.propTypes = {
  label: PropTypes.string.isRequired,
  multimediaObjectUrl: PropTypes.string.isRequired,
  highlightedChars: PropTypes.string.isRequired,
  renderType: PropTypes.oneOf(['img', 'img/txt', 'img/drop', 'txt', 'video']),
  collectionSize: PropTypes.number,
  onClick: PropTypes.func,
  nextTerm: PropTypes.func,
};

Term.defaultProps = {
  renderType: 'img',
  collectionSize: 1,
  onClick: null,
  nextTerm: null,
};

export default Term;
