import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Term from '../Term';
import { lessonHistory } from '../../utils';
import './index.css';

import logo from '../../images/logo.svg';
import { createUserPlayedSectionEntry } from '../../actions/sections';

const TERM_DURATION = 1700;
const PAUSE_DURATION = 1000;

export class Section extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      activeTermIndex: -1,
      type: 'txt',
    };
  }

  componentWillMount() {
    const { type } = this.props;
    this.initSectionState(type);
  }

  componentWillReceiveProps(nextProps) {
    const { terms } = this.props;
    if (terms !== nextProps.terms) {
      this.initSectionState(nextProps.type);
    }
  }

  initSectionState = type => {
    this.setState(
      {
        activeTermIndex: -1,
        type: type === 'img/txt' ? 'img' : type,
      },
      this.startSection,
    );
  };

  playAudio = async () => {
    const { activeTermIndex, type } = this.state;
    const { terms, type: originalType } = this.props;

    const isEnd = activeTermIndex === terms.length;
    if (isEnd) {
      return;
    }

    const hasAudio = !!terms[activeTermIndex].audioObject;
    const hasVideo = !!terms[activeTermIndex].videoObject;
    const audio = hasAudio && terms[activeTermIndex].audioObject;
    if (hasVideo) {
      // const video = terms[activeTermIndex].videoObject;
      // video.onloadedmetadata never fires on safari, so need to use audio
      const videoDurationInMs = audio.duration * 1000;
      this.resetTimerInterval(videoDurationInMs);

      if (hasAudio) {
        audio.play();
      }
      return;
    }

    if (!hasAudio) {
      this.resetTimerInterval(TERM_DURATION);
      return;
    }

    if (audio) {
      const activeTermAudioDuration = audio.duration * 1000; // in milliseconds
      let newTimerDuration = 0;

      if (type === 'txt' && originalType === 'img/txt') {
        newTimerDuration = activeTermAudioDuration;
      } else {
        newTimerDuration =
          activeTermAudioDuration <= TERM_DURATION ? TERM_DURATION : activeTermAudioDuration;
      }
      this.resetTimerInterval(newTimerDuration);
      audio.play();
    }
  };

  startSection = () => {
    const { type: originalType, terms, nextSection, dispatch, id } = this.props;

    dispatch(createUserPlayedSectionEntry({ section_id: id }));

    if (terms.length === 0) {
      clearInterval(this.timer);
      nextSection();
      return;
    }

    this.getNextTerm();
    if (originalType !== 'video' && originalType !== 'img/txt') {
      this.timer = setInterval(() => this.getNextTerm(), TERM_DURATION);
    }
  };

  saveTermsToCookie = () => {
    const { lessonId, id, terms } = this.props;
    const sectionKey = `${lessonId}_${id}`;
    const termsIds = terms.map(term => term.id);

    lessonHistory.setSectionHistory(sectionKey, termsIds);
  };

  resetTimerInterval = intervalDuration => {
    clearInterval(this.timer);
    this.timer = setInterval(() => this.getNextTerm(), intervalDuration);
  };

  getNextTerm = () => {
    const { activeTermIndex, type } = this.state;
    const { terms, nextSection, type: originalType } = this.props;
    const newTermIndex = activeTermIndex + 1;

    if (originalType === 'img/txt' && newTermIndex !== terms.length + 1) {
      if (type === 'txt') {
        this.setState({ type: 'img' });
        this.resetTimerInterval(TERM_DURATION);
        return;
      }
      this.setState({ type: 'txt' });
    }

    if (newTermIndex <= terms.length) {
      this.setState({ activeTermIndex: newTermIndex }, this.playAudio);
      if (newTermIndex === terms.length) {
        this.resetTimerInterval(PAUSE_DURATION);
      }
    } else {
      this.saveTermsToCookie();
      clearInterval(this.timer);
      nextSection();
    }
  };

  render() {
    const { terms, type: originalType, savedMultimedia } = this.props;
    const { activeTermIndex, type } = this.state;

    if (activeTermIndex >= 0 && activeTermIndex < terms.length) {
      return (
        <div className="section">
          <Term
            {...terms[activeTermIndex]}
            savedMultimedia={savedMultimedia}
            originalType={originalType}
            renderType={type}
            nextTerm={this.getNextTerm}
          />
        </div>
      );
    }
    if (type === 'video' && activeTermIndex === terms.length) {
      clearInterval(this.timer);
      this.timer = setTimeout(this.getNextTerm, PAUSE_DURATION);
    }

    return (
      <div className="section">
        <img alt="Pauza" className="section__pause" src={logo} />
      </div>
    );
  }
}

Section.propTypes = {
  terms: PropTypes.array.isRequired,
  nextSection: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  lessonId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  savedMultimedia: PropTypes.array,
};

export default connect()(Section);
