import React from 'react';

import './index.css';
import PropTypes from 'prop-types';

function ProgressBar(props) {
  const { info, containerClassName, labelClassName } = props;

  return (
    <div className={containerClassName}>
      <div className={labelClassName}>{info.label}</div>
      <div
        className={`progress-bar bg-${
          info.percentage <= 34 ? 'success' : info.percentage <= 67 ? 'warning' : 'danger'
        }`}
        role="progressbar"
        style={{ width: `${info.percentage}%` }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  info: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default ProgressBar;
