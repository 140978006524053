import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

function RewardModal({ children, month, label, onExit }) {
  return (
    <div className="modal">
      <div className="modal__bg">
        <button type="button" className="modal__bg__exit" onClick={onExit}>
          x
        </button>
        <div className="modal__bg__month">{month}</div>
        <div className="modal__bg__label">{label}</div>
        <div className="modal__reward">{children}</div>
      </div>
    </div>
  );
}

RewardModal.propTypes = {
  month: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
};

export default RewardModal;
