import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { withNamespaces } from 'react-i18next';

import './index.css';

class CropperModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  closeCropperModal = () => {
    this.setState({ loading: true });
    setTimeout(this.props.onCropFinished, 100);
  };

  render() {
    const { aspectRatioW, aspectRatioH, src, onCropCancel, t, cropperRef } = this.props;
    const { loading } = this.state;

    return (
      <div className="modal">
        {loading && <div className="modal__loading-message">{t('messages.loadingImage')}</div>}
        <div className="modal__loading" />
        <Cropper
          className="modal__cropper"
          ref={cropperRef}
          src={src}
          aspectRatio={aspectRatioW / aspectRatioH}
          viewMode={0}
          dragMode="move"
          guides={false}
          center={false}
          background={false}
          movable={false}
          zoomable={false}
          rotatable={false}
          zoomOnWheel={false}
          zoomOnTouch={false}
        />
        {!loading && (
          <div className="modal__buttons">
            <button
              type="button"
              className="modal__buttons__btn-finish"
              onClick={this.closeCropperModal}
            >
              {t('myMultimedia.wizard.finish')}
            </button>
            <button type="button" className="modal__buttons__btn-cancel" onClick={onCropCancel}>
              {t('myMultimedia.wizard.cancel')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

CropperModal.propTypes = {
  aspectRatioW: PropTypes.number.isRequired,
  aspectRatioH: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  onCropFinished: PropTypes.func.isRequired,
  onCropCancel: PropTypes.func.isRequired,
  cropperRef: PropTypes.object.isRequired,
};

export default withNamespaces('translation')(CropperModal);
