import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import Loading from '../common/Loading';
import CacheBuster from '../common/CacheBuster';
import { hideInfoModal, hideMessageModal } from '../../actions/app';
import CustomMultimediaWizardScreen from '../../containers/CustomMultimediaWizardScreen';
import { logout, updateFirstLogin, setEmail, editUser, clearMessage } from '../../actions/auth';
import { LOGOUT_BTN_MESSAGES, RETRY_BTN_MESSAGES } from '../../constants/errors';
import auth from '../../utils/auth';
import EmailPromptModal from '../EmailPromptModal';
import InfoModal from '../InfoModal';
import roles from '../../constants/roles';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.messageModal = withReactContent(Swal);
  }

  closeInfoModal = data => {
    const { username, email, firstName, lastName, telephoneNumber, dispatch } = this.props;
    dispatch(
      editUser({
        username,
        email,
        firstName,
        lastName,
        telephoneNumber,
        isAppleUser: data,
      }),
    ).then(() => dispatch(clearMessage()));
    dispatch(hideInfoModal());
  };

  closeMessageModal = () => {
    this.props.dispatch(hideMessageModal());
  };

  logout = () => this.props.dispatch(logout());

  reloadPage = () => window.location.reload();

  onExitCustomMultimediaWizard = () => {
    const { dispatch } = this.props;

    dispatch(updateFirstLogin(0));
  };

  handleEmailInputChange = ({ target }) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ email: target.value });
  };

  handleEmailSubmit = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { email } = this.state;

    dispatch(setEmail(email));
  };

  renderMessageModal = (retryBtn, type, message) => {
    const { t } = this.props;

    this.messageModal
      .fire({
        type,
        html: message,
        onClose: this.closeMessageModal,
        confirmButtonColor: '#AF6098',
        showConfirmButton: retryBtn,
        confirmButtonText: t('retry'),
        showCancelButton: true,
        cancelButtonText: t('close'),
      })
      .then(result => {
        if (result.value) {
          this.reloadPage();
        }
      });
  };

  render() {
    const {
      isLoading,
      errors,
      displayError,
      displayInfoModal,
      isFirstLogin,
      isEmailSet,
      t,
    } = this.props;
    const customMultimediaWizardWelcomeMessage = t(`messages.customMultimediaWizardWelcomeMessage`);
    const customMultimediaWizardInstructionsMessage = t(
      `messages.customMultimediaWizardInstructions`,
    );

    const messages = errors && errors.map(error => t([`error.${error}`, `error.genericError`]));

    // if message is in logout messages it means that user should be logged out so we do it for him
    const shouldLogout = errors && LOGOUT_BTN_MESSAGES.some(message => errors.includes(message));

    if (shouldLogout) {
      this.logout();
    }

    const retryBtn = errors && RETRY_BTN_MESSAGES.some(message => errors.includes(message));
    const message = messages && messages.join('<br>');

    if (displayError) {
      this.renderMessageModal(retryBtn, 'error', message);
    }

    const showEmailPromptModal =
      !isLoading &&
      !isEmailSet &&
      auth.isAuthed() &&
      auth.isRole([roles.ADMIN_ROLE, roles.REGULAR_ROLE]);
    const showInfoModal =
      !isLoading &&
      displayInfoModal &&
      isEmailSet &&
      auth.isAuthed() &&
      auth.isRole([roles.ADMIN_ROLE, roles.REGULAR_ROLE, roles.DEMO_ROLE]);
    const showCustomMultimediaWizardScreen =
      !isLoading && isFirstLogin && isEmailSet && !displayInfoModal && auth.isAuthed();

    return (
      <div>
        <Loading show={isLoading} />
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
            return null;
          }}
        </CacheBuster>
        {auth.isAuthed() && (
          <EmailPromptModal
            show={showEmailPromptModal}
            message={t(`auth.setEmailPrompt`)}
            inputType="email"
            inputName="setEmail"
            inputPlaceholder={t(`auth.setEmailPlaceholder`)}
            onControlChange={this.handleEmailInputChange}
            submitButtonName={t(`auth.setEmailSubmitButton`)}
            onSubmit={this.handleEmailSubmit}
          />
        )}
        {auth.isAuthed() && (
          <CustomMultimediaWizardScreen
            show={showCustomMultimediaWizardScreen}
            initialUiState={CustomMultimediaWizardScreen.uiStates.INSTRUCTIONS_PROMPT}
            instructionsMessage={customMultimediaWizardInstructionsMessage}
            onExit={this.onExitCustomMultimediaWizard}
          />
        )}
        {auth.isAuthed() && (
          <InfoModal
            show={showInfoModal}
            title={t(`messages.infoModalWelcomeMessage`)}
            messageInstructions={t(`messages.infoModalMessageInstructions`)}
            messageLimits={!auth.isRole([roles.DEMO_ROLE]) ? t(`messages.infoModalMessageLimits`) : ''}
            messageWelcomeWizard={isFirstLogin ? customMultimediaWizardWelcomeMessage : ''}
            messageApple={t(`auth.isAppleUser`)}
            messageEnd={t(`messages.infoModalMessageEnd`)}
            isAppleUser={this.props.isAppleUser}
            onClose={this.closeInfoModal}
          />
        )}
      </div>
    );
  }
}

App.propTypes = {
  isLoading: PropTypes.bool,
  errors: PropTypes.object,
  displayError: PropTypes.bool,
  displayInfoModal: PropTypes.bool,
  isFirstLogin: PropTypes.bool,
  isEmailSet: PropTypes.bool,
};

const mapStateToProps = state => ({
  isLoading: state.app.loading,
  errors: state.app.errors,
  displayError: state.app.displayError,
  displayInfoModal: state.app.displayInfoModal,
  isFirstLogin: state.auth.isFirstLogin,
  isEmailSet: state.auth.isEmailSet,
  username: state.auth.username,
  email: state.auth.email,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  telephoneNumber: state.auth.telephoneNumber,
  isAppleUser: state.auth.isAppleUser,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(App);
