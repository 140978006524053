function isTermHidden(term) {
  const hasCustomMultimedia = term && term.customMultimedia;
  let isHidden = false;

  if (hasCustomMultimedia) {
    // eslint-disable-next-line radix
    if (parseInt(term.customMultimedia.isHidden)) {
      isHidden = true;
    }
  }

  return isHidden;
}

export default {
  isTermHidden,
};
