import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { animated, Transition } from 'react-spring';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import isEmpty from 'lodash.isempty';
import InlineProgressBar from '../../components/InlineProgressBar';
import Lesson from '../../components/Lesson';
import CardList from '../../components/common/CardList';
import Card from '../../components/common/Card';
import Gameboard from '../../components/Gameboard';
import { common } from '../../utils';
import { fetchSectionTypes } from '../../actions/sections';
import { fetchAttempts } from '../../actions/lessonsAttempts';

import { fetchLesson, preloadSingleLessonAsset } from '../../actions/lessons';
import sectionImages from './sectionImages';
import sectionColors from './sectionColors';
import sectionConstants from '../../constants/sections';
import multimediaTypes from '../../constants/multimediaTypes';
import roles from '../../constants/roles';
import limits from '../../constants/limits';
import auth from '../../utils/auth';
import Forbidden403 from '../../staticComponents/Forbidden403';

import './index.css';

class LessonScreen extends React.Component {
  constructor(props) {
    super(props);

    const passedLevelsCount = props.lesson.gameProgress.match(/1/g);
    this.state = {
      playGame: false,
      selectedSectionTypeId: null,
      playLesson:
        props.lesson &&
        !((passedLevelsCount !== null ? passedLevelsCount.length : 0) > 0) &&
        !auth.isRole([roles.ADMIN_ROLE]),
    };

    this.limitExceeded = withReactContent(Swal);
  }

  componentDidMount() {
    const { dispatch, lesson, match, sectionTypes } = this.props;

    document.body.className = 'auth__bg';

    if (!sectionTypes || !sectionTypes.length) {
      dispatch(fetchSectionTypes());
    }

    if (!lesson || lesson.id !== match.params.lessonId) {
      dispatch(fetchLesson(match.params.lessonId))
        // this.props.lesson must stay like this because it gets
        // latest updated version of lessons from props
        .then(() => {
          // eslint-disable-next-line no-unused-expressions
          this.props.lesson &&
            this.preloadSectionsAssets(this.props.lesson.id, this.props.lesson.sections);
        });
    }
    if (auth.isRole([roles.REGULAR_ROLE])) {
      dispatch(fetchAttempts());
    }
  }

  componentWillReceiveProps(nextProps) {
    const { lesson } = this.props;

    const passedLevelsCount = nextProps.lesson.gameProgress.match(/1/g);
    if (lesson !== nextProps.lesson) {
      this.setState({
        playLesson: !(passedLevelsCount !== null ? passedLevelsCount.length : 0) > 0,
      });
    }
  }

  preloadSectionsAssets = (lessonId, sections) => {
    const { dispatch } = this.props;
    const multimediaUrls = [];

    common.getLessonTerms(sections, false).forEach(term => {
      if (term.multimedia) {
        const imgRectMultimedia = term.multimedia.find(
          m => m.type === multimediaTypes.IMAGE_RECTANGLE,
        );
        const audioMultimedia = term.multimedia.find(m => m.type === multimediaTypes.AUDIO);
        const videoMultimedia = term.multimedia.find(m => m.type === multimediaTypes.VIDEO);

        if (imgRectMultimedia) {
          multimediaUrls.push({
            ...imgRectMultimedia,
            termId: term.id,
          });
        }
        if (audioMultimedia) {
          multimediaUrls.push({
            ...audioMultimedia,
            termId: term.id,
          });
        }
        if (videoMultimedia) {
          multimediaUrls.push({
            ...videoMultimedia,
            termId: term.id,
          });
        }
      }
    });
    dispatch(preloadSingleLessonAsset(lessonId, multimediaUrls));
  };

  goBack = () => this.props.history.goBack();

  getCurrentLessonAttempts = lessonId => {
    const { lessonsAttempts } = this.props;

    if (isEmpty(lessonsAttempts)) {
      return null;
    }

    const currentLesson = lessonsAttempts.lessonsInfo.find(lesson => lesson.lessonId === lessonId);

    return currentLesson;
  };

  startLesson = () => {
    const { t, lessonsAttempts } = this.props;
    const lessonInfo = this.getCurrentLessonAttempts(this.props.lesson.id);

    if (
      common.isDailyLimitEnabled(
        lessonInfo,
        auth.isRole([roles.REGULAR_ROLE]),
        lessonsAttempts.isDailyLimitEnabled,
      )
    ) {
      const proceed = common.renderLimitModal(
        this.limitExceeded,
        lessonInfo,
        lessonsAttempts,
        limits.DAILY_LIMIT,
        'startLimitModal',
        t,
      );
      if (!proceed) {
        return;
      }
    }

    this.setState({
      playLesson: true,
      playGame: false,
    });
  };

  startGame = () => {
    this.setState({
      playLesson: false,
      playGame: true,
    });
  };

  onLessonEnded = () => {
    const { selectedSectionTypeId } = this.state;
    const { t, lessonsAttempts, dispatch } = this.props;

    if (!auth.isRole([roles.ADMIN_ROLE, roles.DEMO_ROLE])) dispatch(fetchAttempts());

    const lessonInfo = this.getCurrentLessonAttempts(this.props.lesson.id);

    if (!selectedSectionTypeId) {
      if (
        common.isDailyLimitEnabled(
          lessonInfo,
          auth.isRole([roles.REGULAR_ROLE]),
          lessonsAttempts.isDailyLimitEnabled,
        )
      ) {
        common.renderLimitModal(
          this.limitExceeded,
          lessonInfo,
          lessonsAttempts,
          limits.DAILY_LIMIT,
          'endLimitModal',
          t,
        );
      }
      this.startGame();
      return;
    }

    // reset state when lesson ends
    this.setState({
      playGame: false,
      playLesson: false,
      selectedSectionTypeId: null,
    });
  };

  startSectionType = typeId => this.setState({ selectedSectionTypeId: typeId, playLesson: true });

  renderSectionsByType = (type, idx) => (
    <Card
      key={type.id}
      onClick={() => this.startSectionType(type.id)}
      label={this.props.t(`lesson.sectionType.${type.label}`)}
      img={sectionImages[(idx + 1).toString()]}
      bgColor={sectionColors[idx + 1].primary}
      bgColorLighter={sectionColors[idx + 1].primaryLighter}
    />
  );

  checkIfProgressBarCanRender = (lessonAttempts, lessonId) => {
    const { t } = this.props;

    if (isEmpty(lessonAttempts)) {
      return null;
    }

    return lessonAttempts.lessonsInfo.find(les => les.lessonId === lessonId).isNew
      ? common.getProgressBarInfo(
          lessonAttempts.newLessonAttempts,
          t('progressBars.newLessonLimit'),
        )
      : null;
  };

  render() {
    const { lesson, monthId, sectionTypes, t, lessonsAttempts, isAppleUser } = this.props;
    const { playGame, playLesson, selectedSectionTypeId } = this.state;

    if (!lesson.lessonEnabled && !auth.isRole([roles.ADMIN_ROLE]) && !lesson.demo) {
      return <Forbidden403 />;
    }

    if (!lesson || !(lesson.sections && lesson.sections.length)) {
      return null;
    }

    if (playLesson) {
      let selectedSections = lesson.sections;

      if (selectedSectionTypeId) {
        selectedSections = selectedSections.filter(
          section => section.sectionTypeId === selectedSectionTypeId,
        );
      }

      return (
        <Lesson
          onLessonEnded={this.onLessonEnded}
          {...lesson}
          sections={selectedSections}
          monthId={monthId}
          isReproduceAll={!selectedSectionTypeId}
          isDailyLimitEnabled={lessonsAttempts.isDailyLimitEnabled}
        />
      );
    }
    if (playGame) {
      const selectedSections = lesson.sections;
      const flashesSections = selectedSections.filter(
        section => section.label === sectionConstants.SECTION_TYPE.FLASHES,
      );

      return (
        <Gameboard
          navigation={this.props.navigation}
          lessonId={lesson.id}
          lessonGameProgress={lesson.gameProgress}
          lessonPuzzleImgUrl={lesson.puzzleImgUrl}
          monthId={monthId}
          isAppleUser={isAppleUser}
          terms={common.getLessonTerms(flashesSections)}
          gameProgress={lesson.gameProgress}
        />
      );
    }

    return (
      <Transition native from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
        {() => props => (
          <div>
            <InlineProgressBar
              progressBarInfo={this.checkIfProgressBarCanRender(lessonsAttempts, lesson.id)}
            />
            <animated.div style={props} className="lesson-screen">
              <div className="lesson-screen__side-content">
                <h3 className="lesson-screen__side-content-title">{lesson.label}</h3>
                <Card
                  onClick={this.startLesson}
                  label={t('lesson.playAll')}
                  img={sectionImages.all}
                  bgColor={sectionColors.all.primary}
                  bgColorLighter={sectionColors.all.primaryLighter}
                  imgBgColor="#FE6263"
                />
                <button
                  type="button"
                  className="lesson-screen__side-content-back-btn"
                  onClick={this.goBack}
                >
                  {t('back')}
                </button>
              </div>

              <CardList>
                {sectionTypes
                  .filter(
                    s =>
                      s.label !== sectionConstants.SECTION_TYPE.VIDEO &&
                      s.label !== sectionConstants.SECTION_TYPE.TITLE,
                  )
                  .map(this.renderSectionsByType)}
                <Card
                  onClick={this.startGame}
                  label={t('game.label')}
                  img={sectionImages.game}
                  bgColor={sectionColors.game.primary}
                  bgColorLighter={sectionColors.game.primaryLighter}
                />
              </CardList>
            </animated.div>
          </div>
        )}
      </Transition>
    );
  }
}

const mapStateToProps = state => {
  const { months } = state.lessons;

  const allLessons = [];
  Object.entries(months).forEach(([, lessonsItems]) =>
    lessonsItems.map(item => allLessons.push(item.lesson)),
  );

  const selectedLesson = allLessons.find(lesson => lesson.id === state.lessons.selectedId);

  return {
    lesson: selectedLesson,
    lessonsGameProgress: selectedLesson && selectedLesson.gameProgress,
    monthId: state.months.selectedId,
    sectionTypes: state.sections.item_types,
    lessonsAttempts: state.lessonsAttempts.attemptsInfo,
    isAppleUser: state.auth.isAppleUser,
  };
};

export default withRouter(
  compose(withNamespaces('translation'), connect(mapStateToProps))(LessonScreen),
);
