import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SettingsList from '../../components/SettingsList';
import INSTRUCTIONS_SETTINGS from '../../constants/instructions';
import Instruction from '../../components/Instruction';
import { showNavigationBar } from '../../actions/app';
import ScrollToTop from '../../components/ScrollToTop';

import './index.css';

class InstructionsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstruction: 'WHAT_IS_HIPER',
    };

    props.dispatch(showNavigationBar('instructions'));
  }

  selectSetting = settingKey => {
    this.setState({ currentInstruction: settingKey });
  };

  render() {
    const { t } = this.props;
    const { currentInstruction } = this.state;

    const settingsTranslated = Object.keys(INSTRUCTIONS_SETTINGS).map(key => ({
      key,
      name: t(`instructions.${INSTRUCTIONS_SETTINGS[key].path}.header`),
    }));

    return (
      <>
        <ScrollToTop />
        <div className="instructions-screen">
          <SettingsList
            className="instructions-screen__menu"
            currentSetting={currentInstruction}
            settings={settingsTranslated}
            onSettingClick={this.selectSetting}
          />
          <div className="instructions-screen__cards scrollbar">
            {INSTRUCTIONS_SETTINGS[currentInstruction].settings.map(cardSettings => (
              <Instruction
                key={currentInstruction}
                path={INSTRUCTIONS_SETTINGS[currentInstruction].path}
                cardSettings={cardSettings}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default compose(withNamespaces('translation'), connect())(InstructionsScreen);
