import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';

import isEmpty from 'lodash.isempty';
import InlineProgressBar from '../../components/InlineProgressBar';
import LessonsScreen from '../LessonsScreen';
import MonthsScreen from '../MonthsScreen'; // eslint-disable-line import/no-named-as-default
import { showNavigationBar } from '../../actions/app';
import auth from '../../utils/auth';
import { getProgressBarInfo } from '../../utils/common';
import roles from '../../constants/roles';
import { fetchAttempts } from '../../actions/lessonsAttempts';

import './index.css';

export class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    props.dispatch(showNavigationBar('home'));
  }

  componentWillMount() {
    const { dispatch, history, lang } = this.props;

    if (!auth.isAuthed()) {
      history.replace(`${lang ? `/${lang}` : ''}/login`);
    } else {
      dispatch(showNavigationBar('home'));
      if (auth.isRole([roles.REGULAR_ROLE])) {
        dispatch(fetchAttempts());
      }
    }
  }

  componentDidMount() {
    document.body.className = 'home__bg';
  }

  render() {
    const { lessonsAttempts, t } = this.props;

    if (!auth.isAuthed()) {
      return null;
    }

    const progressBarInfo =
      !isEmpty(lessonsAttempts) && !lessonsAttempts.lessonsInfo[0].isNew
        ? getProgressBarInfo(lessonsAttempts.oldLessonsAttempts, t('progressBars.oldLessonsLimit'))
        : null;

    return (
      <div>
        <InlineProgressBar progressBarInfo={progressBarInfo} />
        <div className="home">
          <MonthsScreen />
          <LessonsScreen />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  selectedMonthId: state.months.selectedId,
  lang: state.auth.lang,
  lessonsAttempts: state.lessonsAttempts.attemptsInfo,
});

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(withRouter(HomeScreen));
