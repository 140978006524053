import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class SettingsList extends React.PureComponent {
  render() {
    const { settings, onSettingClick, currentSetting, className = 'settings-list' } = this.props;

    return (
      <ul className={className}>
        {settings.map(setting => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            className={`settings-list__item ${
              currentSetting === setting.key ? 'settings-list__item--selected' : ''
            }`}
            onClick={() => onSettingClick(setting.key)}
            onKeyPress={() => onSettingClick(setting.key)}
          >
            {setting.name}
          </li>
        ))}
      </ul>
    );
  }
}

SettingsList.propTypes = {
  settings: PropTypes.array.isRequired,
  onSettingClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SettingsList;
