import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Line, Doughnut } from 'react-chartjs-2';
import moment from 'moment';

import isEmpty from 'lodash.isempty';
import { common } from '../../utils';
import TitleProgressBar from '../../components/TitleProgressBar';
import { dailyOverview, currentDoughnut, overallDoughnut } from './chartsConfigs';
import { fetchScores } from '../../actions/stats';
import { showNavigationBar } from '../../actions/app';
import './index.css';

class StatsScreen extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchScores());
    dispatch(showNavigationBar('stats'));
  }

  translateCharts = () => {
    const { t } = this.props;
    const charts = [dailyOverview, currentDoughnut, overallDoughnut];

    charts.forEach(chart => {
      if (chart.type === 'doughnut') {
        // eslint-disable-next-line no-param-reassign
        chart.data.labels = [t(chart.translation.labels[0]), t(chart.translation.labels[1])];
      } else if (chart.type === 'line') {
        // eslint-disable-next-line no-param-reassign
        chart.options.title.text = t(chart.translation.title);
        // eslint-disable-next-line no-param-reassign
        chart.data.datasets[0].label = t(chart.translation.label);
      }
    });
  };

  render() {
    const {
      totalLessons,
      lessonsPassed,
      totalCurrentMonthLessons,
      lessonsPassedCurrentMonth,
      dailyScores,
      lessonsAttempts,
      t,
    } = this.props;

    overallDoughnut.data.datasets[0].data = [lessonsPassed, totalLessons - lessonsPassed];
    currentDoughnut.data.datasets[0].data = [
      lessonsPassedCurrentMonth,
      totalCurrentMonthLessons - lessonsPassedCurrentMonth,
    ];
    dailyOverview.data.datasets[0].data = dailyScores.map(dailyScore => dailyScore.count);
    dailyOverview.data.labels = dailyScores.map(dailyScore => {
      const date = moment(dailyScore.dateCreated).format('DD.MM.YYYY.');
      return date;
    });

    this.translateCharts();

    const progressBarOldLessonsInfo = !isEmpty(lessonsAttempts)
      ? common.getProgressBarInfo(
          lessonsAttempts.oldLessonsAttempts,
          t('progressBars.oldLessonsLimit'),
        )
      : null;
    const progressBarNewLessonInfo = !isEmpty(lessonsAttempts)
      ? common.getProgressBarInfo(
          lessonsAttempts.newLessonAttempts,
          t('progressBars.newLessonLimit'),
        )
      : null;

    return (
      <div className="stats">
        <div className="stats__doughnuts">
          <div className="stats__doughnuts-chart">
            <p>{t(currentDoughnut.translation.title)}</p>
            <div>
              <Doughnut data={currentDoughnut.data} options={currentDoughnut.options} />
            </div>
          </div>
          <div className="stats__doughnuts-chart">
            <p className="stats__doughnuts-chart--title">{t(overallDoughnut.translation.title)}</p>
            <div>
              <Doughnut data={overallDoughnut.data} options={overallDoughnut.options} />
            </div>
          </div>
        </div>
        <div>
          <TitleProgressBar progressBarInfo={progressBarOldLessonsInfo} />
          <TitleProgressBar progressBarInfo={progressBarNewLessonInfo} />
        </div>
        <div className="stats__line">
          <Line data={dailyOverview.data} options={dailyOverview.options} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.stats,
  lessonsAttempts: state.lessonsAttempts.attemptsInfo,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(StatsScreen);
