/* eslint no-case-declarations: 0 */
import {
  FETCH_SECTIONS_SUCCESS,
  SELECT_SECTION,
  CREATE_SECTION_SUCCESS,
  FETCH_SECTION_TYPES_SUCCESS,
  DELETE_SECTION_SUCCESS,
  FETCH_SECTION_SUCCESS,
  EDIT_SECTION_SUCCESS,
} from '../../actions/sections';

const initialState = {
  items: [],
  selectedId: null,
  item_types: [],
};

export default function sectionReducer(state = initialState, action) {
  let currentSections = [];
  let sectionIndex = null;

  switch (action.type) {
    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      };

    case SELECT_SECTION:
      return {
        ...state,
        selectedId: action.id,
      };

    case FETCH_SECTION_SUCCESS:
      currentSections = [...state.items];
      sectionIndex = currentSections.findIndex(item => item.id === action.payload.data.id);
      if (sectionIndex > -1) {
        currentSections[sectionIndex] = action.payload.data;
      } else {
        currentSections.push(action.payload.data);
      }
      return {
        ...state,
        items: currentSections,
      };

    case CREATE_SECTION_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload.data],
      };

    case FETCH_SECTION_TYPES_SUCCESS:
      return {
        ...state,
        item_types: action.payload.data,
      };

    case DELETE_SECTION_SUCCESS:
      const items = [...state.items];
      const index = items.findIndex(item => item.id.toString() === action.payload.data);
      if (index > -1) {
        items.splice(index, 1);
      }
      return {
        ...state,
        items,
      };

    case EDIT_SECTION_SUCCESS:
      const sections = [...state.items];
      const editedSectionIndex = sections.findIndex(item => item.id === action.payload.data.id);
      if (editedSectionIndex > -1) {
        sections[editedSectionIndex] = action.payload.data;
      }
      return {
        ...state,
        items: sections,
      };

    default:
      return state;
  }
}
