import { applyMiddleware, createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import RootReducer from '../reducers';

export default function configureStore() {
  const middlewares = [thunk];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
  }

  return new Promise((resolve, reject) => {
    try {
      const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['app'],
      };

      const persistedReducer = persistReducer(persistConfig, RootReducer);
      const store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(...middlewares)),
      );
      const persistor = persistStore(store, {}, () => resolve({ store, persistor }));
    } catch (e) {
      reject(e);
    }
  });
}
