import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import {
  deleteLesson,
  selectLesson,
  updateLessonsOrder,
  fetchLessons,
} from '../../actions/lessons';
import AdminList from '../../components/AdminList';

class AdminLessonsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lessons: props.lessons,
    };
  }

  componentDidMount() {
    const { dispatch, monthId, match } = this.props;

    dispatch(fetchLessons(monthId || match.params.monthId));
  }

  componentWillReceiveProps(nextProps) {
    const { lessons } = this.props;

    if (lessons !== nextProps.lessons) {
      this.setState({ lessons: nextProps.lessons });
    }
  }

  selectLessonId = id => this.props.dispatch(selectLesson(id));

  deleteLesson = id => this.props.dispatch(deleteLesson(id));

  editLessonsOrder = lessons => {
    const { dispatch, match } = this.props;

    const lessonIds = lessons.items.map(lesson => lesson.id);
    dispatch(updateLessonsOrder({ items: lessonIds }, match.params.monthId));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { lessons } = this.state;

    this.setState({
      lessons: arrayMove(lessons, oldIndex, newIndex),
    });
  };

  render() {
    const { match, t } = this.props;
    const { monthId } = match.params;
    const { lessons } = this.state;

    return (
      <AdminList
        title="Lekcije"
        itemColor="#3164A5"
        onClick={this.selectLessonId}
        onDelete={this.deleteLesson}
        to={match.url}
        items={lessons && lessons.map(item => item.lesson)}
        addLink={`/${monthId}/lesson`}
        onSortEnd={this.onSortEnd}
        useDragHandle
        updateItemsOrder={this.editLessonsOrder}
        t={t}
      />
    );
  }
}

AdminLessonsScreen.propTypes = {
  lessons: PropTypes.array,
};

AdminLessonsScreen.defaultProps = {
  lessons: null,
};

const mapStateToProps = state => {
  const { months } = state.lessons;
  const lessons = months && months[state.months.selectedId];

  return {
    lessons: lessons && [...lessons],
    monthId: state.months.selectedId,
  };
};

export default compose(withNamespaces('translation'), connect(mapStateToProps))(AdminLessonsScreen);
