export const ACTION_BEGIN = 'ACTION_BEGIN';
export const ACTION_FAILURE = 'ACTION_FAILURE';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const HIDE_NAVBAR = 'HIDE_NAVBAR';
export const SHOW_NAVBAR = 'SHOW_NAVBAR';
export const HIDE_INFO_MODAL = 'HIDE_INFO_MODAL';
export const SHOW_INFO_MODAL = 'SHOW_INFO_MODAL';

export function hideMessageModal() {
  return dispatch => {
    dispatch({
      type: HIDE_ERROR,
    });
  };
}

export function hideNavigationBar() {
  return dispatch => {
    dispatch({
      type: HIDE_NAVBAR,
    });
  };
}

export function showNavigationBar(selectedItem) {
  return dispatch => {
    dispatch({
      type: SHOW_NAVBAR,
      payload: { selectedItem },
    });
  };
}

export function hideInfoModal() {
  return dispatch => {
    dispatch({
      type: HIDE_INFO_MODAL,
    });
  };
}

export function showInfoModal() {
  return dispatch => {
    dispatch({
      type: SHOW_INFO_MODAL,
    });
  };
}
