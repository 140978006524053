import React, { useEffect, useContext } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DndContext } from './DndBox';

const DndElement = ({
  id,
  type,
  label,
  hasPicture,
  isCorrect,
  dndBoxIndex,
  colorIndex,
  canDrag,
}) => {
  const [{ opacity, height }, drag, preview] = useDrag({
    item: { id, type, dndBoxIndex, label, colorIndex },
    canDrag,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0 : 1,
      height: monitor.isDragging() ? 0 : '',
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  let bgColorClass = `bgcolor--${colorIndex}`;
  if (hasPicture && !isCorrect) {
    bgColorClass = label ? 'bgcolor--0' : '';
  }

  const { isActive, draggedColor } = useContext(DndContext);

  let borderClass = hasPicture && !label ? 'borderClass--00' : '';
  // if term is hoovered by dragged element, set border color as dragged element background
  borderClass = isActive ? `borderClass--${draggedColor}` : borderClass;

  const cursorClass = !canDrag && 'cursor-default';

  return (
    <div
      ref={drag}
      className={`word-box ${bgColorClass} ${borderClass} ${cursorClass}`}
      style={{
        opacity,
        height,
      }}
    >
      {label}
    </div>
  );
};

export default DndElement;
