import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { SortableContainer } from 'react-sortable-hoc';
import 'react-confirm-alert/src/react-confirm-alert.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import AdminListItem from '../AdminListItem';
import './index.css';

import backImg from '../../images/admin/back.svg';

class AdminList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      showDragHandle: false,
    };

    this.confirmDeleteAlert = withReactContent(Swal);
  }

  componentWillReceiveProps(nextProps) {
    const { items } = this.props;

    if (items !== nextProps.items) {
      this.setState({ items: nextProps.items });
    }
  }

  confirmDelete = id => {
    const { t, onDelete } = this.props;

    this.confirmDeleteAlert
      .fire({
        type: 'warning',
        title: t(`modals.confirm.title`),
        text: t(`modals.confirm.text`),
        showCancelButton: true,
        confirmButtonColor: '#FF564F',
        cancelButtonColor: '#AF6098',
        confirmButtonText: t(`modals.confirm.confirmText`),
        cancelButtonText: t(`modals.confirm.cancelText`),
      })
      .then(result => {
        if (result.value) {
          onDelete(id);
        }
      });
  };

  saveMonthsOrder = () => {
    const { showDragHandle, items } = this.state;
    const { updateItemsOrder } = this.props;

    if (showDragHandle) {
      updateItemsOrder({ items });
    }
    this.setState({ showDragHandle: !showDragHandle });
  };

  renderItem = (item, index) => {
    const { onClick, to, itemColor, onSortEnd, addLink } = this.props;
    const { showDragHandle } = this.state;

    return (
      <AdminListItem
        key={item.id}
        item={item}
        onClick={onClick}
        itemColor={itemColor}
        to={to}
        addLink={addLink}
        confirmDelete={this.confirmDelete}
        onSortEnd={onSortEnd}
        index={index}
        useDragHandle
        showDragHandle={showDragHandle}
      />
    );
  };

  render() {
    const { addLink, title } = this.props;
    const { items, showDragHandle } = this.state;

    const urlSplit = window.location.href.split('/');
    urlSplit.pop();
    const newUrl = urlSplit.join('/');

    return (
      <div className="admin-list">
        <a className="admin-list__back" href={newUrl}>
          <img alt="back" src={backImg} />
        </a>
        <div className="admin-list__header">
          <button
            type="button"
            className="admin-list__header__reorder-btn"
            onClick={this.saveMonthsOrder}
          >
            {!showDragHandle ? 'Promjeni redoslijed' : 'Spremi redoslijed'}
          </button>
          <Link to="/admin/users" className="admin-list-button">
            Popis korisnika
          </Link>
          <h1 className="admin-list__header__title">{title}</h1>
          <Link to={`/admin/form${addLink}`} className="admin-list-button">
            Dodaj
          </Link>
        </div>
        {!!items && items.map(this.renderItem)}
      </div>
    );
  }
}

AdminList.propTypes = {
  items: PropTypes.array,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  itemColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addLink: PropTypes.string.isRequired,
};

AdminList.defaultProps = {
  onClick: null,
  onDelete: null,
  items: [],
};

export default SortableContainer(AdminList);
