import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import HtmlElement from '../common/HtmlElement';
import './index.css';

function Instruction({ path, cardSettings }) {
  const elements = useMemo(() => Object.keys(cardSettings), [cardSettings]);

  const renderUl = useCallback(
    element => (
      <ul key={`instructions.${path}.${element}`}>
        {[...Array(cardSettings[element].count).keys()].map(index => {
          const translationPath = `instructions.${path}.${element}.${index}`;
          return (
            <HtmlElement
              key={`translationPath-${index}`}
              translationPath={translationPath}
              Tag="li"
            />
          );
        })}
      </ul>
    ),
    [cardSettings, path],
  );

  return (
    <div className="instruction-card scrollbar">
      {elements.map(element => {
        const translationPath = `instructions.${path}.${element}`;
        const { tag } = cardSettings[element];
        if (tag === 'ul') {
          return renderUl(element);
        }
        return <HtmlElement key={translationPath} translationPath={translationPath} Tag={tag} />;
      })}
    </div>
  );
}

Instruction.propTypes = {
  path: PropTypes.string.isRequired,
  cardSettings: PropTypes.shape({
    header: PropTypes.shape({
      count: PropTypes.number,
      tag: PropTypes.string,
    }),
  }).isRequired,
};

export default Instruction;
