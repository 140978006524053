import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ios, ipad } from 'is_js';

import { common } from '../../utils';
import { hideNavigationBar } from '../../actions/app';
import { postLessonResult } from '../../actions/lessons';
import Card from '../common/Card';
import Term from '../Term';
import LoadingBar from '../common/LoadingBar';
import Reward from '../Reward';
import gameLevelImages from './gameLevelImages';

import './index.css';
import DndGame from '../DndGame';

const CORRECTNESS_STATES = {
  correct: 'correct',
  neutral: 'neutral',
  incorrect: 'incorrect',
};

const LEVEL_CAP = [
  { numOfTerms: 2, renderType: 'img' },
  { numOfTerms: 4, renderType: 'img' },
  { numOfTerms: 2, numOfImgs: 2, renderType: 'img/txt' },
  { numOfTerms: 4, numOfImgs: 4, renderType: 'img/txt' },
  { numOfTerms: 2, renderType: 'txt' },
  { numOfTerms: 4, renderType: 'txt' },
];

class Gameboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTerms: [],
      answerIndex: null,
      correctness: CORRECTNESS_STATES.neutral,
      startOfLesson: true,
      level: 0,
      preloadedImgCounter: 0,
      preloadedAudioCounter: 0,
      preloadedVideoCounter: 0,
      currentLessonGameProgress: '',
      allowClick: true,
      iOSpreload: false,
      newGameProgress: null,
    };

    this.termPool = [];
    this.correct_answers = 0;
    this.total_questions = 0;
  }

  componentWillMount() {
    const { dispatch, terms } = this.props;
    dispatch(hideNavigationBar());

    common.preloadTerms(terms, this);
  }

  playAudio = () => {
    const { activeTerms, answerIndex } = this.state;
    const hasAudio = !!activeTerms[answerIndex] && !!activeTerms[answerIndex].audioObject;

    if (activeTerms.length === 0 || !hasAudio) {
      return;
    }

    activeTerms[answerIndex].audioObject.play();
  };

  updateGameProgress = () => {
    const { dispatch, lessonId, lessonGameProgress } = this.props;
    const { level } = this.state;

    const updatedGameProgress = lessonGameProgress.split('');
    updatedGameProgress[level] =
      updatedGameProgress[level] === '0' ? '1' : updatedGameProgress[level];

    const efficacy = this.correct_answers / this.total_questions;
    dispatch(
      postLessonResult({
        lesson_id: lessonId,
        efficacy,
        level,
      }),
    );

    return updatedGameProgress.join('');
  };

  getActiveTerms = () => {
    const { level } = this.state;
    const activeTerms = [];
    const { renderType } = LEVEL_CAP[level];

    const limit = Math.min(LEVEL_CAP[level].numOfTerms, this.termPool.length);
    for (let i = 0; i < limit; i += 1) {
      const index = common.getRandomInt(this.termPool.length);
      activeTerms.push(this.termPool[index]);
      this.termPool.splice(index, 1);
    }

    if (renderType === 'img/txt') {
      this.setState({ correctness: CORRECTNESS_STATES.neutral, activeTerms });
    } else {
      this.setState(
        {
          correctness: CORRECTNESS_STATES.neutral,
          activeTerms,
          answerIndex: common.getRandomInt(activeTerms.length),
        },
        () => this.playAudio(),
      );
    }
  };

  onTermClick = index => {
    const { allowClick } = this.state;
    const { renderType } = LEVEL_CAP[this.state.level];

    if (renderType === 'img/txt') {
      return;
    }

    this.setState({ allowClick: false });
    if (allowClick) {
      const { answerIndex } = this.state;
      const activeTerms = [...this.state.activeTerms];
      this.total_questions += 1;
      if (index === answerIndex) {
        activeTerms.splice(index, 1);
        this.correct_answers += 1;
        this.setState({ correctness: CORRECTNESS_STATES.correct });
        this.termPool = this.termPool.concat(activeTerms);
        setTimeout(() => {
          this.setState({ allowClick: true });
          this.getActiveTerms();
        }, 1000);
      } else {
        this.setState({ correctness: CORRECTNESS_STATES.incorrect });
        this.playAudio();
        setTimeout(() => {
          this.setState({
            allowClick: true,
            correctness: CORRECTNESS_STATES.neutral,
          });
        }, 1000);
      }
    }
  };

  incrementCorrect = () => {
    this.correct_answers += 1;
  };

  incrementQuestions = () => {
    this.total_questions += 1;
  };

  startGame = () => {
    this.correct_answers = 0;
    this.total_questions = 0;
    this.setState({ startOfLesson: false });
    this.termPool = [...this.props.terms];

    this.getActiveTerms();
  };

  goBack = () => this.props.history.goBack();

  iOSpreload = () => {
    const { terms } = this.props;

    terms.forEach(term => {
      if (term.audioObject) {
        term.audioObject.load();
      }
    });

    this.setState({ iOSpreload: true });
  };

  selectLevelAndStartGame = level => {
    const { lessonGameProgress } = this.props;

    this.setState({ currentLessonGameProgress: lessonGameProgress, level }, this.startGame);
  };

  renderTerm = (term, index, renderTypeOverride) => (
    <Term
      key={term.id}
      {...term}
      renderType={renderTypeOverride || LEVEL_CAP[this.state.level].renderType}
      collectionSize={LEVEL_CAP[this.state.level].numOfTerms}
      index={index}
      onClick={this.onTermClick}
      allowClick={this.state.allowClick}
    />
  );

  renderLevelCard = (item, index) => {
    const { gameProgress, t } = this.props;
    const levelIndex = index + 1;
    const label = t(`game.level`);

    return (
      <div key={index} className="level-card">
        <Card
          label={`${label} ${levelIndex}`}
          onClick={() => this.selectLevelAndStartGame(index)}
          img={gameLevelImages[levelIndex]}
          isPlayed={gameProgress[index] === '1'}
        />
      </div>
    );
  };

  renderStart = () => {
    const {
      preloadedImgCounter,
      preloadedAudioCounter,
      preloadedVideoCounter,
      iOSpreload,
    } = this.state;
    const { t, terms } = this.props;
    const termsLength = terms.length;
    const preloadedCounters = preloadedImgCounter + preloadedAudioCounter + preloadedVideoCounter;
    const loadingProgress = (100 * preloadedCounters) / (2 * termsLength);

    if (
      preloadedImgCounter !== termsLength &&
      preloadedAudioCounter !== termsLength &&
      preloadedVideoCounter !== termsLength
    )
      return (
        <div className="gameboard__loading">
          <LoadingBar loadingProgress={loadingProgress} />
        </div>
      );

    if (!iOSpreload) {
      return (
        <React.Fragment>
          <button type="button" className="lesson__start-btn" onClick={this.iOSpreload}>
            {t('lesson.iospreload')}
          </button>
        </React.Fragment>
      );
    }

    return (
      <div>
        <div className="level-card-list">{LEVEL_CAP.map(this.renderLevelCard)}</div>
      </div>
    );
  };

  renderReward = () => {
    const { lessonPuzzleImgUrl } = this.props;
    const { currentLessonGameProgress, newGameProgress } = this.state;

    if (!newGameProgress) {
      this.setState({ newGameProgress: this.updateGameProgress() });
    }

    if (currentLessonGameProgress === newGameProgress || !lessonPuzzleImgUrl) {
      return null;
    }

    return (
      <div className="gameboard__reward-wrapper">
        <Reward
          lessonCurrentGameProgress={currentLessonGameProgress}
          lessonNextGameProgress={newGameProgress}
          lessonPuzzleImgUrl={lessonPuzzleImgUrl}
        />
      </div>
    );
  };

  gameReplay = () => {
    const { lessonGameProgress } = this.props;
    this.setState({ currentLessonGameProgress: lessonGameProgress }, this.startGame);
  };

  renderEnd = () => (
    <React.Fragment>
      <button type="button" className="gameboard__btn" onClick={this.gameReplay}>
        {this.props.t('game.replay')}
      </button>
      <button
        type="button"
        className="gameboard__btn gameboard__btn--highlighted"
        onClick={this.goBack}
      >
        {this.props.t('game.end')}
      </button>
    </React.Fragment>
  );

  renderContent() {
    const { startOfLesson, activeTerms } = this.state;
    const { renderType } = LEVEL_CAP[this.state.level];

    if (startOfLesson) {
      return this.renderStart();
    }

    if (!activeTerms.length && !this.termPool.length) {
      return (
        <div>
          {this.renderReward()}
          {this.renderEnd()}
        </div>
      );
    }

    if (renderType === 'img/txt') {
      return (
        <DndGame
          activeTerms={activeTerms}
          incrementCorrect={this.incrementCorrect}
          incrementQuestions={this.incrementQuestions}
          getActiveTerms={this.getActiveTerms}
        />
      );
    }

    return activeTerms.map((t, i) => this.renderTerm(t, i));
  }

  render() {
    const { isAppleUser } = this.props;
    const { correctness, startOfLesson } = this.state;
    const { renderType } = LEVEL_CAP[this.state.level];

    const appleUserBool = isAppleUser || ios() || ipad();

    const showButton = renderType === 'img/txt' || startOfLesson;
    const buttonClassname = showButton ? 'back' : appleUserBool ? 'repeat_audio' : 'hide';

    return (
      <>
        <button
          type="button"
          className={`gameboard__helper_button gameboard__helper_button--${buttonClassname}`}
          onClick={showButton ? this.goBack : this.playAudio}
        />
        <div className={`gameboard gameboard--${correctness}`}>{this.renderContent()}</div>
      </>
    );
  }
}

Gameboard.propTypes = {
  lessonId: PropTypes.number.isRequired,
  lessonGameProgress: PropTypes.string.isRequired,
  lessonPuzzleImgUrl: PropTypes.string.isRequired,
  terms: PropTypes.array.isRequired,
  monthId: PropTypes.number,
  isAppleUser: PropTypes.bool,
  gameProgress: PropTypes.string,
};

export default withRouter(compose(withNamespaces('translation'), connect())(Gameboard));
