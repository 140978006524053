import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { common } from '../../utils';
import { createTerm, fetchTerm, updateTerm } from '../../actions/terms';
import validation from '../../utils/validation';
import CropperModal from '../../components/CropperModal';
import PATH_SPLIT_REGEX from '../../constants/regex';

import './index.css';

class TermFormScreen extends React.Component {
  constructor() {
    super();

    this.state = {
      label: '',
      customizable: 0,
      imageRect: null,
      audio: null,
      video: null,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      highlightedChars: '',
      showROIEdit: false,
    };

    this.imageFileInput = React.createRef();
    this.videoFileInput = React.createRef();
    this.cropper = React.createRef();
    this.messageModal = withReactContent(Swal);
  }

  componentWillMount() {
    const { dispatch, match, terms } = this.props;
    const { termId } = match.params;

    if (termId) {
      dispatch(fetchTerm(termId));
      this.setTermState(terms, termId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { terms, match } = this.props;
    const { termId } = match.params;

    if (terms !== nextProps.terms) {
      this.setTermState(nextProps.terms, termId);
    }
  }

  setTermState = (terms, termId) => {
    const term = terms.find(term2 => term2.id.toString() === termId);
    const image = term.multimedia && term.multimedia.find(item => item.type === 'image-rectangle');
    const video = term.multimedia && term.multimedia.find(item => item.type === 'video');
    const audio = term.multimedia && term.multimedia.find(item => item.type === 'audio');

    if (!term.highlightedChars) {
      term.highlightedChars = this.setHighlightedChars(term.label.length);
    }

    this.setState({
      label: term.label,
      imageUrl: image && image.url,
      audioUrl: audio && audio.url,
      videoUrl: video && video.url,
      customizable: term.custom_multimedia,
      highlightedChars: term.highlightedChars,
    });
  };

  setHighlightedChars = len => '0'.repeat(len);

  handleCheckboxChange = ({ target }) => this.setState({ customizable: target.checked ? 1 : 0 });

  handleFileChange = ({ target }) => {
    const { t } = this.props;
    const newState = { [target.name]: target.files[0] };
    if (target.name === 'imageRect') {
      if (validation.validFileType(target.files[0], validation.VALID_IMAGE_TYPES)) {
        newState.showROIEdit = true;
      } else {
        common.renderMessageModal(this.messageModal, t('error.wrongImageType'), t('ok'));
        this.imageFileInput.current.value = null;
      }
    }
    if (target.name === 'audio') {
      newState.audioUrl = URL.createObjectURL(target.files[0]);
    }
    if (target.name === 'video') {
      if (validation.validFileType(target.files[0], validation.VALID_VIDEO_TYPES)) {
        newState.videoUrl = URL.createObjectURL(target.files[0]);
      } else {
        common.renderMessageModal(this.messageModal, t('error.wrongVideoType'), t('ok'));
        this.videoFileInput.current.value = null;
      }
    }

    this.setState(newState);
  };

  handleSubmit = e => {
    const { dispatch, history, match } = this.props;
    const { monthId, lessonId, sectionId, termId } = match.params;
    const { label, customizable, imageRect, audio, video, highlightedChars } = this.state;

    const lesson = {
      label,
      customizable,
      imageRect,
      audio,
      video,
      highlightedChars,
    };

    e.preventDefault();
    if (termId) {
      dispatch(updateTerm(lesson, termId));
    } else {
      dispatch(
        createTerm({
          ...lesson,
          id: termId,
          sectionId,
        }),
      );
    }
    history.replace(`/admin/${monthId}/${lessonId}/${sectionId}`);
  };

  handleLabelChange = ({ target }) => {
    this.setState({
      label: target.value,
      highlightedChars: this.setHighlightedChars(target.value.length),
    });
  };

  toggleCharHighlight = index => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let updatedChars = this.state.highlightedChars;
    const replacementChar = updatedChars[index] === '0' ? '1' : '0';
    updatedChars = updatedChars.substr(0, index) + replacementChar + updatedChars.substr(index + 1);

    this.setState({ highlightedChars: updatedChars });
  };

  getCroppedImage = () => {
    const croppedImageUrl = this.cropper.current
      .getCroppedCanvas({ fillColor: '#fff' })
      .toDataURL('image/jpeg');
    const croppedImage = new File(
      [common.dataURLToBlob(croppedImageUrl)],
      this.imageFileInput.current.value.split(PATH_SPLIT_REGEX).pop(),
      {
        type: 'image/jpeg',
      },
    );

    this.setState({
      showROIEdit: false,
      imageUrl: croppedImageUrl,
      imageRect: croppedImage,
    });
  };

  cancelCropping = () => {
    this.setState({ showROIEdit: false });
    this.imageFileInput.current.value = null;
  };

  renderImage = url => <img alt="Lesson thumbnail" className="admin-term__form__image" src={url} />;

  renderAudio = url => <audio controls src={url} />;

  renderVideo = url => <video controls className="admin-term__form__image" src={url} />;

  renderHighlightChar = (char, i) => (
    <span
      className={`admin-term__form__highlight-div__char ${
        this.state.highlightedChars[i] === '1' ? 'highlighted' : ''
      }`}
      key={i}
      onClick={() => this.toggleCharHighlight(i)}
      onKeyPress={() => this.toggleCharHighlight(i)}
    >
      {char}
    </span>
  );

  renderForm = () => {
    const { label, imageUrl, videoUrl, audioUrl, customizable } = this.state;
    const { termId } = this.props.match.params;
    const highlightLabel = [...label].map(this.renderHighlightChar);

    return (
      <div className="admin-term">
        <form name="home-screen-form" className="admin-term__form" onSubmit={this.handleSubmit}>
          <input
            placeholder="Naziv"
            name="label"
            value={label}
            type="text"
            onChange={this.handleLabelChange}
            required
          />
          <div className="admin-term__form__div">
            <label className="admin-term__form__label">
              Odaberite slova koja želite istaknuti:
            </label>
            <div className="admin-term__form__highlight-div">{highlightLabel}</div>
          </div>

          <div className="admin-term__form__div">
            <label>Slika:</label>
            <input
              ref={this.imageFileInput}
              type="file"
              name="imageRect"
              accept="image/*"
              onChange={this.handleFileChange}
            />
          </div>

          {imageUrl && this.renderImage(imageUrl)}

          <div className="admin-term__form__div">
            <label>Audio:</label>
            <input type="file" name="audio" accept="audio/*" onChange={this.handleFileChange} />
          </div>

          {audioUrl && this.renderAudio(audioUrl)}

          <div className="admin-term__form__div">
            <label>Video:</label>
            <input
              ref={this.videoFileInput}
              type="file"
              name="video"
              accept="video/mp4"
              onChange={this.handleFileChange}
            />
          </div>

          {videoUrl && this.renderVideo(videoUrl)}

          {imageUrl && (
            <div className="admin-term__form__div--checkbox">
              <input
                className="admin-lesson__form__div--checkbox"
                type="checkbox"
                id="custom"
                name="custom"
                checked={customizable === 1}
                onChange={this.handleCheckboxChange}
              />
              <label>Korisnik smije dodavati svoju sliku</label>
            </div>
          )}

          <button className="admin-term__form-button" type="submit">
            {termId ? 'Izmijeni' : 'Dodaj'} pojam
          </button>
        </form>
      </div>
    );
  };

  render() {
    const { showROIEdit } = this.state;

    return (
      <div>
        {showROIEdit && (
          <CropperModal
            cropperRef={this.cropper}
            aspectRatioW={16}
            aspectRatioH={9}
            src={window.URL.createObjectURL(this.imageFileInput.current.files[0])}
            onCropCancel={this.cancelCropping}
            onCropFinished={this.getCroppedImage}
          />
        )}
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  terms: state.terms.items,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(TermFormScreen);
