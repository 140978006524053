import React from 'react';
import { connect } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { showNavigationBar } from '../../actions/app';
import {
  createOrganizationUser,
  deactivateOrganizationUser,
  fetchOrganizationUsers,
} from '../../actions/organizationManagement';
import UserList from '../../components/UserList';

class OrganizationManagementScreen extends React.Component {
  constructor(props) {
    super(props);

    this.actionStatusAlert = withReactContent(Swal);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchOrganizationUsers());
    dispatch(showNavigationBar('organizationManagement'));
  }

  deactivateUser = id => {
    const { dispatch, t } = this.props;

    this.actionStatusAlert
      .fire({
        title: t('modals.confirm.title'),
        text: t('modals.confirm.text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF564F',
        cancelButtonColor: '#AF6098',
        confirmButtonText: t('modals.confirm.confirmText'),
        cancelButtonText: t('modals.confirm.cancelText'),
      })
      .then(result => {
        if (result.value) {
          dispatch(deactivateOrganizationUser(id));
        }
      });
  };

  addUser = () => this.props.dispatch(createOrganizationUser());

  render() {
    const { users, accountsLimit, subscriptionUntil, t } = this.props;

    return (
      <UserList
        users={users}
        addUser={this.addUser}
        deactivateUser={this.deactivateUser}
        accountsLimit={accountsLimit}
        subscriptionUntil={subscriptionUntil}
        t={t}
      />
    );
  }
}

const mapStateToProps = state => {
  const { users, accountLimit: accountsLimit, subscriptionUntil } = state.organizationManagement;

  return {
    users,
    accountsLimit,
    subscriptionUntil,
  };
};

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(OrganizationManagementScreen);
