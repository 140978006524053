import { network } from '../../utils';

export const ACCOUNT_GENERATOR_BEGIN = 'ACCOUNT_GENERATOR_BEGIN';
export const ACCOUNT_GENERATOR_FAILURE = 'ACCOUNT_GENERATOR_FAILURE';
export const ACCOUNT_GENERATOR_SUCCESS = 'ACCOUNT_GENERATOR_SUCCESS';
export const CLEAR_GENERATOR_STATUS = 'CLEAR_GENERATOR_STATUS';

export const createSingleAccount = data =>
  network.createActionWrapper(
    'accountGenerator/single',
    {
      begin: ACCOUNT_GENERATOR_BEGIN,
      success: ACCOUNT_GENERATOR_SUCCESS,
      failure: ACCOUNT_GENERATOR_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const createMultipleAccounts = data =>
  network.createActionWrapper(
    'accountGenerator/multiple',
    {
      begin: ACCOUNT_GENERATOR_BEGIN,
      success: ACCOUNT_GENERATOR_SUCCESS,
      failure: ACCOUNT_GENERATOR_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const clearGeneratorStatus = () => dispatch => {
  dispatch({
    type: CLEAR_GENERATOR_STATUS,
  });
};
