import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

class MonthList extends React.PureComponent {
  renderMonth = ({ id, label }) => {
    const { updateSelectedMonth, selectedMonthId } = this.props;
    const isSelected = selectedMonthId === id;

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        key={id}
        onClick={() => updateSelectedMonth(id)}
        onKeyPress={() => updateSelectedMonth(id)}
        className={`month-list__item ${isSelected ? 'month-list__item--selected' : ''}`}
      >
        {label}
      </li>
    );
  };

  render() {
    const { months } = this.props;

    return <ul className="month-list">{!!months && months.map(this.renderMonth)}</ul>;
  }
}

MonthList.propTypes = {
  months: PropTypes.array,
  updateSelectedMonth: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedMonthId: PropTypes.number,
};

export default MonthList;
