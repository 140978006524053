/**
 * INSTRUCTIONS_SETTINGS constant is used for generating HTML elements,
 * rendered by component HtmlElement from Instruction component,
 * every Object entry in INSTRUCTIONS_SETTINGS is an object containing:
 *
 * HOW TO RENDER ONE INSTRUCTION CARD:
 * Consider we have in translation.json
 *  {
 *    'instructions': {
 *        card1: {
 *          {
 *          "element1": "We will render it as <h2></h2>",
 *          "element2": [
 *            "unordered list element 1",
 *            "unordered list element 2",
 *            "unordered list element 3",
 *            "unordered list element 4",
 *          ]
 *      }
 *  }
 *
 * then in constants/instructions.js:
 *
 * const INSTRUCTIONS_SETTINGS = {
 *     CARD1: {
 *       path: 'card1',
 *       settings:[
 *         {
 *           element1: {count: 1, tag: 'h2'},
 *           element2: {count: 7, tag: 'ul'}
 *         }
 *       ]
 *     }
 *   }
 *
 * path to translation string (or array)
 * (eg. path: 'card1' translation.json)
 * settings, an array with specification of na HTML element to render
 * (eg. settings:[
 * {
 * element1: {count: 1, tag: 'h2'},
 * element2: {count: 4, tag: 'ul'}
 * }
 * ], which instructs to render element1 content from translation.json with tag 'h2',
 * and element2 content from translation.json as 'ul' with 4 'li' elements)
 *
 *
 *  For further explanation visit:
 *  https://gitlab.com/ehiper/hiper-web/-/wikis/Managing%20Hiper%20Instructions%20Content
 */

const INSTRUCTIONS_SETTINGS = {
  WHAT_IS_HIPER: {
    path: 'whatIsHiper',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        ul_1: { count: 7, tag: 'ul' },
      },
    ],
  },
  HARDWARE_REQUIREMENTS: {
    path: 'hardwareRequirements',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        ul_1: { count: 6, tag: 'ul' },
      },
    ],
  },
  REMARKS: {
    path: 'remarks',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        h3_1: { count: 1, tag: 'h3' },
        ul_1: { count: 1, tag: 'ul' },
        h3_2: { count: 1, tag: 'h3' },
        ul_2: { count: 1, tag: 'ul' },
        h3_3: { count: 1, tag: 'h3' },
        ul_3: { count: 1, tag: 'ul' },
      },
    ],
  },
  MAIN_SCREEN: {
    path: 'mainScreen',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        p: { count: 1, tag: 'p' },
        ul_1: { count: 3, tag: 'ul' },
        h3_1: { count: 1, tag: 'h3' },
        p_1: { count: 1, tag: 'p' },
        h3_2: { count: 1, tag: 'h3' },
        p_2: { count: 1, tag: 'p' },
        h3_3: { count: 1, tag: 'h3' },
        p_3: { count: 1, tag: 'p' },
      },
    ],
  },
  LESSON_SCREEN: {
    path: 'lessonScreen',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        h3_1: { count: 1, tag: 'h3' },
        p_1: { count: 1, tag: 'p' },
        h3_2: { count: 1, tag: 'h3' },
        p_2: { count: 1, tag: 'p' },
        p_3: { count: 1, tag: 'p' },
        ul_1: { count: 7, tag: 'ul' },
        p_4: { count: 1, tag: 'p' },
        h3_3: { count: 1, tag: 'h3' },
        p_5: { count: 1, tag: 'p' },
        h3_4: { count: 1, tag: 'h3' },
        p_6: { count: 1, tag: 'p' },
        h3_5: { count: 1, tag: 'h3' },
        p_7: { count: 1, tag: 'p' },
        h3_6: { count: 1, tag: 'h3' },
        p_8: { count: 1, tag: 'p' },
        h3_7: { count: 1, tag: 'h3' },
        p_9: { count: 1, tag: 'p' },
        h3_8: { count: 1, tag: 'h3' },
        p_10: { count: 1, tag: 'p' },
        h3_9: { count: 1, tag: 'h3' },
        p_11: { count: 1, tag: 'p' },
        ul_2: { count: 6, tag: 'ul' },
        p_12: { count: 1, tag: 'p' },
        p_13: { count: 1, tag: 'p' },
      },
    ],
  },
  REWARDS_SCREEN: {
    path: 'rewardsScreen',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        p_1: { count: 1, tag: 'p' },
      },
    ],
  },
  STATS_SCREEN: {
    path: 'statsScreen',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        p_1: { count: 1, tag: 'p' },
        ul_1: { count: 3, tag: 'ul' },
      },
    ],
  },
  PICTURES_SCREEN: {
    path: 'picturesScreen',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        p_1: { count: 1, tag: 'p' },
        p_2: { count: 1, tag: 'p' },
        p_3: { count: 1, tag: 'p' },
        p_4: { count: 1, tag: 'p' },
        p_5: { count: 1, tag: 'p' },
      },
    ],
  },
  SETTINGS_SCREEN: {
    path: 'settingsScreen',
    settings: [
      {
        header: { count: 1, tag: 'h2' },
        p_1: { count: 1, tag: 'p' },
        ul_1: { count: 3, tag: 'ul' },
      },
    ],
  },
  THEMES_AND_LESSONS: {
    path: 'themesAndLessons',
    settings: [
      {
        h2: { count: 1, tag: 'h2' },
        h3_1: { count: 1, tag: 'h3' },
        ul_1: { count: 6, tag: 'ul' },
        h3_2: { count: 1, tag: 'h3' },
        ul_2: { count: 6, tag: 'ul' },
        h3_3: { count: 1, tag: 'h3' },
        ul_3: { count: 6, tag: 'ul' },
        h3_4: { count: 1, tag: 'h3' },
        ul_4: { count: 6, tag: 'ul' },
        h3_5: { count: 1, tag: 'h3' },
        ul_5: { count: 6, tag: 'ul' },
        h3_6: { count: 1, tag: 'h3' },
        ul_6: { count: 6, tag: 'ul' },
        h3_7: { count: 1, tag: 'h3' },
        ul_7: { count: 6, tag: 'ul' },
        h3_8: { count: 1, tag: 'h3' },
        ul_8: { count: 6, tag: 'ul' },
        h3_9: { count: 1, tag: 'h3' },
        ul_9: { count: 6, tag: 'ul' },
        h3_10: { count: 1, tag: 'h3' },
        ul_10: { count: 6, tag: 'ul' },
        h3_11: { count: 1, tag: 'h3' },
        ul_11: { count: 6, tag: 'ul' },
        h3_12: { count: 1, tag: 'h3' },
        ul_12: { count: 6, tag: 'ul' },
      },
    ],
  },
};

export default INSTRUCTIONS_SETTINGS;
