import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import './index.css';

function TermMultimediaEdit(props) {
  const {
    label,
    subLabel,
    multimediaUrl,
    month,
    hideLabel,
    onExit,
    onKeep,
    onHide,
    onSwitch,
    onDefault,
    t,
  } = props;

  return (
    <div className="modal__bg">
      <button type="button" className="modal__bg__exit" onClick={onExit}>
        x
      </button>
      <div className="modal__bg__month">{month}</div>
      {subLabel && <div className="modal__bg__sublabel">{subLabel}</div>}
      <div className="modal__bg__label">{label}</div>
      <img alt="custom multimedia" className="modal__bg__image" src={multimediaUrl} />
      <div className="modal__bg__btn-container">
        <button type="button" onClick={onSwitch}>
          {t('myMultimedia.wizard.switch')}
        </button>
        <button type="button" onClick={onHide}>
          {hideLabel}
        </button>
        <button type="button" onClick={onKeep}>
          {t('myMultimedia.wizard.keep')}
        </button>
        {onDefault && (
          <button type="button" onClick={onDefault}>
            {t('myMultimedia.wizard.default')}
          </button>
        )}
      </div>
    </div>
  );
}

TermMultimediaEdit.propTypes = {
  month: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  multimediaUrl: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  hideLabel: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onKeep: PropTypes.func.isRequired,
  onDefault: PropTypes.func,
};

TermMultimediaEdit.defaultProps = {
  onDefault: null,
};

export default withNamespaces('translation')(TermMultimediaEdit);
