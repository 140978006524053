import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import './index.css';

class InfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
    };
  }

  componentDidMount() {
    const { isAppleUser } = this.props;

    this.setState({
      value: {
        isAppleUser,
      },
    });
  }

  reloadPage = () => window.location.reload();

  renderCloseButton = () => (
    <button type="button" onClick={() => this.props.onClose(this.state.value.isAppleUser)}>
      {this.props.closeBtnText ? this.props.closeBtnText : this.props.t('ok')}
    </button>
  );

  handleChange = ({ target }) => {
    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [target.name]: target.checked,
      },
    }));
  };

  render() {
    const {
      show,
      title,
      messageInstructions,
      messageLimits,
      messageApple,
      messageWelcomeWizard,
      messageEnd,
    } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div className="backdrop">
        <div className="div--modal">
          <div className="div--modal__title">{title}</div>
          <div className="div--modal__message">
            <p>{messageInstructions}</p>
            <p>{messageLimits}</p>
            <p>{messageWelcomeWizard}</p>
            <p>
              <span className="user-edit-screen__form__label">{messageApple}</span>
              <input
                name="isAppleUser"
                onChange={this.handleChange}
                type="checkbox"
                checked={this.state.value.isAppleUser}
              />
            </p>
            <u>{messageEnd}</u>
          </div>
          <div className="div--modal__buttons">{this.renderCloseButton()}</div>
        </div>
      </div>
    );
  }
}

InfoModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  closeBtnText: PropTypes.string,
  isAppleUser: PropTypes.bool,
};

export default withNamespaces('translation')(InfoModal);
