import React from 'react';
import { withNamespaces } from 'react-i18next';

import './index.css';

import notFoundLogo from '../../images/staticPages/NotFound404/404_logo.png';

class NotFound404 extends React.PureComponent {
  render() {
    const { t } = this.props;

    return (
      <div className="not-found-page">
        <img className="not-found-page__img" alt="Forbidden" src={notFoundLogo} />
        <div className="not-found-page__code">404</div>
        <div className="not-found-page__message">{t('error.pageNotFound')}</div>
      </div>
    );
  }
}

export default withNamespaces('translation')(NotFound404);
