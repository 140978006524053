import { FETCH_LANGUAGES_SUCCESS } from '../../actions/languages';

const initialState = {
  items: [],
};

export default function languageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      };

    default:
      return state;
  }
}
