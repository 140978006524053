import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { findDOMNode } from 'react-dom';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import {
  login,
  register,
  requestPasswordRecoveryLink,
  resetPassword,
  clearMessage,
} from '../../actions/auth';
import { showInfoModal } from '../../actions/app';
import { selectLanguage } from '../../actions/languages';
import Loading from '../../components/common/Loading';

import './index.css';

import forms from './forms.json';
import logo from '../../images/logo.svg';
import auth from '../../utils/auth';
import roles from '../../constants/roles';

class AuthScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
    };
  }

  componentDidMount() {
    document.body.className = 'auth__bg';

    const {
      t,
      match: { params },
      i18n,
      lang,
    } = this.props;
    this.initializeState(params.authType);

    if (params.lang) {
      i18n.changeLanguage(params.lang);
    } else if (lang) {
      i18n.changeLanguage(lang);
    } else if (!lang) {
      i18n.changeLanguage('hr');
    }

    // validation for confirm password
    // FIXME depreceted this.ref and findDOMNode
    // eslint-disable-next-line react/no-find-dom-node,react/no-string-refs
    const elem = findDOMNode(this.refs.confirmPassword);
    if (elem) {
      elem.addEventListener('onChange', e => {
        const { value } = this.state;
        if (value.passwordConfirm !== value.password) {
          e.target.setCustomValidity(t('auth.passwordMatch'));
        } else {
          e.target.setCustomValidity('');
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      i18n,
      match: {
        params: { authType, lang },
      },
    } = this.props;
    const {
      match: {
        params: { authType: nextAuthType, lang: nextLang },
      },
    } = nextProps;

    if (nextAuthType !== authType) {
      this.initializeState(nextProps.match.params.authType);
    }

    if (nextLang !== lang) {
      i18n.changeLanguage(nextLang);
    }
  }

  initializeState = authType => {
    this.setState({ value: forms.data[authType] });
    this.props.dispatch(clearMessage());
  };

  handleChange = ({ target }) => {
    const { t } = this.props;
    this.setState(prevState => ({
      value: { ...prevState.value, [target.name]: target.value },
    }));
    if (target.name === 'confirmPassword') {
      const { value } = this.state;
      if (target.value !== value.password) {
        target.setCustomValidity(t('auth.passwordMatch'));
      } else {
        target.setCustomValidity('');
      }
    } else if (target.name === 'confirmNewPassword') {
      const { value } = this.state;
      if (target.value !== value.newPassword) {
        target.setCustomValidity(t('auth.passwordMatch'));
      } else {
        target.setCustomValidity('');
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { authType, lang, resetPasswordToken } = this.props.match.params;
    const { value } = this.state;

    switch (authType) {
      case 'login':
        this.props.dispatch(login(value)).then(() => {
          this.ifAuthed();
        });
        break;

      case 'signup':
        this.props.dispatch(
          register({
            first_name: value.firstName,
            last_name: value.lastName,
            gender: value.gender,
            date_of_birth: value.dob,
            email: value.email,
            username: value.username,
            password: value.password,
            lang: lang || '',
          }),
        );
        break;

      case 'forgotPassword':
        this.props.dispatch(
          requestPasswordRecoveryLink({
            email: value.forgotPasswordEmail,
            lang: lang || '', // lang!!
          }),
        );
        break;

      case 'resetPassword':
        this.props.dispatch(
          resetPassword({
            reset_password_token: resetPasswordToken,
            new_password: value.newPassword,
          }),
        );
        break;

      default:
        break;
    }
  };

  redirectUser = () => {
    const {
      dispatch,
      history,
      location: { state },
    } = this.props;
    let redirectRoute = state && state.from && state.from.pathname;

    if (auth.isAuthed() && auth.isRole([roles.ACCOUNT_GENERATOR_ROLE])) {
      redirectRoute = '/account-generator';
    }

    if (auth.isAuthed() && auth.isRole([roles.ORGANIZATION_MANAGER_ROLE])) {
      redirectRoute = '/organization-management';
    }

    dispatch(showInfoModal());
    history.replace(redirectRoute || '/');
  };

  ifAuthed = () => {
    const { i18n, lang, dispatch } = this.props;

    dispatch(selectLanguage(lang));
    i18n.changeLanguage(lang);
    this.redirectUser();
  };

  renderInput = field => (
    <div key={field.name}>
      {field.type === 'select' ? (
        <select
          name={field.name}
          onChange={this.handleChange}
          value={this.state.value[field.name] || ''}
          defaultValue=""
        >
          <option>Select gender</option>
          {field.options.map(o => (
            <option key={o}>{o}</option>
          ))}
        </select>
      ) : (
        <input
          placeholder={this.props.t(`auth.${field.name}`)}
          name={field.name}
          ref={field.name}
          onChange={this.handleChange}
          type={field.type}
          value={this.state.value[field.name] || ''}
          required={field.required}
          pattern={field.pattern}
          title={field.title}
        />
      )}
    </div>
  );

  renderLangLinks = () => {
    const {
      match: { params },
    } = this.props;
    const isLogin = params.authType === 'login';
    const languagesToRender = ['hr'];

    return (
      <div className="auth__lang">
        {languagesToRender.map(lang => (
          <Link
            key={lang}
            className={`auth__lang-item ${params.lang === lang ? 'auth__lang-item--selected' : ''}`}
            to={`../${lang}/${isLogin ? 'login' : 'signup'}`}
          >
            {lang}
          </Link>
        ))}
      </div>
    );
  };

  renderInputsAndMessage = () => {
    const {
      match: { params },
      t,
      message,
    } = this.props;
    const inputs = forms.views[params.authType] || [];

    const submitButtonName = {
      login: t('auth.login'),
      signup: t('auth.register'),
      forgotPassword: t('auth.sendRecoveryLink'),
      resetPassword: t('auth.resetPassword'),
    };

    return (
      <div>
        {!message && inputs.map(this.renderInput)}
        {!message && (
          <button className="auth__form-button" type="submit">
            {submitButtonName[params.authType]}
          </button>
        )}

        {message && (
          <div>
            <div className="auth__message">{t([`auth.${message}`, `error.genericError`])}</div>
            <br />
          </div>
        )}
      </div>
    );
  };

  renderLoginAndRegisterLinks = () => {
    const {
      match: { params },
      t,
    } = this.props;
    const isLogin = params.authType === 'login';

    return (
      <div>
        <Link
          className="auth__form-link"
          replace
          to={`${params.lang ? `/${params.lang}` : ''}/${isLogin ? 'signup' : 'login'}`}
        >
          {isLogin ? t('auth.register') : t('auth.login')}
        </Link>
      </div>
    );
  };

  renderForgotPasswordLink = () => {
    const {
      match: { params },
      t,
    } = this.props;
    const isLogin = params.authType === 'login';

    return (
      <div>
        {isLogin && (
          <div>
            <Link
              className="auth__form-link"
              replace
              to={`${params.lang ? `/${params.lang}` : ''}/${'forgotPassword'}`}
            >
              {t('auth.forgotPassword')}
            </Link>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      loading,
      match: { params },
      t,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (auth.isAuthed()) {
      this.ifAuthed();
    }

    const isPasswordRecovery =
      params.authType === 'forgotPassword' || params.authType === 'resetPassword';

    const recoveryPasswordFormStyle = {
      paddingTop: 40,
    };

    const authFormStyle = isPasswordRecovery ? recoveryPasswordFormStyle : {};

    return (
      <div className="auth">
        {this.renderLangLinks()}

        <img alt="logo" className="auth__logo" src={logo} />
        <h1 className="auth__title">{t('title')}</h1>

        <form className="auth__form" onSubmit={this.handleSubmit} style={authFormStyle}>
          {!isPasswordRecovery && <h2 className="auth__form-title">{t('welcome')}</h2>}
          {this.renderInputsAndMessage()}
          {this.renderLoginAndRegisterLinks()}
          {this.renderForgotPasswordLink()}
        </form>

        <p className="auth-copyright">
          Powered by E-Glas | {new Date().getFullYear()}
          <br />
          <a href="mailto:support@ehiper.eu">support@ehiper.eu</a>
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.auth.lang,
  loading: state.auth.loading,
  error: state.auth.error,
  message: state.auth.message,
});

AuthScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      authType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loading: PropTypes.bool,
};

export default compose(withNamespaces('translation'), connect(mapStateToProps))(AuthScreen);
