import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import CustomMultimediaList from '../../components/CustomMultimediaList';
import CustomTermModal from '../../components/CustomTermModal';
import MonthNavigation from '../../components/MonthNavigation';
import CustomMultimediaWizardScreen from '../CustomMultimediaWizardScreen';
import {
  fetchCustomMultimedia,
  hideTerm,
  setCustomMultimedia,
  setDefaultImage,
} from '../../actions/customMultimedia';
import { showNavigationBar } from '../../actions/app';
import multimediaTypes from '../../constants/multimediaTypes';
import customMultimedia from '../../utils/customMultimedia';
import ScrollToTop from '../../components/ScrollToTop';

import './index.css';

class CustomMultimediaScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonthIndex: 0,
      selectedTerm: null,
      showEditModal: false,
      showWizard: false,
      indexDirection: '',
    };

    this.resetDefaultAlert = withReactContent(Swal);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchCustomMultimedia());
    dispatch(showNavigationBar('myMultimedia'));
  }

  nextMonth = () => {
    const { currentMonthIndex } = this.state;
    const { customMultimediaTerms } = this.props;

    if (currentMonthIndex === customMultimediaTerms.length - 1) {
      return;
    }

    this.setState({
      currentMonthIndex: currentMonthIndex + 1,
      indexDirection: 'right',
    });
  };

  previousMonth = () => {
    const { currentMonthIndex } = this.state;
    if (currentMonthIndex === 0) {
      return;
    }

    this.setState({
      currentMonthIndex: currentMonthIndex - 1,
      indexDirection: 'left',
    });
  };

  closeModal = () => {
    this.setState({ showEditModal: false });
  };

  setCustomTermMultimedia = (data, termId) => {
    const { dispatch } = this.props;

    dispatch(setCustomMultimedia(data, multimediaTypes.IMAGE_RECTANGLE, termId));
    this.closeModal();
  };

  setDefaultImage = termId => {
    const { dispatch } = this.props;

    dispatch(setDefaultImage(multimediaTypes.IMAGE_RECTANGLE, termId));
    this.closeModal();
  };

  setDefaultImagePrompt = termId => {
    const { t } = this.props;

    this.resetDefaultAlert
      .fire({
        type: 'warning',
        title: t(`myMultimedia.wizard.deletePromptTitle`),
        text: t(`myMultimedia.wizard.deletePromptMessage`),
        showCancelButton: true,
        confirmButtonColor: '#AF6098',
        cancelButtonColor: '#FF564F',
        confirmButtonText: t(`yes`),
        cancelButtonText: t(`no`),
      })
      .then(result => {
        if (result.value) {
          this.setDefaultImage(termId);
        }
      });
  };

  selectCustomTerm = selectedTerm => {
    this.setState({ selectedTerm, showEditModal: true });
  };

  onHide = async (isHidden, termId) => {
    const { dispatch, t } = this.props;

    if (isHidden) {
      dispatch(hideTerm(!isHidden, multimediaTypes.IMAGE_RECTANGLE, termId));
      return;
    }

    const result = await this.resetDefaultAlert.fire({
      type: 'warning',
      title: t(`myMultimedia.wizard.hideTermTitle`),
      text: t(`myMultimedia.wizard.hideTermMessage`),
      showCancelButton: true,
      confirmButtonColor: '#FF564F',
      cancelButtonColor: '#AF6098',
      confirmButtonText: t(`yes`),
      cancelButtonText: t(`no`),
    });

    if (result.value) {
      dispatch(hideTerm(!isHidden, multimediaTypes.IMAGE_RECTANGLE, termId));
    }
  };

  showWizard = show => {
    this.setState({ showWizard: show });
  };

  render() {
    const {
      currentMonthIndex,
      selectedTerm,
      showEditModal,
      showWizard,
      indexDirection,
    } = this.state;
    const { customMultimediaTerms, t } = this.props;
    const currentMonthLabel =
      customMultimediaTerms[currentMonthIndex] && customMultimediaTerms[currentMonthIndex].label;

    const isHidden = customMultimedia.isTermHidden(selectedTerm);
    const buttonHideLabel = isHidden
      ? t('myMultimedia.wizard.show')
      : t('myMultimedia.wizard.hide');
    const renderNavButtons = customMultimediaTerms.length > 1;

    return (
      <>
        <ScrollToTop />
        <div className="custom-multimedia-screen">
          <div className="custom-multimedia-screen__header">
            <div className="rewards-screen__header__title">{t('navbar.myMultimedia')}</div>
            <div className="custom-multimedia-screen__header__month-nav">
              <MonthNavigation
                label={currentMonthLabel}
                renderNavButtons={renderNavButtons}
                goBack={this.previousMonth}
                goForward={this.nextMonth}
              />
            </div>
            <button
              type="button"
              className="custom-multimedia-screen__header__button"
              onClick={() => this.showWizard(true)}
            >
              {t(`myMultimedia.wizard.run`)}
            </button>
          </div>
          <CustomMultimediaList
            customTerms={customMultimediaTerms}
            currentMonthIndex={currentMonthIndex}
            indexDirection={indexDirection}
            onSelect={this.selectCustomTerm}
          />

          <CustomMultimediaWizardScreen
            show={showWizard}
            initialUiState={CustomMultimediaWizardScreen.uiStates.WIZARD_MODAL}
            onExit={() => this.showWizard(false)}
          />

          {selectedTerm && (
            <CustomTermModal
              termId={selectedTerm.id}
              month={currentMonthLabel}
              label={selectedTerm.label}
              subLabel={isHidden ? t('myMultimedia.wizard.thisTermIsHidden') : ''}
              multimediaUrl={
                selectedTerm.customMultimedia && selectedTerm.customMultimedia.url
                  ? selectedTerm.customMultimedia.url
                  : selectedTerm.multimediaUrl
              }
              show={showEditModal}
              onExit={this.closeModal}
              onImageSelected={this.setCustomTermMultimedia}
              onHide={() => this.onHide(isHidden, selectedTerm.id)}
              hideLabel={buttonHideLabel}
              onKeep={this.closeModal}
              onDefault={
                selectedTerm.customMultimedia &&
                selectedTerm.customMultimedia.url &&
                (() => this.setDefaultImagePrompt(selectedTerm.id))
              }
            />
          )}
        </div>
      </>
    );
  }
}

CustomMultimediaScreen.propTypes = {
  customMultimediaTerms: PropTypes.array,
};

const mapStateToProps = state => ({
  customMultimediaTerms: state.customMultimedia.customMultimediaTerms,
});

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(CustomMultimediaScreen);
