import PropTypes from 'prop-types';
import React from 'react';
import CardList from '../common/CardList';
import LessonCard from '../LessonCard';

function LessonList({ lessons, monthId }) {
  return (
    <CardList>
      {!!lessons &&
        lessons.map((item, i) => (
          <LessonCard
            key={item.lesson.id}
            index={i}
            monthId={monthId}
            lesson={item.lesson}
            preloaded={item.preloadStatus}
          />
        ))}
    </CardList>
  );
}

LessonList.propTypes = {
  lessons: PropTypes.array,
  monthId: PropTypes.number.isRequired,
};

export default LessonList;
