import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { hideNavigationBar } from '../../actions/app';
import { common, lessonHistory, auth } from '../../utils';
import Section from '../Section';
import LoadingBar from '../common/LoadingBar';
import roles from '../../constants/roles';
import { insertNewAttempt } from '../../actions/lessonsAttempts';

import './index.css';

class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSectionIndex: -1,
      terms: {},
      preloadedSections: {},
      preloadedImgCounter: 0,
      preloadedAudioCounter: 0,
      preloadedVideoCounter: 0,
      preloadedSectionsAndTermsLength: 0,
      type: null,
      iOSpreload: false,
    };

    this.limitExceeded = withReactContent(Swal);
  }

  componentWillMount() {
    const { dispatch, sections } = this.props;

    dispatch(hideNavigationBar());
    common.preloadSections(sections, this);
  }

  showFullscreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  iOSpreload = () => {
    const { preloadedSections } = this.state;

    preloadedSections.forEach(preloadedSection => {
      if (preloadedSection.terms) {
        preloadedSection.terms.forEach(term => {
          if (term.audioObject) {
            term.audioObject.load();
          }
          if (term.videoObject) {
            term.videoObject.load();
          }
        });
      }
    });

    this.setState({ iOSpreload: true });
  };

  startLesson = () => {
    const { dispatch, id, isDailyLimitEnabled, isReproduceAll } = this.props;

    if (isDailyLimitEnabled && auth.isRole([roles.REGULAR_ROLE]) && isReproduceAll) {
      dispatch(
        insertNewAttempt({
          lesson_id: id,
        }),
      );
    }
    this.showFullscreen();
    this.getNextSectionData();
  };

  loadTerms = (terms, sectionId) => {
    const { id: lessonId } = this.props;
    let newTerms = common.shuffleArray(terms);
    let newTermsIds = newTerms.map(term => term.id);
    const sectionHistory = lessonHistory.getSectionHistory(`${lessonId}_${sectionId}`);

    if (sectionHistory) {
      for (let i = 0; i < 100; i += 1) {
        if (!sectionHistory.includes(newTermsIds.toString())) {
          break;
        }
        newTerms = common.shuffleArray(terms);
        newTermsIds = newTerms.map(term => term.id);
      }
    }
    return newTerms;
  };

  // this function gets new terms and increases activeSectionIndex by 1
  getNextSectionData = () => {
    const { onLessonEnded } = this.props;
    const { preloadedSections, activeSectionIndex } = this.state;
    const newSectionIndex = activeSectionIndex + 1;
    const hasTerms =
      !!preloadedSections[newSectionIndex] && !!preloadedSections[newSectionIndex].terms;

    if (newSectionIndex < preloadedSections.length && hasTerms) {
      const newTerms = this.loadTerms(
        preloadedSections[newSectionIndex].terms,
        preloadedSections[newSectionIndex].id,
      );
      const type = preloadedSections[newSectionIndex].display_type;
      this.setState({
        activeSectionIndex: newSectionIndex,
        terms: newTerms,
        type,
      });
    } else {
      onLessonEnded();
    }
  };

  renderLessonStart = () => {
    const { t } = this.props;
    const {
      preloadedImgCounter,
      preloadedAudioCounter,
      preloadedVideoCounter,
      preloadedSectionsAndTermsLength,
      iOSpreload,
    } = this.state;

    const preloadedCounters = preloadedImgCounter + preloadedAudioCounter + preloadedVideoCounter;
    const loadingPercentage = Math.round(
      (Math.round((preloadedCounters / (2 * preloadedSectionsAndTermsLength)) * 100) / 150) * 100,
    );

    if (
      preloadedImgCounter < preloadedSectionsAndTermsLength ||
      preloadedAudioCounter < preloadedSectionsAndTermsLength ||
      preloadedVideoCounter < preloadedSectionsAndTermsLength
    ) {
      return (
        <div className="lesson__loading">
          <div>{loadingPercentage} %</div>
          <LoadingBar loadingProgress={loadingPercentage} />
        </div>
      );
    }

    if (!iOSpreload) {
      return (
        <React.Fragment>
          <button type="button" className="lesson__start-btn" onClick={this.iOSpreload}>
            {t('lesson.iospreload')}
          </button>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="lesson__starting-note">{t('lesson.hint')}</div>
        <button type="button" className="lesson__start-btn" onClick={this.startLesson}>
          {t('lesson.start')}
        </button>
      </React.Fragment>
    );
  };

  render() {
    const { id, savedMultimedia } = this.props;
    const { preloadedSections, activeSectionIndex, terms, type } = this.state;

    return (
      <div className="lesson">
        {activeSectionIndex >= 0 ? (
          <Section
            nextSection={this.getNextSectionData}
            terms={terms}
            type={type}
            lessonId={id}
            id={preloadedSections[activeSectionIndex].id}
            savedMultimedia={savedMultimedia}
          />
        ) : (
          this.renderLessonStart()
        )}
      </div>
    );
  }
}

Lesson.propTypes = {
  id: PropTypes.number.isRequired,
  sections: PropTypes.array.isRequired,
  savedMultimedia: PropTypes.array,
  isDailyLimitEnabled: PropTypes.bool,
  isReproduceAll: PropTypes.bool,
};

export default compose(withNamespaces('translation'), connect())(Lesson);
