import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import './index.css';

class MessageModal extends React.Component {
  reloadPage = () => window.location.reload();

  renderRetryButton = () => (
    <button type="button" onClick={this.reloadPage}>
      {this.props.t('retry')}
    </button>
  );

  renderLogoutButton = () => (
    <button type="button" onClick={this.props.logout}>
      {this.props.t('navbar.logout')}
    </button>
  );

  renderCloseButton = () => (
    <button type="button" onClick={this.props.onClose}>
      {this.props.closeBtnText ? this.props.closeBtnText : this.props.t('close')}
    </button>
  );

  render() {
    const { show, logoutBtn, closeBtn, retryBtn, message } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div className="backdrop">
        <div className="div--modal">
          <div className="div--modal__message">{message}</div>
          <div className="div--modal__buttons">
            {retryBtn && this.renderRetryButton()}
            {logoutBtn && this.renderLogoutButton()}
            {closeBtn && this.renderCloseButton()}
          </div>
        </div>
      </div>
    );
  }
}

MessageModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  logoutBtn: PropTypes.bool.isRequired,
  closeBtn: PropTypes.bool.isRequired,
  retryBtn: PropTypes.bool.isRequired,
  closeBtnText: PropTypes.string,
  logout: PropTypes.func,
};

export default withNamespaces('translation')(MessageModal);
