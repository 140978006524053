import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { auth } from '../../utils';
import { USER_ROLE_KEY } from '../../constants/roles';
import Forbidden403 from '../../staticComponents/Forbidden403';

const PrivateRoute = ({ component: Component, allowedRoles = [], ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!auth.isAuthed()) {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }

      const userRole = localStorage.getItem(USER_ROLE_KEY);

      if (!!userRole && !allowedRoles.includes(userRole)) {
        return <Forbidden403 />;
      }

      return <Component {...props} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default PrivateRoute;
