import React from 'react';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import CustomTermModal from '../../components/CustomTermModal';
import MessageModal from '../../components/common/MessageModal';
import {
  fetchCustomMultimedia,
  hideTerm,
  setCustomMultimedia,
  setDefaultImage,
} from '../../actions/customMultimedia';

import multimediaTypes from '../../constants/multimediaTypes';
import customMultimedia from '../../utils/customMultimedia';

const UI_STATES = {
  INSTRUCTIONS_PROMPT: 0,
  WIZARD_MODAL: 1,
};

class CustomMultimediaWizardScreen extends React.Component {
  constructor(props) {
    super(props);

    this.uiStates = UI_STATES;

    this.initialState = {
      currentMonthIndex: 0,
      currentTermIndex: 0,
      currentUiState: this.props.initialUiState,
    };

    this.state = this.initialState;

    this.resetDefaultAlert = withReactContent(Swal);
  }

  componentDidMount() {
    const { dispatch, show } = this.props;

    if (show) {
      dispatch(fetchCustomMultimedia());
    }
  }

  closeWizardModalEvent = e => {
    e.preventDefault();
    this.closeWizardModal();
  };

  closeWizardModal = () => {
    this.setState(this.initialState);
    this.props.onExit();
  };

  closeMessageModal = () =>
    this.setState(prevState => ({ currentUiState: prevState.currentUiState + 1 }));

  nextMonth = () => {
    const { currentMonthIndex } = this.state;
    const { customMultimediaTerms } = this.props;

    if (currentMonthIndex === customMultimediaTerms.length - 1) {
      this.closeWizardModal();
      return;
    }

    this.setState({
      currentMonthIndex: currentMonthIndex + 1,
    });
  };

  nextCustomTerm = () => {
    const { currentMonthIndex, currentTermIndex } = this.state;
    const { customMultimediaTerms } = this.props;

    if (currentTermIndex === customMultimediaTerms[currentMonthIndex].terms.length - 1) {
      this.nextMonth();
      this.setState({
        currentTermIndex: 0,
      });
      return;
    }

    this.setState({
      currentTermIndex: currentTermIndex + 1,
    });
  };

  setCustomTermMultimedia = (data, termId) => {
    const { dispatch } = this.props;

    dispatch(setCustomMultimedia(data, multimediaTypes.IMAGE_RECTANGLE, termId));
    this.nextCustomTerm();
  };

  setDefaultImage = termId => {
    const { dispatch } = this.props;

    dispatch(setDefaultImage(multimediaTypes.IMAGE_RECTANGLE, termId));
    this.nextCustomTerm();
  };

  setDefaultImagePrompt = termId => {
    const { t } = this.props;

    this.resetDefaultAlert
      .fire({
        type: 'warning',
        title: t(`myMultimedia.wizard.deletePromptTitle`),
        text: t(`myMultimedia.wizard.deletePromptMessage`),
        showCancelButton: true,
        confirmButtonColor: '#FF564F',
        cancelButtonColor: '#AF6098',
        confirmButtonText: t(`yes`),
        cancelButtonText: t(`no`),
      })
      .then(result => {
        if (result.value) {
          this.setDefaultImage(termId);
        }
      });
  };

  onHide = async (isHidden, termId) => {
    const { dispatch, t } = this.props;

    if (isHidden) {
      dispatch(hideTerm(!isHidden, multimediaTypes.IMAGE_RECTANGLE, termId));
      return;
    }

    const result = await this.resetDefaultAlert.fire({
      type: 'warning',
      title: t(`myMultimedia.wizard.hideTermTitle`),
      text: t(`myMultimedia.wizard.hideTermMessage`),
      showCancelButton: true,
      confirmButtonColor: '#FF564F',
      cancelButtonColor: '#AF6098',
      confirmButtonText: t(`yes`),
      cancelButtonText: t(`no`),
    });

    if (result.value) {
      dispatch(hideTerm(!isHidden, multimediaTypes.IMAGE_RECTANGLE, termId));
    }
  };

  renderPrompt = message => (
    <div>
      <MessageModal
        show
        onClose={this.closeMessageModal}
        logoutBtn={false}
        retryBtn={false}
        closeBtn
        closeBtnText={this.props.t(`ok`)}
        message={message}
      />
    </div>
  );

  renderWizard = (currentMonth, currentTerm) => {
    const { t } = this.props;

    const isHidden = customMultimedia.isTermHidden(currentTerm);
    const buttonHideLabel = isHidden
      ? t('myMultimedia.wizard.show')
      : t('myMultimedia.wizard.hide');

    return (
      <div>
        <CustomTermModal
          termId={currentTerm.id}
          month={currentMonth.label}
          label={currentTerm.label}
          subLabel={isHidden ? t('myMultimedia.wizard.thisTermIsHidden') : ''}
          multimediaUrl={
            currentTerm.customMultimedia && currentTerm.customMultimedia.url
              ? currentTerm.customMultimedia.url
              : currentTerm.multimediaUrl
          }
          show
          onExit={this.closeWizardModalEvent}
          onImageChange={() => {}}
          onImageSelected={this.setCustomTermMultimedia}
          onHide={() => this.onHide(isHidden, currentTerm.id)}
          hideLabel={buttonHideLabel}
          onKeep={this.nextCustomTerm}
          onDefault={
            currentTerm.customMultimedia &&
            currentTerm.customMultimedia.url &&
            (() => this.setDefaultImagePrompt(currentTerm.id))
          }
        />
      </div>
    );
  };

  render() {
    const { show } = this.props;

    if (!show) {
      return null;
    }

    const { currentMonthIndex, currentTermIndex, currentUiState } = this.state;
    const { customMultimediaTerms, instructionsMessage } = this.props;

    const currentMonth = customMultimediaTerms[currentMonthIndex];
    if (!currentMonth) {
      return null;
    }

    const currentMonthTerms = currentMonth.terms;
    const currentTerm = currentMonthTerms[currentTermIndex];

    switch (currentUiState) {
      case this.uiStates.INSTRUCTIONS_PROMPT:
        return this.renderPrompt(instructionsMessage);
      case this.uiStates.WIZARD_MODAL:
        return this.renderWizard(currentMonth, currentTerm);
      default:
        return null;
    }
  }
}

CustomMultimediaWizardScreen.uiStates = UI_STATES;

CustomMultimediaWizardScreen.propTypes = {
  customMultimediaTerms: PropTypes.array,
  initialUiState: PropTypes.oneOf(Object.values(UI_STATES)),
  instructionsMessage: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  customMultimediaTerms: state.customMultimedia.customMultimediaTerms,
});

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(CustomMultimediaWizardScreen);
