import localForage from 'localforage';
import config from '../config';

async function handleErrors(response) {
  if (!response.ok) {
    let content = response.statusText;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      content = await response.json();
    } else {
      content = await response.text();
    }
    throw Error(content);
  }
  return response;
}

function fetchBegin(type) {
  return {
    type,
  };
}

function fetchBeginSingle(type, data) {
  return {
    type,
    payload: { data },
  };
}

function fetchSuccess(type, data) {
  return {
    type,
    payload: { data },
  };
}

function fetchError(type, error) {
  return {
    type,
    payload: { error },
  };
}

function fetchActionWrapper(path, constants = {}, options = {}) {
  const DEFAULTS = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  };

  options = { ...DEFAULTS, ...options }; // eslint-disable-line no-param-reassign

  return dispatch => {
    dispatch(fetchBegin(constants.begin));
    return fetch(`${config.apiUrl}/${path}`, options)
      .then(handleErrors)
      .then(res => {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return res.json();
        }
        return res.text();
      })
      .then(res => {
        dispatch(fetchSuccess(constants.success, res));
        return res;
      })
      .catch(error => dispatch(fetchError(constants.failure, error)));
  };
}

function lessonsFetchActionWrapper(path, constants = {}, options = {}, monthId) {
  const DEFAULTS = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  };

  options = { ...DEFAULTS, ...options }; // eslint-disable-line no-param-reassign

  return dispatch => {
    dispatch(fetchBegin(constants.begin));
    return fetch(`${config.apiUrl}/${path}`, options)
      .then(handleErrors)
      .then(res => {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return res.json();
        }
        return res.text();
      })
      .then(res => {
        dispatch(fetchSuccess(constants.success, { lessons: res, monthId }));
        return res;
      })
      .catch(error => dispatch(fetchError(constants.failure, error)));
  };
}

function fetchMultipleActionWrapper(multimedia, constants = {}, lessonId) {
  return dispatch => {
    dispatch(fetchBeginSingle(constants.begin, { id: lessonId }));
    Promise.all(
      // eslint-disable-next-line array-callback-return
      multimedia.map(newItem => {
        const itemName = `${newItem.termId}-${newItem.type}`;
        localForage.setItem(itemName, {
          ...newItem,
        });
      }),
    )
      .then(() =>
        dispatch(
          fetchSuccess(constants.success, {
            id: lessonId,
          }),
        ),
      )
      .catch(error => dispatch(fetchError(constants.failure, { error, id: lessonId })));
  };
}

function createActionWrapper(path, constants = {}, options = {}) {
  const DEFAULTS = {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  };

  options = { ...DEFAULTS, ...options }; // eslint-disable-line no-param-reassign

  return dispatch => {
    dispatch(fetchBegin(constants.begin));
    return fetch(`${config.apiUrl}/${path}`, options)
      .then(handleErrors)
      .then(res => {
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return res.json();
        }
        return res.text();
      })
      .then(res => {
        let json = options.body;
        if (options.body instanceof FormData) {
          const object = {};
          options.body.forEach((value, key) => {
            object[key] = value;
          });
          json = JSON.stringify(object);
        }
        const item = JSON.parse(json);
        dispatch(
          fetchSuccess(constants.success, {
            ...res,
            label: item.label,
            order_number: 0,
          }),
        );
        return res;
      })
      .catch(error => dispatch(fetchError(constants.failure, error)));
  };
}

export default {
  handleErrors,
  fetchActionWrapper,
  createActionWrapper,
  fetchMultipleActionWrapper,
  lessonsFetchActionWrapper,
};
