import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

function HtmlElement({ t, translationPath, Tag }) {
  return <Tag dangerouslySetInnerHTML={{ __html: t(translationPath) }} />;
}

HtmlElement.propTypes = {
  translationPath: PropTypes.string.isRequired,
  Tag: PropTypes.string.isRequired,
};

export default compose(withNamespaces('translation'), connect())(HtmlElement);
