import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProgressBar from '../ProgressBar';
import auth from '../../utils/auth';
import roles from '../../constants/roles';

import './index.css';

function TitleProgressBar({ progressBarInfo, lessonsAttempts }) {
  if (
    !lessonsAttempts.isDailyLimitEnabled ||
    !auth.isRole([roles.REGULAR_ROLE]) ||
    !progressBarInfo
  ) {
    return null;
  }

  return (
    <div className="title-progress">
      <p>{progressBarInfo.text}</p>
      <ProgressBar
        info={progressBarInfo}
        containerClassName="progress title-progress-bar"
        labelClassName="title-progress-bar--label"
      />
    </div>
  );
}

TitleProgressBar.propTypes = {
  progressBarInfo: PropTypes.object,
  lessonsAttempts: PropTypes.object,
};

const mapStateToProps = state => ({
  lessonsAttempts: state.lessonsAttempts.attemptsInfo,
});

export default connect(mapStateToProps)(TitleProgressBar);
