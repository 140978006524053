import { network, common } from '../../utils';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';

export const FETCH_CUSTOM_MULTIMEDIA_BEGIN = 'FETCH_CUSTOM_MULTIMEDIA_BEGIN';
export const FETCH_CUSTOM_MULTIMEDIA_SUCCESS = 'FETCH_CUSTOM_MULTIMEDIA_SUCCESS';
export const FETCH_CUSTOM_MULTIMEDIA_FAILURE = 'FETCH_CUSTOM_MULTIMEDIA_FAILURE';
export const CREATE_CUSTOM_MULTIMEDIA_BEGIN = 'CREATE_CUSTOM_MULTIMEDIA_BEGIN';
export const CREATE_CUSTOM_MULTIMEDIA_SUCCESS = 'CREATE_CUSTOM_MULTIMEDIA_SUCCESS';
export const CREATE_CUSTOM_MULTIMEDIA_FAILURE = 'CREATE_CUSTOM_MULTIMEDIA_FAILURE';
export const SET_DEFAULT_IMAGE_BEGIN = 'SET_DEFAULT_IMAGE_BEGIN';
export const SET_DEFAULT_IMAGE_SUCCESS = 'SET_DEFAULT_IMAGE_SUCCESS';
export const SET_DEFAULT_IMAGE_FAILURE = 'SET_DEFAULT_IMAGE_FAILURE';
export const HIDE_TERM_SUCCESS = 'HIDE_TERM_SUCCESS';

export const fetchCustomMultimedia = () =>
  network.fetchActionWrapper(
    `customMultimedia/image-rectangle/`,
    {
      begin: FETCH_CUSTOM_MULTIMEDIA_BEGIN,
      success: FETCH_CUSTOM_MULTIMEDIA_SUCCESS,
      failure: FETCH_CUSTOM_MULTIMEDIA_FAILURE,
    },
    {},
  );

export const setCustomMultimedia = (data, multimediaType, termId) => {
  const formData = new FormData();
  Object.keys(data).map(k => formData.append(k, common.dataURLToBlob(data[k]), 'test.jpg'));

  return network.fetchActionWrapper(
    `customMultimedia/${multimediaType}/${termId}`,
    {
      begin: CREATE_CUSTOM_MULTIMEDIA_BEGIN,
      success: CREATE_CUSTOM_MULTIMEDIA_SUCCESS,
      failure: CREATE_CUSTOM_MULTIMEDIA_FAILURE,
    },
    {
      method: 'PUT',
      body: formData,
    },
  );
};

export const hideTerm = (isHidden, multimediaType, termId) => {
  const formData = new FormData();
  formData.append('isHidden', isHidden ? '1' : '0');

  return network.fetchActionWrapper(
    `customMultimedia/${multimediaType}/${termId}`,
    {
      begin: ACTION_BEGIN,
      success: HIDE_TERM_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'PATCH',
      body: formData,
    },
  );
};

export const setDefaultImage = (multimediaType, termId) =>
  network.fetchActionWrapper(
    `customMultimedia/${multimediaType}/${termId}`,
    {
      begin: SET_DEFAULT_IMAGE_BEGIN,
      success: SET_DEFAULT_IMAGE_SUCCESS,
      failure: SET_DEFAULT_IMAGE_FAILURE,
    },
    {
      method: 'DELETE',
    },
  );
