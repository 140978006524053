import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ChevronUp } from './chevron-up.svg';

import './index.css';

function scrollToTop() {
  window.scrollTo(0, 0);
}

// code reference
// https://stackoverflow.com/questions/57088861/react-setstate-hook-from-scroll-event-listener
function ScrollToTop() {
  const prevScrollY = useRef(0);

  const [renderButton, setRenderButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < 160 || (prevScrollY.current < currentScrollY && renderButton)) {
        setRenderButton(false);
      }
      if (currentScrollY > 160 && prevScrollY.current > currentScrollY && !renderButton) {
        setRenderButton(true);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [renderButton]);

  return renderButton ? (
    <button type="button" className="scroll-to-top" onClick={scrollToTop}>
      <ChevronUp />
    </button>
  ) : null;
}

export default ScrollToTop;
