import {
  CREATE_CUSTOM_MULTIMEDIA_SUCCESS,
  FETCH_CUSTOM_MULTIMEDIA_SUCCESS,
  HIDE_TERM_SUCCESS,
  SET_DEFAULT_IMAGE_SUCCESS,
} from '../../actions/customMultimedia';

const initialState = {
  customMultimediaTerms: [],
};

export default function customMultimediaReducer(state = initialState, action) {
  let newCustomTerms = [];
  let termId = null;
  let newUrl = '';

  switch (action.type) {
    case FETCH_CUSTOM_MULTIMEDIA_SUCCESS:
      return {
        ...state,
        customMultimediaTerms: action.payload.data,
      };
    case HIDE_TERM_SUCCESS:
      // eslint-disable-next-line prefer-destructuring
      termId = action.payload.data.termId;
      newCustomTerms = [...state.customMultimediaTerms];

      newCustomTerms = newCustomTerms.map(month => {
        month.terms.map(term => {
          const newTerm = term;

          if (newTerm.id.toString() === termId) {
            newTerm.customMultimedia = newTerm.customMultimedia || {};
            newTerm.customMultimedia.isHidden = action.payload.data.isHidden;
          }

          return newTerm;
        });

        return month;
      });

      return {
        ...state,
        customMultimediaTerms: newCustomTerms,
      };
    case SET_DEFAULT_IMAGE_SUCCESS:
      // eslint-disable-next-line prefer-destructuring
      termId = action.payload.data.termId;
      newCustomTerms = [...state.customMultimediaTerms];

      newCustomTerms = newCustomTerms.map(month => {
        month.terms.map(term => {
          const newTerm = term;

          if (newTerm.id.toString() === termId && newTerm.customMultimedia) {
            newTerm.customMultimedia = newTerm.customMultimedia || {};
            newTerm.customMultimedia.url = '';
          }

          return newTerm;
        });

        return month;
      });

      return {
        ...state,
        customMultimediaTerms: newCustomTerms,
      };
    case CREATE_CUSTOM_MULTIMEDIA_SUCCESS:
      // eslint-disable-next-line prefer-destructuring
      termId = action.payload.data.termId;
      // eslint-disable-next-line prefer-destructuring
      newUrl = action.payload.data.newUrl;
      newCustomTerms = [...state.customMultimediaTerms];

      newCustomTerms = newCustomTerms.map(month => {
        month.terms.map(term => {
          const newTerm = term;

          if (newTerm.id.toString() === termId) {
            newTerm.customMultimedia = newTerm.customMultimedia || {};
            newTerm.customMultimedia.url = newUrl;
          }

          return newTerm;
        });

        return month;
      });

      return {
        ...state,
        customMultimediaTerms: newCustomTerms,
      };
    default:
      return state;
  }
}
