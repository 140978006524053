/* eslint no-case-declarations: 0 */

import {
  FETCH_LESSON_SUCCESS,
  FETCH_LESSONS_SUCCESS,
  SELECT_LESSON,
  CREATE_LESSON_SUCCESS,
  EDIT_LESSON_SUCCESS,
  DELETE_LESSON_SUCCESS,
  PRELOAD_LESSON_MULTIMEDIA_SUCCESS,
  PRELOAD_LESSON_MULTIMEDIA_BEGIN,
  PRELOAD_LESSON_MULTIMEDIA_FAILURE,
  PUT_LESSON_GAME_PROGRESS_SUCCESS,
} from '../../actions/lessons';

const initialState = {
  months: {},
  selectedId: null,
};

function initLessonItems(items) {
  return items.map(lesson => ({
    lesson,
    preloadStatus: '',
  }));
}

export default function lessonReducer(state = initialState, action) {
  let currentMonths;
  let lessonIndex;

  switch (action.type) {
    case FETCH_LESSON_SUCCESS:
      currentMonths = { ...state.months };

      lessonIndex =
        currentMonths[action.payload.data.monthId] &&
        currentMonths[action.payload.data.monthId].findIndex(
          lesson => lesson.id === action.payload.data.lesson.id,
        );

      if (lessonIndex && lessonIndex > -1) {
        currentMonths[action.payload.data.monthId][lessonIndex] = initLessonItems([
          action.payload.data.lesson,
        ]);
      } else {
        currentMonths[action.payload.data.monthId] = initLessonItems([action.payload.data.lesson]);
      }

      return {
        ...state,
        months: currentMonths,
        selectedId: action.payload.data.lesson.id,
      };

    case SELECT_LESSON:
      return {
        ...state,
        selectedId: action.payload.data,
      };

    case FETCH_LESSONS_SUCCESS:
      const currentMonths2 = state.months;
      currentMonths2[action.payload.data.monthId] = initLessonItems(action.payload.data.lessons);
      return {
        ...state,
        months: currentMonths2,
      };

    case CREATE_LESSON_SUCCESS:
      currentMonths = state.months;
      currentMonths[action.payload.data.monthId] = [
        ...state.months[action.payload.data.monthId],
        ...initLessonItems([action.payload.data]),
      ];
      return {
        ...state,
        months: currentMonths,
      };

    case DELETE_LESSON_SUCCESS:
      currentMonths = state.months;
      Object.entries(currentMonths).forEach(([key, value]) => {
        const tempValue = value;
        const index = tempValue.findIndex(
          item => item.lesson.id.toString() === action.payload.data,
        );

        if (index > -1) {
          tempValue.splice(index, 1);
          currentMonths[key] = tempValue;
        }
      });
      return {
        ...state,
        months: { ...currentMonths },
      };

    case EDIT_LESSON_SUCCESS:
      currentMonths = state.months;
      Object.entries(currentMonths).forEach(([key, value]) => {
        const tempValue = value;
        const index = tempValue.findIndex(item => item.lesson.id === action.payload.data.id);

        if (index > -1) {
          tempValue[index].lesson = action.payload.data;
          currentMonths[key] = tempValue;
        }
      });
      return {
        ...state,
        months: { ...currentMonths },
      };

    case PRELOAD_LESSON_MULTIMEDIA_BEGIN:
      const currentMonths3 = state.months;
      Object.entries(currentMonths3).forEach(([key, value]) => {
        const tempValue = value;
        const preloadedIndexBegin = tempValue.findIndex(
          item => item.lesson.id === action.payload.data.id,
        );

        if (preloadedIndexBegin > -1) {
          tempValue[preloadedIndexBegin].preloadStatus = 'begin';
          currentMonths3[key] = tempValue;
        }
      });
      return {
        ...state,
        months: { ...currentMonths3 },
      };

    case PRELOAD_LESSON_MULTIMEDIA_SUCCESS:
      const currentMonths4 = state.months;
      Object.entries(currentMonths4).forEach(([key, value]) => {
        const tempValue = value;
        const preloadedIndex = tempValue.findIndex(
          item => item.lesson.id === action.payload.data.id,
        );

        if (preloadedIndex > -1) {
          tempValue[preloadedIndex].preloadStatus = 'success';
          currentMonths4[key] = tempValue;
        }
      });
      return {
        ...state,
        months: { ...currentMonths4 },
      };

    case PRELOAD_LESSON_MULTIMEDIA_FAILURE:
      const currentMonths5 = state.months;
      Object.entries(currentMonths5).forEach(([key, value]) => {
        const tempValue = value;
        const preloadedIndex = tempValue.findIndex(
          item => action.payload.data && item.lesson.id === action.payload.data.id,
        );

        if (preloadedIndex > -1) {
          tempValue[preloadedIndex].preloadStatus = 'failed';
          currentMonths5[key] = tempValue;
        }
      });
      return {
        ...state,
        months: { ...currentMonths5 },
      };

    case PUT_LESSON_GAME_PROGRESS_SUCCESS:
      currentMonths = state.months;
      Object.entries(currentMonths).forEach(([key, value]) => {
        const tempValue = value;
        const index = tempValue.findIndex(item => item.lesson.id === action.payload.data.id);

        if (index > -1) {
          tempValue[index].lesson.gameProgress = action.payload.data.gameProgress;
          currentMonths[key] = tempValue;
        }
      });
      return {
        ...state,
        months: { ...currentMonths },
      };

    default:
      return state;
  }
}
