import { FETCH_MONTH_LIMIT_SUCCESS, FETCH_SCORES_SUCCESS } from '../../actions/stats';

const initialState = {
  dailyScores: [],
  totalLessons: null,
  lessonsPassed: null,
  totalCurrentMonthLessons: null,
  gamesPassedCurrentMonth: null,
  monthLimit: [],
};

export default function rewardsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SCORES_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
      };
    case FETCH_MONTH_LIMIT_SUCCESS:
      return {
        ...state,
        monthLimit: action.payload.data,
      };
    default:
      return state;
  }
}
