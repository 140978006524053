import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import 'react-confirm-alert/src/react-confirm-alert.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { accountAdministration } from '../../actions/accountAdministration';
import TABLE_DROPDOWN from '../../constants/usersTable';

import { showNavigationBar } from '../../actions/app';
import { fetchUsers } from '../../actions/auth';

import './index.css';

class UsersScreen extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchUsers());
    dispatch(showNavigationBar('admin'));
    this.saveCellModal = withReactContent(Swal);
  }

  getColumns = () => [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: {
        width: '75px',
      },
    },
    {
      dataField: 'is_active',
      text: 'Aktivan?',
      formatter: this.boolFormatter,
      filter: selectFilter({
        options: this.boolOptions(),
      }),
      headerStyle: {
        width: '135px',
      },
      editor: {
        type: Type.SELECT,
        options: TABLE_DROPDOWN.YES_NO,
      },
    },
    {
      dataField: 'has_month_limit',
      text: 'Ima li mjesečni limit?',
      formatter: this.boolFormatter,
      filter: selectFilter({
        options: this.boolOptions(),
      }),
      headerStyle: {
        width: '135px',
      },
      editor: {
        type: Type.SELECT,
        options: TABLE_DROPDOWN.YES_NO,
      },
    },
    {
      dataField: 'original_username',
      text: 'Originalno korisničko ime',
      filter: textFilter(),
      sort: true,
      editable: false,
    },
    {
      dataField: 'username',
      text: 'Korisničko ime',
      filter: textFilter(),
      sort: true,
      editable: false,
    },
    {
      dataField: 'role_label',
      text: 'Uloga',
      sort: true,
      filter: selectFilter({
        options: this.roleOptions(),
      }),
      editable: false,
    },
    {
      dataField: 'language_code',
      text: 'Jezik',
      filter: selectFilter({
        options: this.languageOptions(),
      }),
      headerStyle: {
        width: '85px',
      },
      editable: false,
    },
    {
      dataField: 'email',
      text: 'E-Mail',
      sort: true,
      filter: textFilter(),
      headerStyle: {
        width: '300px',
      },
      editable: false,
    },
    {
      dataField: 'date_created',
      text: 'Datum nastanka',
      sort: true,
      editable: false,
    },
    {
      dataField: 'first_login',
      text: 'Barem jedna prijava u sustav?',
      formatter: this.firstLoginFormatter,
      filter: selectFilter({
        options: this.firstLoginOptions(),
      }),
      headerStyle: {
        width: '125px',
      },
      editable: false,
    },
    {
      dataField: 'first_name',
      text: 'Ime',
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'last_name',
      text: 'Prezime',
      sort: true,
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'telephone_number',
      text: 'Broj telefona',
      editable: false,
    },
  ];

  boolFormatter = cell => {
    const cellVal = cell.toString();
    return <span>{cellVal === '1' ? 'Da' : 'Ne'}</span>;
  };

  firstLoginFormatter = cell => <span>{cell === 0 ? 'Da' : 'Ne'}</span>;

  roleOptions = () => ({
    admin: 'admin',
    regular: 'regular',
    demo: 'demo',
    organization_manager: 'organization_manager',
    account_generator: 'account_generator',
  });

  boolOptions = () => ({
    0: 'Ne',
    1: 'Da',
  });

  firstLoginOptions = () => ({
    0: 'Da',
    1: 'Ne',
  });

  languageOptions = () => ({
    hr: 'hr',
    de: 'de',
  });

  getFormattedUsersData = users => {
    const yesNo = {
      0: 'Ne',
      1: 'Da',
    };

    const invertedYesNo = {
      0: 'Da',
      1: 'Ne',
    };

    const formattedUsers = users.map(a => ({ ...a }));

    formattedUsers.forEach(user => {
      // eslint-disable-next-line no-param-reassign
      user.is_active = yesNo[user.is_active];
      // eslint-disable-next-line no-param-reassign
      user.has_month_limit = yesNo[user.has_month_limit];
      // eslint-disable-next-line no-param-reassign
      user.first_login = invertedYesNo[user.first_login];

      // eslint-disable-next-line no-param-reassign
      delete user.is_email_set;
      // eslint-disable-next-line no-param-reassign
      delete user.subscription_until;
    });

    return formattedUsers;
  };

  exportToExcel = (jsonData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const range = XLSX.utils.decode_range(ws['!ref']);
    // eslint-disable-next-line no-plusplus
    for (let C = range.s.r; C <= range.e.r; ++C) {
      const address = `${XLSX.utils.encode_col(C)}1`;
      // eslint-disable-next-line no-continue
      if (!ws[address]) continue;

      const columns = this.getColumns();
      const header = columns.find(column => column.dataField === ws[address].v);

      if (header) {
        ws[address].v = header.text;
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  resetTable = () => {
    window.location.reload();
  };

  beforeSaveCell = (oldValue, newValue, { id }, { dataField }, done) => {
    const { dispatch } = this.props;

    // return if no changes made after editing cell
    if (parseInt(oldValue, 10) === parseInt(newValue, 10)) {
      return;
    }

    this.saveCellModal
      .fire({
        type: 'warning',
        title: 'Želite li prihvatiti promjene?',
        text: `${
          this.getColumns().find(item => item.dataField === dataField).text
        } iz ${oldValue} u ${newValue}`,
        showCancelButton: true,
        confirmButtonColor: '#FF564F',
        cancelButtonColor: '#AF6098',
        confirmButtonText: 'Prihvati',
        cancelButtonText: 'Odbaci',
      })
      .then(result => {
        if (result.value) {
          const dbRequestBody = { id, [dataField]: newValue };
          dispatch(accountAdministration(dbRequestBody));
          done();
        } else {
          done(false);
        }
      });
    // eslint-disable-next-line consistent-return
    return { async: true };
  };

  render() {
    const { users } = this.props;

    if (!users) {
      return null;
    }

    return (
      <div className="users-screen">
        <Link to="../admin">Nazad</Link>
        <br />
        <Button
          onClick={() => {
            const formattedUsers = this.getFormattedUsersData(users);
            this.exportToExcel(formattedUsers, `Hiper-Korisnici-${new Date().toISOString()}`);
          }}
        >
          Izvezi u Excel datoteku (.xlsx)
        </Button>
        <br />
        <Button onClick={this.resetTable}>Resetiraj filtere i sortove</Button>
        <br />
        <span className="users-info">
          Napomene:
          <ul>
            <li>Za sortiranje, potrebno je kliknuti na ime stupca</li>
            <li>
              Podatak o barem jednoj prijavi u sustav je točan i ispravan samo za <b>regularne</b>{' '}
              korisnike
            </li>
            <li>
              Originalno korisničko ime je prvo korisničko ime koje je imao account i služi za
              potrebe evidencije
            </li>
            <li>Neaktivan korisnik je isto što i onemogućen korisik (ne može se ulogirati)</li>
          </ul>
        </span>
        <br />
        <span className="users-info">
          Omogućeno je uređivanje stupaca:
          <br />
          <span>(kliknuti na polje)</span>
          <ul>
            <li>Aktivan?</li>
            <li>Ima li mjesečni limit?</li>
          </ul>
        </span>
        <div className="users-table">
          <BootstrapTable
            ref={n => {
              this.table = n;
            }}
            keyField="id"
            data={users}
            columns={this.getColumns()}
            pagination={paginationFactory()}
            filter={filterFactory()}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              beforeSaveCell: this.beforeSaveCell,
            })}
          />
        </div>
      </div>
    );
  }
}

UsersScreen.propTypes = {
  users: PropTypes.array,
};

const mapStateToProps = state => ({
  users: state.auth.users,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(UsersScreen);
