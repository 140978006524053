import React from 'react';
import { connect } from 'react-redux';

import { createMonth, fetchMonth, updateMonth } from '../../actions/months';

import './index.css';

export class MonthFormScreen extends React.Component {
  constructor() {
    super();

    this.state = {
      label: '',
    };
  }

  componentWillMount() {
    const { dispatch, match, months } = this.props;
    const { monthId } = match.params;

    if (months.length > 0 && monthId) {
      const month = months.find(month2 => month2.id.toString() === monthId);
      this.setState({ label: month.label });
    } else if (monthId) {
      dispatch(fetchMonth(monthId));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { months, match } = this.props;
    const { monthId } = match.params;

    if (months !== nextProps.months) {
      const month = nextProps.months.find(month2 => month2.id.toString() === monthId);
      this.setState({ label: month.label });
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value });

  handleSubmit = e => {
    const { dispatch, history, match } = this.props;
    const { monthId } = match.params;

    e.preventDefault();
    if (monthId) {
      dispatch(updateMonth(this.state, monthId));
    } else {
      dispatch(createMonth(this.state));
    }
    history.replace('/admin');
  };

  render() {
    const { label } = this.state;
    const { monthId } = this.props.match.params;

    return (
      <div className="admin--month">
        <form name="home-screen-form" className="admin--month__form" onSubmit={this.handleSubmit}>
          <input
            placeholder="Naziv"
            value={label}
            name="label"
            type="text"
            onChange={this.handleChange}
            required
          />

          <button className="admin--month__form-button" type="submit">
            {monthId ? 'Izmijeni' : 'Dodaj'} mjesec
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  months: state.months.items,
});

export default connect(mapStateToProps)(MonthFormScreen);
