import { network } from '../../utils';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';

export const INSERT_ATTEMPT_SUCCESS = 'INSERT_ATTEMPT_SUCCESS';
export const FETCH_ATTEMPTS_SUCCESS = 'FETCH_ATTEMPTS_SUCCESS';

export const insertNewAttempt = data =>
  network.createActionWrapper(
    'userPlayedLesson',
    {
      begin: ACTION_BEGIN,
      success: INSERT_ATTEMPT_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const fetchAttempts = () =>
  network.fetchActionWrapper(`userPlayedLesson`, {
    begin: ACTION_BEGIN,
    success: FETCH_ATTEMPTS_SUCCESS,
    failure: ACTION_FAILURE,
  });
