import {
  CREATE_ORGANIZATION_USERS_SUCCESS,
  DEACTIVATE_ORGANIZATION_USERS_SUCCESS,
  FETCH_ORGANIZATION_USERS_SUCCESS,
} from '../../actions/organizationManagement';

const initialState = {
  users: [],
  accountLimit: 0,
  subscriptionUntil: null,
};

export default function organizationManagementReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data.users,
        accountLimit: action.payload.data.accountLimit,
        subscriptionUntil: action.payload.data.subscriptionUntil,
      };

    case CREATE_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.data],
      };

    case DEACTIVATE_ORGANIZATION_USERS_SUCCESS:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload.data.deactivatedId),
      };

    default:
      return state;
  }
}
