import {
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  LOGOUT_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  EDIT_FIRST_LOGIN_SUCCESS,
  SET_EMAIL_SUCCESS,
  CLEAR_MESSAGE,
  EDIT_USER_SUCCESS,
  GET_USERS_SUCCESS,
} from '../../actions/auth';

import { SELECT_LANGUAGE, EDIT_USER_LANG_SUCCESS } from '../../actions/languages';

import auth from '../../utils/auth';

const initialState = {
  isAuth: auth.isAuthed(),
  lang: 'hr',
  isFirstLogin: false,
  isEmailSet: true,
  users: [],
};

export default function curriculumReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      auth.saveData(action.payload.data);
      return {
        ...state,
        isAuth: true,
        lang: action.payload.data.lang,
        isFirstLogin: !!action.payload.data.isFirstLogin,
        // temporary, for current users who have old hiper version cached without this field
        isEmailSet: 'isEmailSet' in action.payload.data ? !!action.payload.data.isEmailSet : true,
        username: action.payload.data.username,
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        email: action.payload.data.email,
        telephoneNumber: action.payload.data.telephoneNumber,
        isAppleUser: action.payload.data.isAppleUser,
        subscriptionUntil: action.payload.data.subscriptionUntil,
        organizationName: action.payload.data.organizationName,
        role: action.payload.data.role,
      };
    case REGISTER_SUCCESS:
      auth.saveData(action.payload.data);
      return {
        isAuth: true,
        lang: action.payload.data.lang,
        isFirstLogin: !!action.payload.data.isFirstLogin,
        isEmailSet: 'isEmailSet' in action.payload.data ? !!action.payload.data.isEmailSet : true,
        username: action.payload.data.username,
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        email: action.payload.data.email,
        telephoneNumber: action.payload.data.telephoneNumber,
        subscriptionUntil: action.payload.data.subscriptionUntil,
        organizationName: action.payload.data.organizationName,
        role: action.payload.data.role,
      };

    case LOGOUT_SUCCESS:
      auth.removeData();
      return {
        isAuth: false,
      };

    case REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        message: action.payload.data,
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
      };
    case EDIT_USER_LANG_SUCCESS:
    case SELECT_LANGUAGE:
      return {
        ...state,
        lang: action.payload.data.langCode,
      };
    case EDIT_FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        isFirstLogin: !!action.payload.data.firstLogin,
      };
    case SET_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload.data.email,
        isEmailSet: !!action.payload.data.isEmailSet,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        username: action.payload.data.username,
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        email: action.payload.data.email,
        telephoneNumber: action.payload.data.telephoneNumber,
        isAppleUser: action.payload.data.isAppleUser,
        message: action.payload.data.message,
      };
    default:
      return state;
  }
}
