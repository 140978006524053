import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SettingsList from '../../components/SettingsList';
import LanguageSetting from '../LanguageSetting';
import UserEditScreen from '../UserEditScreen';
import InfoScreen from '../InfoScreen';
import { showNavigationBar } from '../../actions/app';

import './index.css';

const SETTINGS = {
  INFO: 'info',
  LANGUAGE: 'language',
  EDIT_PROFILE: 'editProfile',
};

class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSetting: SETTINGS.INFO,
    };

    props.dispatch(showNavigationBar('settings'));
  }

  selectSetting = settingKey => {
    this.setState({ currentSetting: settingKey });
  };

  renderSettingComponent = () => {
    const { currentSetting } = this.state;

    switch (currentSetting) {
      case SETTINGS.INFO:
        return <InfoScreen />;
      case SETTINGS.LANGUAGE:
        return <LanguageSetting />;
      case SETTINGS.EDIT_PROFILE:
        return <UserEditScreen />;
      default:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    const { currentSetting } = this.state;

    const settingsTranslated = Object.values(SETTINGS).map(setting => ({
      key: setting,
      name: t(`settings.option.${setting}`),
    }));

    return (
      <div className="setting--section">
        <SettingsList
          currentSetting={currentSetting}
          settings={settingsTranslated}
          onSettingClick={this.selectSetting}
        />
        {this.renderSettingComponent()}
      </div>
    );
  }
}

export default compose(withNamespaces('translation'), connect())(SettingsScreen);
