import React from 'react';
import './index.css';
import PropTypes from 'prop-types';

function UserListItem(props) {
  const { user, deactivateUser, t } = props;

  return (
    <div className="user-item">
      <div className="user-item__label">{user.username}</div>
      <div className="user-item__btn-group">
        <button type="submit" onClick={() => deactivateUser(user.id)} className="user-item__delete">
          {t('organizationManagement.deactivate')}
        </button>
      </div>
    </div>
  );
}

UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
  }),
  deactivateUser: PropTypes.func.isRequired,
};

UserListItem.defaultProps = {
  user: {},
};

export default UserListItem;
