import React from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { version } from '../../../package.json';
import auth from '../../utils/auth';
import roles from '../../constants/roles';
import DATE_FORMAT from '../../constants/common';

import './index.css';

class InfoScreen extends React.PureComponent {
  renderInfo = field => {
    const { t } = this.props;
    const infoField = this.props[field];

    return (
      <>
        {infoField && (
          <div className="info-screen__fields">
            <span>
              <b>{t(`auth.${field}`)}: </b>
              {infoField}
            </span>
          </div>
        )}
      </>
    );
  };

  render() {
    const { t, userInfoKeys } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <div className="info-screen">
          <h1>{t('settings.info.label')}</h1>
          {userInfoKeys.map(this.renderInfo)}
        </div>
        <span className="version-info">
          <b>{t('settings.info.version')}: </b> {version}
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const subscriptionUntilMoment = moment(state.auth.subscriptionUntil);
  const isSubscriptionUntilValid = subscriptionUntilMoment.isValid();
  const subscriptionUntilDate = subscriptionUntilMoment.format(DATE_FORMAT);

  const isRoleForSubscription = auth.isRole([roles.REGULAR_ROLE, roles.ORGANIZATION_MANAGER_ROLE]);

  const userInfo = {
    username: state.auth.username,
    email: state.auth.email,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    telephoneNumber: state.auth.telephoneNumber,
    subscriptionUntil: isRoleForSubscription && isSubscriptionUntilValid && subscriptionUntilDate,
    organizationName: state.auth.organizationName,
    role: state.auth.role,
  };
  const userInfoKeys = Object.keys(userInfo);

  return {
    ...userInfo,
    userInfoKeys,
  };
};

export default compose(withNamespaces('translation'), connect(mapStateToProps))(InfoScreen);
