const IMAGE_SETTINGS = {
  SQUARE: {
    fieldName: 'imageSquare',
    aspectW: 1,
    aspectH: 1,
    width: 200,
    height: 200,
  },
  RECT: {
    fieldName: 'imageRect',
    aspectW: 16,
    aspectH: 9,
    width: 1000,
    height: 562,
  },
  PUZZLE: {
    fieldName: 'imagePuzzle',
    aspectW: 16,
    aspectH: 9,
    width: 1000,
    height: 562,
  },
};

export { IMAGE_SETTINGS as default };
