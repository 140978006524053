import activities from '../../images/sections/activities.png';
import all from '../../images/sections/all.png';
import encyclopedic from '../../images/sections/encyclopedic.png';
import flash from '../../images/sections/flash.png';
import game from '../../images/sections/game.png';
import opposites from '../../images/sections/opposites.png';
import reading from '../../images/sections/reading.png';

export default {
  all,
  1: flash,
  2: reading,
  3: activities,
  4: opposites,
  5: encyclopedic,
  game,
};
