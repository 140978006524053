import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

function Loading({ show }) {
  if (!show) {
    return null;
  }

  return (
    <div className="overlay">
      <div className="overlay__loading-bg">
        <div className="overlay__loading-bg-icon" />
      </div>
    </div>
  );
}

Loading.propTypes = {
  show: PropTypes.bool,
};

Loading.defaultProps = {
  show: false,
};

export default Loading;
