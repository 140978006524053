import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import moment from 'moment';
import isEmpty from 'lodash.isempty';
import Card from '../common/Card';
import { selectLessonId } from '../../actions/lessons';
import { fetchAttempts } from '../../actions/lessonsAttempts';
import { fetchMonthLimit } from '../../actions/stats';
import './index.css';
import auth from '../../utils/auth';
import roles from '../../constants/roles';

import unavailableImgUrl from '../../images/lesson_unavailable.png';
import lockedImgUrl from '../../images/lesson_locked.png';
import limits from '../../constants/limits';

const DURATION = 5;

class LessonCard extends React.Component {
  componentDidMount() {
    const { dispatch, monthLimit } = this.props;

    if (!monthLimit || !monthLimit.length) {
      dispatch(fetchMonthLimit());
    }

    if (auth.isRole([roles.REGULAR_ROLE])) {
      dispatch(fetchAttempts());
    }
    this.monthLimitModal = withReactContent(Swal);
    this.dailyLimitModal = withReactContent(Swal);
  }

  canEnter = lessonId => {
    const { lessonsAttempts } = this.props;

    if (
      isEmpty(lessonsAttempts) ||
      !lessonsAttempts.isDailyLimitEnabled ||
      !auth.isRole([roles.REGULAR_ROLE])
    ) {
      return true;
    }

    const currentLesson = lessonsAttempts.lessonsInfo.find(lesson => lesson.lessonId === lessonId);

    if (isEmpty(currentLesson)) {
      return true;
    }

    if (!currentLesson.canPlay) {
      return false;
    }

    return currentLesson.isNew
      ? !(lessonsAttempts.newLessonAttempts >= limits.DAILY_LIMIT && currentLesson.gameLevel <= 0)
      : !(lessonsAttempts.oldLessonsAttempts >= limits.DAILY_LIMIT && currentLesson.gameLevel <= 0);
  };

  isMonthLimitDisabled = lessonId => {
    const { monthLimit } = this.props;
    return (
      monthLimit &&
      monthLimit.length > 0 &&
      monthLimit.find(lessonLimit => !lessonLimit.lessonEnabled && lessonLimit.id === lessonId)
    );
  };

  showMonthLimitModal = monthLimitObj => {
    const { t } = this.props;
    const displayedCanBePlayedDate = moment(monthLimitObj.canBePlayedDate).format('DD.MM.YYYY.');
    const monthLimitMessage = `${t('messages.monthLimitLesson')} ${displayedCanBePlayedDate}`;

    this.monthLimitModal.fire({
      type: 'warning',
      text: monthLimitMessage,
    });
  };

  onLessonClicked = lessonId => {
    const { dispatch, monthLimit, t } = this.props;
    const monthLimitObj = this.isMonthLimitDisabled(lessonId);

    if (monthLimitObj || !(monthLimit && monthLimit.length)) {
      this.showMonthLimitModal(monthLimitObj);
      return;
    }

    if (this.canEnter(lessonId)) {
      dispatch(selectLessonId(lessonId));
      return;
    }

    this.dailyLimitModal.fire({
      type: 'info',
      title: t('messages.newOpenedLessonLimitTitle'),
      text: t('messages.newOpenedLessonLimit'),
      confirmButtonColor: '#AF6098',
      confirmButtonText: t('close'),
    });
  };

  render() {
    const { lesson, index, preloaded, t } = this.props;
    const start = index * DURATION + 1;
    const end = index * DURATION + 5;
    const assetLoadBlocked = preloaded !== 'success';
    let disabled = false;

    let imgUrl = null;

    if (auth.isRole([roles.ADMIN_ROLE])) {
      disabled = false;
    } else if (auth.isRole([roles.REGULAR_ROLE])) {
      disabled = !lesson.lessonEnabled;

      if (disabled) {
        imgUrl = unavailableImgUrl;
      }
    } else if (auth.isRole([roles.DEMO_ROLE])) {
      disabled = !lesson.demo;

      if (disabled) {
        imgUrl = lockedImgUrl;
      }
    }

    if (assetLoadBlocked) {
      disabled = true;
    }

    if (!disabled) {
      imgUrl = lesson.thumbnail_url;
    }

    const linkDest =
      this.isMonthLimitDisabled(lesson.id) || !this.canEnter(lesson.id)
        ? ''
        : `/lesson/${lesson.id}`;

    return (
      <Link className={`lesson-card ${disabled ? 'lesson-card--disabled' : ''}`} to={linkDest}>
        <Card
          disabled={disabled}
          onClick={() => this.onLessonClicked(lesson.id)}
          label={lesson.label}
          label2num={`${start}-${end}`}
          label2days={`${t('lesson.daysLabel')}`}
          highlighted={lesson.lessonPassed}
          img={imgUrl}
          preloaded={preloaded}
        />
      </Link>
    );
  }
}

LessonCard.propTypes = {
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  lesson: PropTypes.object.isRequired,
  preloaded: PropTypes.oneOf(['', 'begin', 'success', 'failed']).isRequired,
};

const mapStateToProps = state => ({
  monthLimit: state.stats.monthLimit,
  lessonsAttempts: state.lessonsAttempts.attemptsInfo,
});

export default withRouter(
  compose(withNamespaces('translation'), connect(mapStateToProps))(LessonCard),
);
