import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import './index.css';
import UserListItem from '../UserListItem';
import DATE_FORMAT from '../../constants/common';

class UserList extends React.PureComponent {
  renderItem = (user, index) => (
    <UserListItem
      key={index}
      user={user}
      deactivateUser={this.props.deactivateUser}
      t={this.props.t}
    />
  );

  render() {
    const { users, addUser, accountsLimit, subscriptionUntil, t } = this.props;

    const formattedSubscription = moment(subscriptionUntil).format(DATE_FORMAT);

    return (
      <div className="user-list">
        <div className="user-list__header">
          <div>
            <h1 className="user-list__header__title">
              {t('organizationManagement.users')} ({users.length}/{accountsLimit})
            </h1>
            <div className="user-list__header__subscription">
              {t('organizationManagement.subscriptionValidUntil')}: {formattedSubscription}
            </div>
          </div>
          <button
            className="user-list__header__button"
            type="button"
            disabled={users.length === accountsLimit}
            onClick={addUser}
          >
            {t('organizationManagement.addUser')}
          </button>
        </div>
        {!!users && users.map(this.renderItem)}
      </div>
    );
  }
}

UserList.propTypes = {
  users: PropTypes.array,
  addUser: PropTypes.func.isRequired,
  accountsLimit: PropTypes.number.isRequired,
  subscriptionUntil: PropTypes.string.isRequired,
};

UserList.defaultProps = {
  users: [],
};

export default UserList;
