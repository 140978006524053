import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';
import DragHandle from './DragHandle';
import './index.css';

function AdminListItem({ onClick, to, item, confirmDelete, showDragHandle, addLink }) {
  const itemStyle = {
    textDecoration: 'none',
  };

  return (
    <div className="admin-list__item">
      <DragHandle visible={showDragHandle} />
      <Link
        onClick={() => onClick(item.id)}
        to={`${to}/${item.id}`}
        style={itemStyle}
        className="admin-list__item__label"
      >
        {item.label}
      </Link>
      <div className="admin-list__item__btn-group">
        <Link to={`/admin/form${addLink}/${item.id}`} className="admin-list__item__edit">
          Edit
        </Link>
        <button
          type="submit"
          onClick={() => confirmDelete(item.id)}
          className="admin-list__item__delete"
        >
          x
        </button>
      </div>
    </div>
  );
}

AdminListItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.object),
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  confirmDelete: PropTypes.func.isRequired,
  showDragHandle: PropTypes.bool,
  addLink: PropTypes.string.isRequired,
};

AdminListItem.defaultProps = {
  item: {},
  onClick: null,
  showDragHandle: false,
};

export default SortableElement(AdminListItem);
