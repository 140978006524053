export const USER_ROLE_KEY = 'role';

const roles = {
  ADMIN_ROLE: 'admin',
  DEMO_ROLE: 'demo',
  REGULAR_ROLE: 'regular',
  ACCOUNT_GENERATOR_ROLE: 'account_generator',
  ORGANIZATION_MANAGER_ROLE: 'organization_manager',
};

export default roles;
