import { network } from '../../utils';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_BEGIN = 'REGISTER_BEGIN';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const EDIT_FIRST_LOGIN_BEGIN = 'EDIT_FIRST_LOGIN_BEGIN';
export const EDIT_FIRST_LOGIN_SUCCESS = 'EDIT_FIRST_LOGIN_SUCCESS';
export const EDIT_FIRST_LOGIN_FAILURE = 'EDIT_FIRST_LOGIN_FAILURE';

export const SET_EMAIL_SUCCESS = 'SET_EMAIL_SUCCESS';

export const REQUEST_PASSWORD_RECOVERY_LINK_BEGIN = 'REQUEST_PASSWORD_RECOVERY_LINK_BEGIN';
export const REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS = 'REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS';
export const REQUEST_PASSWORD_RECOVERY_LINK_FAILURE = 'REQUEST_PASSWORD_RECOVERY_LINK_FAILURE';

export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const login = data =>
  network.fetchActionWrapper(
    'auth/login',
    {
      begin: LOGIN_BEGIN,
      success: LOGIN_SUCCESS,
      failure: LOGIN_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const register = data =>
  network.fetchActionWrapper(
    'auth/register',
    {
      begin: REGISTER_BEGIN,
      success: REGISTER_SUCCESS,
      failure: REGISTER_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const logout = () =>
  network.fetchActionWrapper(
    'auth/logout',
    {
      begin: LOGOUT_BEGIN,
      success: LOGOUT_SUCCESS,
      failure: LOGOUT_FAILURE,
    },
    { method: 'POST' },
  );

export const requestPasswordRecoveryLink = data =>
  network.fetchActionWrapper(
    'auth/requestPasswordRecoveryLink',
    {
      begin: REQUEST_PASSWORD_RECOVERY_LINK_BEGIN,
      success: REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS,
      failure: REQUEST_PASSWORD_RECOVERY_LINK_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const resetPassword = data =>
  network.fetchActionWrapper(
    'auth/resetPassword',
    {
      begin: RESET_PASSWORD_BEGIN,
      success: RESET_PASSWORD_SUCCESS,
      failure: RESET_PASSWORD_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const clearMessage = () => dispatch => {
  dispatch({
    type: CLEAR_MESSAGE,
  });
};

export const fetchUsers = () =>
  network.fetchActionWrapper('users/list', {
    begin: ACTION_BEGIN,
    success: GET_USERS_SUCCESS,
    failure: ACTION_FAILURE,
  });

export const updateFirstLogin = isFirstLogin =>
  network.fetchActionWrapper(
    'users/firstLogin',
    {
      begin: EDIT_FIRST_LOGIN_BEGIN,
      success: EDIT_FIRST_LOGIN_SUCCESS,
      failure: EDIT_FIRST_LOGIN_FAILURE,
    },
    {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ first_login: isFirstLogin }),
    },
  );

export const setEmail = email =>
  network.fetchActionWrapper(
    'users/setEmail',
    {
      begin: ACTION_BEGIN,
      success: SET_EMAIL_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ email }),
    },
  );

export const editUser = data =>
  network.fetchActionWrapper(
    'users/edit',
    {
      begin: ACTION_BEGIN,
      success: EDIT_USER_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );
