import { network } from '../../utils';

export const FETCH_LESSONS_BEGIN = 'FETCH_LESSONS_BEGIN';
export const FETCH_LESSONS_SUCCESS = 'FETCH_LESSONS_SUCCESS';
export const FETCH_LESSONS_FAILURE = 'FETCH_LESSONS_FAILURE';
export const FETCH_LESSON_BEGIN = 'FETCH_LESSON_BEGIN';
export const FETCH_LESSON_SUCCESS = 'FETCH_LESSON_SUCCESS';
export const FETCH_LESSON_FAILURE = 'FETCH_LESSON_FAILURE';
export const SELECT_LESSON = 'SELECT_LESSON';
export const CREATE_LESSON_BEGIN = 'CREATE_LESSON_BEGIN';
export const CREATE_LESSON_FAILURE = 'CREATE_LESSON_FAILURE';
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS';
export const DELETE_LESSON_BEGIN = 'DELETE_LESSON_BEGIN';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_FAILURE = 'DELETE_LESSON_FAILURE';
export const REORDER_LESSONS_BEGIN = 'REORDER_LESSONS_BEGIN';
export const REORDER_LESSONS_SUCCESS = 'REORDER_LESSONS_SUCCESS';
export const REORDER_LESSONS_FAILURE = 'REORDER_LESSONS_FAILURE';
export const EDIT_LESSON_BEGIN = 'EDIT_LESSON_BEGIN';
export const EDIT_LESSON_FAILURE = 'EDIT_LESSON_FAILURE';
export const EDIT_LESSON_SUCCESS = 'EDIT_LESSON_SUCCESS';
export const PRELOAD_LESSON_MULTIMEDIA_BEGIN = 'PRELOAD_LESSON_MULTIMEDIA_BEGIN';
export const PRELOAD_LESSON_MULTIMEDIA_SUCCESS = 'PRELOAD_LESSON_MULTIMEDIA_SUCCESS';
export const PRELOAD_LESSON_MULTIMEDIA_FAILURE = 'PRELOAD_LESSON_MULTIMEDIA_FAILURE';
export const PUT_LESSON_GAME_PROGRESS_BEGIN = 'PUT_LESSON_GAME_PROGRESS_BEGIN';
export const PUT_LESSON_GAME_PROGRESS_SUCCESS = 'PUT_LESSON_GAME_PROGRESS_SUCCESS';
export const PUT_LESSON_GAME_PROGRESS_FAILURE = 'PUT_LESSON_GAME_PROGRESS_FAILURE';
export const PRELOAD_SINGLE_LESSON_MULTIMEDIA_BEGIN = 'PRELOAD_SINGLE_LESSON_MULTIMEDIA_BEGIN';
export const PRELOAD_SINGLE_LESSON_MULTIMEDIA_SUCCESS = 'PRELOAD_SINGLE_LESSON_MULTIMEDIA_SUCCESS';
export const PRELOAD_SINGLE_LESSON_MULTIMEDIA_FAILURE = 'PRELOAD_SINGLE_LESSON_MULTIMEDIA_FAILURE';

export const preloadAsset = (lessonId, multimedia) =>
  network.fetchMultipleActionWrapper(
    multimedia,
    {
      begin: PRELOAD_LESSON_MULTIMEDIA_BEGIN,
      success: PRELOAD_LESSON_MULTIMEDIA_SUCCESS,
      failure: PRELOAD_LESSON_MULTIMEDIA_FAILURE,
    },
    lessonId,
  );

export const preloadSingleLessonAsset = (lessonId, multimedia) =>
  network.fetchMultipleActionWrapper(
    multimedia,
    {
      begin: PRELOAD_SINGLE_LESSON_MULTIMEDIA_BEGIN,
      success: PRELOAD_SINGLE_LESSON_MULTIMEDIA_SUCCESS,
      failure: PRELOAD_SINGLE_LESSON_MULTIMEDIA_FAILURE,
    },
    lessonId,
  );

export const fetchLessons = monthId =>
  network.lessonsFetchActionWrapper(
    `months/${monthId}/lessons`,
    {
      begin: FETCH_LESSONS_BEGIN,
      success: FETCH_LESSONS_SUCCESS,
      failure: FETCH_LESSONS_FAILURE,
    },
    {},
    monthId,
  );

export const fetchLesson = id =>
  network.fetchActionWrapper(`lessons/${id}`, {
    begin: FETCH_LESSON_BEGIN,
    success: FETCH_LESSON_SUCCESS,
    failure: FETCH_LESSON_FAILURE,
  });

export const selectLesson = data => ({
  type: SELECT_LESSON,
  payload: { data },
});

export function selectLessonId(lessonId) {
  return dispatch => {
    dispatch(selectLesson(lessonId));
  };
}

export const postLessonResult = data =>
  network.fetchActionWrapper(
    'scores',
    {
      begin: PUT_LESSON_GAME_PROGRESS_BEGIN,
      success: PUT_LESSON_GAME_PROGRESS_SUCCESS,
      failure: PUT_LESSON_GAME_PROGRESS_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const updateLesson = (data, id) => {
  const formData = new FormData();
  Object.keys(data).map(k => formData.append(k, data[k]));

  return network.fetchActionWrapper(
    `lessons/${id}`,
    {
      begin: EDIT_LESSON_BEGIN,
      success: EDIT_LESSON_SUCCESS,
      failure: EDIT_LESSON_FAILURE,
    },
    {
      method: 'PUT',
      body: formData,
    },
  );
};

export const createLesson = data => {
  const formData = new FormData();
  Object.keys(data).map(k => formData.append(k, data[k]));

  return network.createActionWrapper(
    'lessons',
    {
      begin: CREATE_LESSON_BEGIN,
      success: CREATE_LESSON_SUCCESS,
      failure: CREATE_LESSON_FAILURE,
    },
    {
      method: 'POST',
      body: formData,
    },
  );
};

export const deleteLesson = lessonId =>
  network.fetchActionWrapper(
    `lessons/${lessonId}`,
    {
      begin: DELETE_LESSON_BEGIN,
      success: DELETE_LESSON_SUCCESS,
      failure: DELETE_LESSON_FAILURE,
    },
    {
      method: 'DELETE',
    },
  );

export const updateLessonsOrder = (data, monthId) =>
  network.fetchActionWrapper(
    `monthHasLessons/${monthId}`,
    {
      begin: REORDER_LESSONS_BEGIN,
      success: REORDER_LESSONS_SUCCESS,
      failure: REORDER_LESSONS_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );
