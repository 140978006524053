const VALID_IMAGE_TYPES = ['image/jpeg', 'image/pjpeg'];

const VALID_VIDEO_TYPES = ['video/mp4'];

function validFileType(file, types) {
  for (let i = 0; i < types.length; i += 1) {
    if (file.type === types[i]) {
      return true;
    }
  }
  return false;
}

function validFileName(file1, file2) {
  if (!file1 || !file2) {
    return true;
  }

  return file1.name !== file2.name;
}

export default {
  validFileType,
  validFileName,
  VALID_IMAGE_TYPES,
  VALID_VIDEO_TYPES,
};
