import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

class MonthNavigation extends React.PureComponent {
  render() {
    const { label, goBack, goForward, renderNavButtons } = this.props;

    return (
      <div className="month-nav">
        {renderNavButtons && (
          <button type="button" className="month-nav__btn" onClick={goBack}>
            &#x2039;
          </button>
        )}
        <div className="month-nav__label">{label}</div>
        {renderNavButtons && (
          <button type="button" className="month-nav__btn" onClick={goForward}>
            &#x203a;
          </button>
        )}
      </div>
    );
  }
}

MonthNavigation.propTypes = {
  label: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  renderNavButtons: PropTypes.bool.isRequired,
};

export default MonthNavigation;
