import {
  ACCOUNT_GENERATOR_FAILURE,
  ACCOUNT_GENERATOR_SUCCESS,
  CLEAR_GENERATOR_STATUS,
} from '../../actions/accountGenerator';

const initialState = {
  status: null,
};

export default function accountGeneratorReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_GENERATOR_FAILURE:
      return {
        status: 'error',
      };

    case ACCOUNT_GENERATOR_SUCCESS:
      return {
        status: 'success',
      };

    case CLEAR_GENERATOR_STATUS:
      return {
        status: null,
      };

    default:
      return state;
  }
}
