import gameLevelOne from '../../images/games/GameLevel/R01.png';
import gameLevelTwo from '../../images/games/GameLevel/R02.png';
import gameLevelThree from '../../images/games/GameLevel/R03.png';
import gameLevelFour from '../../images/games/GameLevel/R04.png';
import gameLevelFive from '../../images/games/GameLevel/R05.png';
import gameLevelSix from '../../images/games/GameLevel/R06.png';

export default {
  1: gameLevelOne,
  2: gameLevelTwo,
  3: gameLevelThree,
  4: gameLevelFour,
  5: gameLevelFive,
  6: gameLevelSix,
};
