import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import moment from 'moment';

import { fetchMonths, selectMonth } from '../../actions/months';
import MonthList from '../../components/MonthList';

export class MonthsScreen extends React.Component {
  componentDidMount() {
    const { dispatch, months } = this.props;

    if (!months || !months.length) {
      dispatch(fetchMonths());
    }

    this.messageModal = withReactContent(Swal);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedMonthId: prevSelectedMonthId } = prevProps;
    const { selectedMonthId, monthLimit } = this.props;

    if (prevSelectedMonthId !== selectedMonthId) {
      const monthLimitObj =
        monthLimit &&
        monthLimit.length > 0 &&
        monthLimit.find(
          lessonLimit => !lessonLimit.lessonEnabled && lessonLimit.monthId === selectedMonthId,
        );
      if (monthLimitObj) {
        this.monthLimitModal(monthLimitObj);
      }
    }
  }

  monthLimitModal = monthLimitObj => {
    const { t } = this.props;
    const displayedCanBePlayedDate = moment(monthLimitObj.canBePlayedDate).format('DD.MM.YYYY.');
    const monthLimitMessage = `${t('messages.monthLimit')} ${displayedCanBePlayedDate}`;

    this.messageModal.fire({
      type: 'warning',
      text: monthLimitMessage,
    });
  };

  onMonthClick = monthId => {
    const { dispatch } = this.props;
    dispatch(selectMonth(monthId));
  };

  render() {
    const { months, selectedMonthId } = this.props;

    return (
      <MonthList
        updateSelectedMonth={this.onMonthClick}
        months={months}
        selectedMonthId={selectedMonthId}
      />
    );
  }
}

MonthsScreen.propTypes = {
  selectedMonthId: PropTypes.number,
};

const mapStateToProps = state => ({
  months: state.months.items,
  loading: state.months.loading,
  error: state.months.error,
  selectedMonthId: state.months.selectedId,
  monthLimit: state.stats.monthLimit,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(MonthsScreen);
