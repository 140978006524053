import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import './index.css';

class EmailPromptModal extends React.Component {
  reloadPage = () => window.location.reload();

  renderCloseButton = () => (
    <button type="button" onClick={this.props.onClose}>
      {this.props.closeBtnText ? this.props.closeBtnText : this.props.t('close')}
    </button>
  );

  renderControl = () => (
    <input
      className="div--modal__input"
      type={this.props.inputType}
      name={this.props.inputName}
      placeholder={this.props.inputPlaceholder}
      onChange={this.props.onControlChange}
      required
    />
  );

  renderForm = () => {
    const { onSubmit, inputType, submitButtonName } = this.props;

    return (
      <form className="div--modal__form" onSubmit={onSubmit}>
        {inputType && this.renderControl()}
        <div className="div--modal__buttons">{submitButtonName && this.renderSubmitButton()}</div>
      </form>
    );
  };

  renderSubmitButton = () => <button type="submit">{this.props.submitButtonName}</button>;

  render() {
    const { show, closeBtn, message } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div className="backdrop">
        <div className="div--modal">
          <div className="div--modal__message">{message}</div>
          {this.renderForm()}
          <div className="div--modal__buttons">{closeBtn && this.renderCloseButton()}</div>
        </div>
      </div>
    );
  }
}

EmailPromptModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  closeBtn: PropTypes.bool.isRequired,
  closeBtnText: PropTypes.string,
  inputType: PropTypes.string,
  inputName: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  onControlChange: PropTypes.func,
  submitButtonName: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default withNamespaces('translation')(EmailPromptModal);
