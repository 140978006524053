import PropTypes from 'prop-types';
import React from 'react';
import './index.css';

function CardList({ children }) {
  return <div className="card-list">{children}</div>;
}

CardList.propTypes = {
  children: PropTypes.any.isRequired,
};

export default CardList;
