/* eslint no-case-declarations: 0 */
import {
  FETCH_TERMS_SUCCESS,
  SELECT_TERM,
  CREATE_TERM_SUCCESS,
  FETCH_TERM_SUCCESS,
  EDIT_TERM_SUCCESS,
  DELETE_TERM_SUCCESS,
} from '../../actions/terms';

const initialState = {
  items: [],
  selectedId: null,
  savedMultimedia: [],
};

export default function termReducer(state = initialState, action) {
  let currentTerms = [];
  let termIndex = null;

  switch (action.type) {
    case FETCH_TERMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
      };

    case SELECT_TERM:
      return {
        ...state,
        selectedId: action.id,
      };

    case FETCH_TERM_SUCCESS:
      currentTerms = [...state.items];
      termIndex = currentTerms.findIndex(item => item.id === action.payload.data.id);
      if (termIndex > -1) {
        currentTerms[termIndex] = action.payload.data;
      } else {
        currentTerms.push(action.payload.data);
      }
      return {
        ...state,
        items: currentTerms,
      };

    case CREATE_TERM_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload.data],
      };

    case DELETE_TERM_SUCCESS:
      const items = [...state.items];
      const index = items.findIndex(item => item.id.toString() === action.payload.data);
      if (index > -1) {
        items.splice(index, 1);
      }
      return {
        ...state,
        items,
      };

    case EDIT_TERM_SUCCESS:
      const terms = [...state.items];
      const editedTermIndex = terms.findIndex(item => item.id === action.payload.data.id);
      if (editedTermIndex > -1) {
        terms[editedTermIndex] = action.payload.data;
      }
      return {
        ...state,
        items: terms,
      };

    default:
      return state;
  }
}
