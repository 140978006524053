export const AUTH_ERRORS = {
  PASSWORD_INVALID_REGEX: 'passwordNotOneUpperOneLowerOneNum',
  PASSWORD_INVALID_LENGTH: 'passwordMustBeBetween8And24Chars',
  USERNAME_NOT_DEFINED: 'usernameNotDefined',
  PASSWORD_NOT_DEFINED: 'passwordNotDefined',
  USERNAME_REQUIRED: 'usernameRequired',
  PASSWORD_REQUIRED: 'passwordRequired',
  OLD_PASSWORD_INVALID: 'oldPasswordInvalid',
  NO_OLD_PASSWORD: 'noOldPassword',
  NO_NEW_PASSWORD: 'noNewPassword',
  USERNAME_TAKEN: 'usernameAlreadyTaken',
  EMAIL_TAKEN: 'emailAlreadyTaken',
};

export const AUTH_MESSAGES = {
  USER_EDIT_SUCCESS: 'userEditSuccessful',
};
