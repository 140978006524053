import { defaults } from 'react-chartjs-2';

defaults.global.legend.position = 'bottom';
defaults.global.legend.labels.boxWidth = 20;

export const dailyOverview = {
  type: 'line',
  data: {
    datasets: [
      {
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 20,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    title: {
      display: true,
      fontSize: 19,
      padding: 30,
    },
  },
  translation: {
    label: 'charts.dailyOverview.label',
    title: 'charts.dailyOverview.title',
  },
};

export const currentDoughnut = {
  type: 'doughnut',
  data: {
    datasets: [
      {
        backgroundColor: ['#eb7f2c', '#dadada'],
        hoverBackgroundColor: ['#37eb35', '#dadada'],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
  },
  translation: {
    labels: ['charts.currentProgress.label', 'charts.toLearnLabel'],
    title: 'charts.currentProgress.title',
  },
};

export const overallDoughnut = {
  type: 'doughnut',
  data: {
    datasets: [
      {
        backgroundColor: ['#a782eb', '#dadada'],
        hoverBackgroundColor: ['#37eb35', '#dadada'],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
  },
  translation: {
    labels: ['charts.overallProgress.label', 'charts.toLearnLabel'],
    title: 'charts.overallProgress.title',
  },
};
