import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { arrayMove } from 'react-sortable-hoc';

import {
  deleteSection,
  fetchSections,
  selectSection,
  updateSectionsOrder,
} from '../../actions/sections';
import AdminList from '../../components/AdminList';

class AdminSectionsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: props.sections,
    };
  }

  componentWillMount() {
    const { dispatch, lessonId, match } = this.props;

    dispatch(fetchSections(lessonId || match.params.lessonId));
  }

  componentWillReceiveProps(nextProps) {
    const { sections } = this.props;

    if (sections !== nextProps.sections) {
      this.setState({ sections: nextProps.sections });
    }
  }

  selectSectionId = id => this.props.dispatch(selectSection(id));

  deleteSection = id => this.props.dispatch(deleteSection(id));

  editSectionsOrder = sections => {
    const { dispatch, match } = this.props;
    dispatch(updateSectionsOrder(sections, match.params.lessonId));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { sections } = this.state;

    this.setState({
      sections: arrayMove(sections, oldIndex, newIndex),
    });
  };

  render() {
    const { match, t } = this.props;
    const { monthId, lessonId } = match.params;
    const { sections } = this.state;

    return (
      <AdminList
        title="Sekcije"
        itemColor="orange"
        onClick={this.selectSectionId}
        onDelete={this.deleteSection}
        to={match.url}
        items={sections.map(section => ({
          ...section,
          label: t(`lesson.sectionType.${section.label}`),
        }))}
        addLink={`/${monthId}/${lessonId}/section`}
        onSortEnd={this.onSortEnd}
        useDragHandle
        updateItemsOrder={this.editSectionsOrder}
        t={t}
      />
    );
  }
}

const mapStateToProps = state => ({
  sections: state.sections.items,
  lessonId: state.lessons.selectedId,
  monthId: state.months.selectedId,
});

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(AdminSectionsScreen);
