import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchSectionTypes } from '../../actions/sections';
import { selectMonth } from '../../actions/months';
import { fetchMonthLimit } from '../../actions/stats';
import { fetchLessons, preloadAsset } from '../../actions/lessons';
import LessonList from '../../components/LessonList';
import { common } from '../../utils';
import multimediaTypes from '../../constants/multimediaTypes';

export class LessonsScreen extends React.Component {
  componentDidMount() {
    const { selectedMonthId, dispatch, sectionTypes } = this.props;
    if (selectedMonthId) {
      dispatch(fetchMonthLimit());
      dispatch(fetchLessons(selectedMonthId))
        // this.props.lesson must stay like this because it gets
        // latest updated version of lessons from props
        .then(() => this.preloadLessonAssets(this.props.lessons));
    }
    if (!sectionTypes || !sectionTypes.length) {
      dispatch(fetchSectionTypes());
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedMonthId, dispatch } = this.props;

    if (selectedMonthId !== nextProps.selectedMonthId) {
      dispatch(selectMonth(nextProps.selectedMonthId));
      dispatch(fetchMonthLimit());
      dispatch(fetchLessons(nextProps.selectedMonthId)).then(() => {
        // this.props.lesson must stay like this because it gets
        // latest updated version of lessons from props
        this.preloadLessonAssets(this.props.lessons);
      });
    }
  }

  preloadSectionsAssets = (lessonId, sections) => {
    const { dispatch } = this.props;
    const multimediaUrls = [];

    common.getLessonTerms(sections, false).forEach(term => {
      if (term.multimedia) {
        const imgRectMultimedia = term.multimedia.find(
          m => m.type === multimediaTypes.IMAGE_RECTANGLE,
        );
        const audioMultimedia = term.multimedia.find(m => m.type === multimediaTypes.AUDIO);
        const videoMultimedia = term.multimedia.find(m => m.type === multimediaTypes.VIDEO);

        if (imgRectMultimedia) {
          multimediaUrls.push({
            ...imgRectMultimedia,
            termId: term.id,
          });
        }
        if (audioMultimedia) {
          multimediaUrls.push({
            ...audioMultimedia,
            termId: term.id,
          });
        }
        if (videoMultimedia) {
          multimediaUrls.push({
            ...videoMultimedia,
            termId: term.id,
          });
        }
      }
    });
    dispatch(preloadAsset(lessonId, multimediaUrls));
  };

  preloadLessonAssets = lessons => {
    if (!lessons) {
      return;
    }
    if (Array.isArray(lessons)) {
      lessons.forEach(item => this.preloadSectionsAssets(item.lesson.id, item.lesson.sections));
    } else {
      this.preloadSectionsAssets(lessons.lesson.id, lessons.lesson.sections);
    }
  };

  render() {
    const { lessons, selectedMonthId } = this.props;

    return <LessonList monthId={selectedMonthId} lessons={lessons} />;
  }
}

LessonsScreen.propTypes = {
  selectedMonthId: PropTypes.number,
};

const mapStateToProps = state => {
  const { months } = state.lessons;
  let lessons = null;
  if (months && months[state.months.selectedId]) {
    lessons = months[state.months.selectedId];
  }

  return {
    selectedMonthId: state.months.selectedId,
    lessons: lessons && [...lessons],
    sectionTypes: state.sections.item_types,
  };
};

export default connect(mapStateToProps)(LessonsScreen);
