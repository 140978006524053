/* eslint no-case-declarations: 0 */
import {
  FETCH_MONTHS_SUCCESS,
  CREATE_MONTH_SUCCESS,
  SELECT_MONTH,
  DELETE_MONTH_SUCCESS,
  FETCH_MONTH_SUCCESS,
  EDIT_MONTH_SUCCESS,
} from '../../actions/months';

import { SELECT_LANGUAGE, EDIT_USER_LANG_SUCCESS } from '../../actions/languages';

const initialState = {
  items: [],
  selectedId: null,
};

export default function monthReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MONTHS_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        selectedId:
          !state.selectedId && action.payload.data[0]
            ? action.payload.data[0].id
            : state.selectedId,
      };

    case SELECT_MONTH:
      return {
        ...state,
        selectedId: action.id,
      };

    case FETCH_MONTH_SUCCESS:
    case CREATE_MONTH_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload.data],
      };

    case DELETE_MONTH_SUCCESS:
      const items = [...state.items];
      const index = items.findIndex(item => item.id.toString() === action.payload.data);
      if (index > -1) {
        items.splice(index, 1);
      }
      return {
        ...state,
        items,
      };

    case EDIT_MONTH_SUCCESS:
      const months = [...state.items];
      const editedMonthIndex = months.findIndex(item => item.id === action.payload.data.id);
      if (editedMonthIndex > -1) {
        months[editedMonthIndex] = action.payload.data;
      }
      return {
        ...state,
        items: months,
      };

    case EDIT_USER_LANG_SUCCESS:
    case SELECT_LANGUAGE:
      return initialState;

    default:
      return state;
  }
}
