import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';

import Navbar from '../../components/Navbar';
import { logout } from '../../actions/auth';
import auth from '../../utils/auth';

import './index.css';

class NavbarWrapper extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { history, lang } = this.props;

    if (auth.isAuthed() !== nextProps.isAuth && !nextProps.isAuth) {
      history.replace(`${lang ? `/${lang}` : ''}/login`);
    }
  }

  logout = () => this.props.dispatch(logout());

  render() {
    const { children, display, isAuth, t, history, selectedItem } = this.props;
    return (
      <div className="navbar-wrapper">
        {display && (
          <Navbar
            isAuth={isAuth}
            logout={this.logout}
            t={t}
            history={history}
            selected={selectedItem}
          />
        )}
        <div className="navbar-wrapper__main-content">{children}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  display: state.app.displayNavbar,
  lang: state.auth.lang,
  selectedItem: state.app.selectedItem,
});

export default compose(
  withNamespaces('translation'),
  connect(mapStateToProps),
)(withRouter(NavbarWrapper));
