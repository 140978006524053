import React from 'react';
import { Transition, animated } from 'react-spring';
import PropTypes from 'prop-types';

import CardList from '../common/CardList';
import Card from '../common/Card';
import customMultimedia from '../../utils/customMultimedia';

import eyeSlash from '../../images/customMultimedia/eyeSlash.svg';

import './index.css';

function CustomMultimediaList({ customTerms, currentMonthIndex, onSelect, indexDirection }) {
  if (!customTerms.length) {
    return null;
  }

  let fromTransform;
  let leaveTransform;

  if (indexDirection === 'right') {
    fromTransform = 'translate3d(100%,0,0)';
    leaveTransform = 'translate3d(-50%,0,0)';
  } else if (indexDirection === 'left') {
    fromTransform = 'translate3d(-100%,0,0)';
    leaveTransform = 'translate3d(80%,0,0)';
  }

  return (
    <Transition
      native
      reset
      unique
      items={currentMonthIndex}
      from={{ position: 'absolute', opacity: 0, transform: fromTransform }}
      enter={{
        position: 'static',
        opacity: 1,
        transform: 'translate3d(0%,0,0)',
      }}
      leave={{ position: 'absolute', opacity: 0, transform: leaveTransform }}
    >
      {index => props => (
        <animated.div style={props}>
          <CardList>
            {!!customTerms[index].terms &&
              !!customTerms[index].terms.length &&
              customTerms[index].terms.map(item => (
                <Card
                  rect
                  disabledWithCursor={customMultimedia.isTermHidden(item)}
                  iconSrc={customMultimedia.isTermHidden(item) ? eyeSlash : ''}
                  key={item.id}
                  label={item.label}
                  onClick={() => onSelect(item)}
                  img={
                    item.customMultimedia && item.customMultimedia.url
                      ? item.customMultimedia.url
                      : item.multimediaUrl
                  }
                />
              ))}
          </CardList>
        </animated.div>
      )}
    </Transition>
  );
}

CustomMultimediaList.propTypes = {
  customTerms: PropTypes.array.isRequired,
  currentMonthIndex: PropTypes.number.isRequired,
  indexDirection: PropTypes.oneOf(['left', 'right', '']),
  onSelect: PropTypes.func.isRequired,
};

export default CustomMultimediaList;
