import cookies from './cookies';

const LESSON_HISTORY_COOKIE = 'lessonsHistory';

function getSectionHistory(sectionKey) {
  let lessonsHistory = cookies.getCookie(LESSON_HISTORY_COOKIE);

  if (lessonsHistory) {
    lessonsHistory = JSON.parse(lessonsHistory);

    if (lessonsHistory[sectionKey]) {
      return lessonsHistory[sectionKey];
    }
    return '';
  }
  return lessonsHistory;
}

function setSectionHistory(sectionKey, termsIds) {
  let lessonsHistory = {};

  if (cookies.getCookie(LESSON_HISTORY_COOKIE)) {
    lessonsHistory = JSON.parse(cookies.getCookie(LESSON_HISTORY_COOKIE));
  }

  if (!lessonsHistory[sectionKey] || lessonsHistory[sectionKey].length >= termsIds.length - 1) {
    lessonsHistory[sectionKey] = [];
  }

  lessonsHistory[sectionKey].push(termsIds.toString());
  // FIXME: duration of cookie
  cookies.setCookie(LESSON_HISTORY_COOKIE, JSON.stringify(lessonsHistory), 1);
}

export default {
  getSectionHistory,
  setSectionHistory,
};
