import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { clearMessage, editUser } from '../../actions/auth';
import forms from './forms.json';
import { AUTH_MESSAGES } from '../../constants/messages/auth';

import './index.css';

class UserEditScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
      showPassword: false,
      passwordButtonText: '',
    };

    this.actionStatusAlert = withReactContent(Swal);
  }

  componentDidMount() {
    const {
      t,
      username,
      email,
      firstName,
      lastName,
      telephoneNumber,
      isAppleUser,
      dispatch,
    } = this.props;

    dispatch(clearMessage());
    this.setState({
      value: {
        username,
        email,
        firstName,
        lastName,
        telephoneNumber,
        isAppleUser,
      },
      passwordButtonText: t('auth.changePassword'),
    });
  }

  fireConfirmationWindow = () => {
    const { t, message, dispatch } = this.props;

    this.actionStatusAlert
      .fire({
        type: 'success',
        titleText: t(`auth.${message}`),
        showConfirmButton: true,
      })
      .then(() => dispatch(clearMessage()));
  };

  handleSubmit = e => {
    e.preventDefault();
    const { value } = this.state;
    const { dispatch } = this.props;

    dispatch(editUser(value));
  };

  toggleShowPassword = () => {
    const clearedPasswordValues = {
      confirmNewPassword: '',
      newPassword: '',
      oldPassword: '',
    };

    this.setState(prevState => ({
      value: prevState.showPassword
        ? { ...prevState.value, ...clearedPasswordValues }
        : prevState.value,
      showPassword: !prevState.showPassword,
    }));

    this.handleChangePasswordButtonText();
  };

  handleChangePasswordButtonText = () => {
    const { showPassword } = this.state;
    const { t } = this.props;
    const passwordButtonText = showPassword
      ? t('auth.changePassword')
      : t('auth.hideChangePassword');
    this.setState({ passwordButtonText });
  };

  getTargetValue = target => {
    switch (target.type) {
      case 'checkbox':
        return target.checked;
      default:
        return target.value;
    }
  };

  handleChange = ({ target }) => {
    const { t } = this.props;

    this.setState(prevState => ({
      value: {
        ...prevState.value,
        [target.name]: this.getTargetValue(target),
      },
    }));

    if (target.name === 'confirmNewPassword') {
      const { value } = this.state;
      if (target.value !== value.newPassword) {
        target.setCustomValidity(t('auth.passwordMatch'));
      } else {
        target.setCustomValidity('');
      }
    }
  };

  renderInput = field => {
    const { t } = this.props;

    return (
      <div className="user-edit-screen__form__input-label-pair" key={field.name}>
        <h2
          className={`user-edit-screen__form__label${
            field.name === 'isAppleUser' ? '--checkbox' : ''
          }`}
        >
          {t(`auth.${field.name}`)}
        </h2>
        <input
          name={field.name}
          ref={field.name}
          onChange={this.handleChange}
          type={field.type}
          value={this.state.value[field.name] || ''}
          checked={this.state.value[field.name]}
          required={field.required}
          pattern={field.pattern}
        />
      </div>
    );
  };

  renderForm = () => {
    const { showPassword } = this.state;
    const { userSettings, passwords } = forms.views;
    const { t } = this.props;

    return (
      <form
        name="user-edit-screen-form"
        className="user-edit-screen__form"
        onSubmit={this.handleSubmit}
      >
        <h1>{t('settings.option.editProfile')}</h1>
        {userSettings.map(this.renderInput)}
        <button
          className="user-edit-screen__form-button"
          type="button"
          onClick={this.toggleShowPassword}
        >
          {this.state.passwordButtonText}
        </button>
        {showPassword && passwords.map(this.renderInput)}
        <button className="user-edit-screen__form-button" type="submit">
          {t('auth.editUser')}
        </button>
      </form>
    );
  };

  render() {
    const { message } = this.props;

    if (message === AUTH_MESSAGES.USER_EDIT_SUCCESS) {
      this.fireConfirmationWindow();
    }

    return <React.Fragment>{this.renderForm()}</React.Fragment>;
  }
}

const mapStateToProps = state => ({
  username: state.auth.username,
  email: state.auth.email,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  telephoneNumber: state.auth.telephoneNumber,
  message: state.auth.message,
  isAppleUser: state.auth.isAppleUser,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(UserEditScreen);
