import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { createLesson, fetchLesson, updateLesson } from '../../actions/lessons';
import validation from '../../utils/validation';
import CropperModal from '../../components/CropperModal';
import { common } from '../../utils';
import PATH_SPLIT_REGEX from '../../constants/regex';
import IMAGE_SETTINGS from '../../constants/images';

import './index.css';

class LessonFormScreen extends React.Component {
  constructor() {
    super();

    this.state = {
      label: '',
      imageSquare: null,
      imagePuzzle: null,
      thumbnailUrl: '',
      puzzleImgUrl: '',
      demo: 0,
      showROIEdit: null,
    };

    this.thumbnailFileInput = React.createRef();
    this.puzzleFileInput = React.createRef();
    this.cropper = React.createRef();
    this.messageModal = withReactContent(Swal);
  }

  componentWillMount() {
    const { dispatch, match, lessons } = this.props;
    const { lessonId } = match.params;

    if (lessons && lessons.length > 0 && lessonId) {
      // eslint-disable-next-line no-shadow
      const { lesson } = lessons.find(({ lessonItem }) => lessonItem.id.toString() === lessonId);
      this.setState({
        label: lesson.label,
        thumbnailUrl: lesson.thumbnail_url,
        puzzleImgUrl: lesson.puzzle_img_url,
        demo: lesson.demo,
      });
    } else if (lessonId) {
      dispatch(fetchLesson(lessonId));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { lessons, match } = this.props;
    const { lessonId } = match.params;

    if (lessons !== nextProps.lessons) {
      // eslint-disable-next-line no-shadow
      const { lesson } = nextProps.lessons.find(
        ({ lessonItem }) => lessonItem.id.toString() === lessonId,
      );
      this.setState({
        label: lesson.label,
        thumbnailUrl: lesson.thumbnail_url,
        puzzleImgUrl: lesson.puzzle_img_url,
        demo: lesson.demo,
      });
    }
  }

  cancelCropping = fileInputRef => {
    const ref = fileInputRef;
    this.setState({ showROIEdit: null });
    ref.current.value = null;
  };

  getCroppedImage = fileInputRef => {
    const { showROIEdit } = this.state;
    const croppedImageUrl = this.cropper.current
      .getCroppedCanvas({ fillColor: '#fff' })
      .toDataURL('image/jpeg');

    const croppedImage = new File(
      [common.dataURLToBlob(croppedImageUrl)],
      fileInputRef.current.value.split(PATH_SPLIT_REGEX).pop(),
      {
        type: 'image/jpeg',
      },
    );

    const imageProperties =
      showROIEdit === IMAGE_SETTINGS.SQUARE.fieldName
        ? { thumbnailUrl: croppedImageUrl, imageSquare: croppedImage }
        : { puzzleImgUrl: croppedImageUrl, imagePuzzle: croppedImage };

    this.setState({
      showROIEdit: null,
      ...imageProperties,
    });
  };

  handleChange = ({ target }) => this.setState({ [target.name]: target.value });

  handleCheckboxChange = ({ target }) => this.setState({ demo: target.checked ? 1 : 0 });

  handleFileChange = ({ target }) => {
    const { t } = this.props;
    if (
      !validation.validFileName(
        this.thumbnailFileInput.current.files[0],
        this.puzzleFileInput.current.files[0],
      )
    ) {
      // eslint-disable-next-line no-alert
      common.renderMessageModal(
        this.messageModal,
        t('myMultimedia.wizard.repeatingFileName'),
        t('ok'),
      );
      this.resetInputField(target);

      return;
    }

    if (!validation.validFileType(target.files[0], validation.VALID_IMAGE_TYPES)) {
      common.renderMessageModal(this.messageModal, t('error.wrongImageType'), t('ok'));
      this.resetInputField(target);

      return;
    }

    this.setState({ showROIEdit: target.name });
  };

  resetInputField = target => {
    if (target.name === IMAGE_SETTINGS.SQUARE.fieldName) {
      // eslint-disable-next-line no-param-reassign
      target.value = null;
      this.setState({
        thumbnailUrl: '',
        imageSquare: null,
      });
    } else if (target.name === IMAGE_SETTINGS.PUZZLE.fieldName) {
      // eslint-disable-next-line no-param-reassign
      target.value = null;
      this.setState({
        puzzleImgUrl: '',
        imagePuzzle: null,
      });
    }
  };

  handleSubmit = e => {
    const { dispatch, history, match } = this.props;
    const { monthId, lessonId } = match.params;
    const { label, imageSquare, imagePuzzle, demo } = this.state;
    const lesson = {
      label,
      imageSquare,
      imagePuzzle,
      demo,
    };

    e.preventDefault();
    if (lessonId) {
      dispatch(updateLesson(lesson, lessonId));
    } else {
      dispatch(
        createLesson({
          ...lesson,
          monthId,
        }),
      );
    }
    history.replace(`/admin/${monthId}`);
  };

  renderImage = url => (
    <img alt="Lesson thumbnail" className="admin-lesson__form__image" src={url} />
  );

  renderForm = () => {
    const { label, thumbnailUrl, demo, puzzleImgUrl } = this.state;
    const { lessonId } = this.props.match.params;
    const required = !lessonId;

    return (
      <div className="admin-lesson">
        <form name="home-screen-form" className="admin-lesson__form" onSubmit={this.handleSubmit}>
          <input
            className="admin-lesson__form__input"
            placeholder="Naziv"
            name="label"
            value={label}
            type="text"
            onChange={this.handleChange}
            required
          />

          {thumbnailUrl && this.renderImage(thumbnailUrl)}

          <input
            className="admin-lesson__form__input"
            ref={this.thumbnailFileInput}
            type="file"
            name={IMAGE_SETTINGS.SQUARE.fieldName}
            accept="image/*"
            required={required}
            onChange={this.handleFileChange}
          />

          <div className="admin-lesson__form__input--checkbox">
            <input
              className="admin-lesson__form__input"
              type="checkbox"
              id="demo"
              name="demo"
              checked={demo === 1}
              onChange={this.handleCheckboxChange}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
            <label>Demo lekcija</label>
          </div>

          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
          <label className="admin-lesson__form__label">Slika za nagradu (puzzle)</label>

          {puzzleImgUrl && this.renderImage(puzzleImgUrl)}

          <input
            className="admin-lesson__form__input"
            ref={this.puzzleFileInput}
            type="file"
            name={IMAGE_SETTINGS.PUZZLE.fieldName}
            accept="image/*"
            onChange={this.handleFileChange}
          />

          <button className="admin-lesson__form-button" type="submit">
            {lessonId ? 'Izmijeni' : 'Dodaj'} lekciju
          </button>
        </form>
      </div>
    );
  };

  renderCropper = (aspectW, aspectH, src, fileInput) => (
    <CropperModal
      cropperRef={this.cropper}
      aspectRatioW={aspectW}
      aspectRatioH={aspectH}
      src={src}
      onCropCancel={() => this.cancelCropping(fileInput)}
      onCropFinished={() => this.getCroppedImage(fileInput)}
    />
  );

  render() {
    const { showROIEdit } = this.state;
    const { SQUARE } = IMAGE_SETTINGS;
    const { PUZZLE } = IMAGE_SETTINGS;
    let fileInput;
    let aspectW;
    let aspectH;

    if (showROIEdit === SQUARE.fieldName) {
      fileInput = this.thumbnailFileInput;
      ({ aspectW } = SQUARE);
      ({ aspectH } = SQUARE);
    } else if (showROIEdit === PUZZLE.fieldName) {
      fileInput = this.puzzleFileInput;
      ({ aspectW } = PUZZLE);
      ({ aspectH } = PUZZLE);
    }

    return (
      <div>
        {showROIEdit &&
          this.renderCropper(
            aspectW,
            aspectH,
            window.URL.createObjectURL(fileInput.current.files[0]),
            fileInput,
          )}
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { months } = state.lessons;
  let lessons = null;
  if (months && months[state.months.selectedId]) {
    lessons = months[state.months.selectedId];
  }
  return {
    lessons: lessons && [...lessons],
  };
};

export default compose(withNamespaces('translation'), connect(mapStateToProps))(LessonFormScreen);
