export default {
  all: {
    primary: '#f01821',
    primaryLighter: '#FF4B4D',
  },
  1: {
    primary: '#2372B4',
    primaryLighter: '#26B4FF',
  },
  2: {
    primary: '#C701CA',
    primaryLighter: '#FC01FF',
  },
  3: {
    primary: '#B59747',
    primaryLighter: '#FFDD5E',
  },
  4: {
    primary: '#D6195B',
    primaryLighter: '#FF195F',
  },
  5: {
    primary: '#009E01',
    primaryLighter: '#00FF01',
  },
  game: {
    primary: '#CD6700',
    primaryLighter: '#FC8200',
  },
};
