import React from 'react';
import { useDragLayer } from 'react-dnd';

const DragLayerX = () => {
  const { isDragging, item, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function getItemStyles(curOffset) {
    if (!curOffset) {
      return {
        display: 'none',
      };
    }
    const { x, y } = curOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
      pointerEvents: 'none',
    };
  }

  const bgcolor = item && item.label ? `bgcolor--${item.colorIndex}` : 'bgcolor--0';

  if (!isDragging) {
    return null;
  }
  return (
    <div className="drag-layer" style={getItemStyles(currentOffset)}>
      <div className={`word-box ${bgcolor}`}>{item.label}</div>
    </div>
  );
};

export default DragLayerX;
