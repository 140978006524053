import merge from 'lodash.merge';

const config = {
  env: process.env.REACT_APP_ENV || 'development',
};

// eslint-disable-next-line import/no-dynamic-require
merge(config, require(`./${config.env}`).default);

export default config;
