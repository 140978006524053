import { network } from '../../utils';

import { ACTION_FAILURE, ACTION_BEGIN } from '../app';

export const ACCOUNT_ADMINISTRATION_SUCCESS = 'ACCOUNT_ADMINISTRATION_SUCCESS';

export const accountAdministration = data =>
  network.fetchActionWrapper(
    'users/accountAdministration',
    {
      begin: ACTION_BEGIN,
      success: ACCOUNT_ADMINISTRATION_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );
