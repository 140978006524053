import roles from '../../constants/roles';

const NAVBAR_ITEMS = [
  {
    name: 'home',
    destination: '/',
    allowedRoles: [roles.DEMO_ROLE, roles.REGULAR_ROLE, roles.ADMIN_ROLE],
  },
  {
    name: 'instructions',
    destination: '/instructions',
    allowedRoles: [roles.DEMO_ROLE, roles.REGULAR_ROLE, roles.ADMIN_ROLE],
  },
  {
    name: 'rewards',
    destination: '/rewards',
    allowedRoles: [roles.DEMO_ROLE, roles.REGULAR_ROLE, roles.ADMIN_ROLE],
  },
  {
    name: 'stats',
    destination: '/stats',
    allowedRoles: [roles.DEMO_ROLE, roles.REGULAR_ROLE, roles.ADMIN_ROLE],
  },
  {
    name: 'myMultimedia',
    destination: '/custom-multimedia',
    allowedRoles: [roles.DEMO_ROLE, roles.REGULAR_ROLE, roles.ADMIN_ROLE],
  },
  {
    name: 'organizationManagement',
    destination: '/organization-management',
    allowedRoles: [roles.ORGANIZATION_MANAGER_ROLE],
  },
  {
    name: 'accountGeneration',
    destination: '/account-generator',
    allowedRoles: [roles.ACCOUNT_GENERATOR_ROLE],
  },
  {
    name: 'settings',
    destination: '/settings',
    allowedRoles: [
      roles.DEMO_ROLE,
      roles.REGULAR_ROLE,
      roles.ADMIN_ROLE,
      roles.ORGANIZATION_MANAGER_ROLE,
    ],
  },
  {
    name: 'admin',
    destination: '/admin',
    allowedRoles: [roles.ADMIN_ROLE],
  },
];

export default NAVBAR_ITEMS;
