import { network } from '../../utils';
import { ACTION_FAILURE, ACTION_BEGIN } from '../app';

export const FETCH_ORGANIZATION_USERS_SUCCESS = 'FETCH_ORGANIZATION_USERS_SUCCESS';
export const DEACTIVATE_ORGANIZATION_USERS_SUCCESS = 'DEACTIVATE_ORGANIZATION_USERS_SUCCESS';
export const CREATE_ORGANIZATION_USERS_SUCCESS = 'CREATE_ORGANIZATION_USERS_SUCCESS';

export const fetchOrganizationUsers = () =>
  network.fetchActionWrapper(
    'organizationManager',
    {
      begin: ACTION_BEGIN,
      success: FETCH_ORGANIZATION_USERS_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {},
  );

export const deactivateOrganizationUser = id =>
  network.fetchActionWrapper(
    `organizationManager/deactivate/${id}`,
    {
      begin: ACTION_BEGIN,
      success: DEACTIVATE_ORGANIZATION_USERS_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'PUT',
    },
  );

export const createOrganizationUser = () =>
  network.fetchActionWrapper(
    'organizationManager',
    {
      begin: ACTION_BEGIN,
      success: CREATE_ORGANIZATION_USERS_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {
      method: 'POST',
    },
  );
