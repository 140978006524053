import React from 'react';
import ReactDOM from 'react-dom';

import Root from './containers/Root';
import registerServiceWorker from './registerServiceWorker';
import './config/i18n';
import configureStore from './config/store';

import './index.css';

async function init() {
  const app = await configureStore();
  ReactDOM.render(
    <Root persistor={app.persistor} store={app.store} />,
    document.getElementById('root'),
  );
  registerServiceWorker();
}

init();
