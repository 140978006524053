/* eslint no-case-declarations: 0 */
import { FETCH_ATTEMPTS_SUCCESS, INSERT_ATTEMPT_SUCCESS } from '../../actions/lessonsAttempts';

const initialState = {
  insertResponse: {},
  attemptsInfo: {},
};

export default function lessonsAttemptsReducer(state = initialState, action) {
  switch (action.type) {
    case INSERT_ATTEMPT_SUCCESS:
      return {
        ...state,
        insertResponse: action.payload.data,
      };
    case FETCH_ATTEMPTS_SUCCESS:
      return {
        ...state,
        attemptsInfo: action.payload.data,
      };

    default:
      return state;
  }
}
