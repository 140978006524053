import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';

import DndBox from './DndBox';
import DndElement from './DndElement';
import DragLayerX from './DragLayerX';
import ItemTypes from './ItemTypes';

import './index.css';

import { common } from '../../utils';

// there are 8 css classes for background color and 8 css classes for border color
// colorIndex is used to form css dndElement background color and border class name
const colorIndexes = [1, 2, 3, 4, 5, 6, 7, 8];

function generateTerms(terms) {
  const tempTerms = {};
  const shuffledColorIndexes = common.shuffle(colorIndexes);

  terms.forEach(({ label }, index) => {
    tempTerms[index] = {
      label,
      type: ItemTypes.TERM,
      colorIndex: shuffledColorIndexes[index],
      canDrag: true,
    };
    tempTerms[index + terms.length] = {
      label: '',
      type: ItemTypes.TERM,
      colorIndex: '00',
      canDrag: false,
    };
  });

  return tempTerms;
}

function generateBoxes(terms) {
  const tempDndBoxes = [];
  // create array of int up to terms.length and shuffle it
  const shuffledIndexes = common.shuffle([...Array(terms.length).keys()]);

  terms.forEach(({ label, multimediaObjectUrl: picture }, index) => {
    tempDndBoxes.unshift({
      id: index + terms.length,
      answer: label,
      picture,
      termId: index + terms.length,
      accepts: [ItemTypes.TERM],
    });
    tempDndBoxes.push({
      id: index,
      answer: '',
      picture: '',
      termId: shuffledIndexes[index],
      accepts: [ItemTypes.TERM],
    });
  });

  return tempDndBoxes;
}

const DndGame = ({ activeTerms, getActiveTerms, incrementCorrect, incrementQuestions }) => {
  const [dndBoxes, setDndBoxes] = useState(generateBoxes(activeTerms));
  const [dndTerms, setDndTerms] = useState(generateTerms(activeTerms));

  useEffect(() => {
    const tempTerms = generateTerms(activeTerms);
    setDndTerms(tempTerms);
  }, [activeTerms, setDndTerms]);

  useEffect(() => {
    const tempDndBoxes = generateBoxes(activeTerms);
    setDndBoxes(tempDndBoxes);
  }, [activeTerms, setDndBoxes]);

  const checkCorrectness = useCallback(
    index => {
      const { termId, answer } = dndBoxes[index];
      return dndTerms[termId].label === answer;
    },
    [dndBoxes, dndTerms],
  );

  const checkGameCorrectness = useCallback(() => {
    if (dndBoxes.length !== Object.keys(dndTerms).length) return false;
    return dndBoxes.map((_, index) => checkCorrectness(index)).every(Boolean); // check if all array elements are true
  }, [dndBoxes, dndTerms, checkCorrectness]);

  const handleDrop = useCallback(
    (index, item) => {
      const { id: termId, dndBoxIndex: prevDndBox, label } = item;

      // track game statistics
      incrementQuestions();
      if (label === dndBoxes[index].answer) {
        incrementCorrect();
      }

      const updatedDndBoxes = [...dndBoxes];
      updatedDndBoxes[index] = { ...dndBoxes[index], termId };
      updatedDndBoxes[prevDndBox] = { ...dndBoxes[prevDndBox], termId: dndBoxes[index].termId };
      setDndBoxes(updatedDndBoxes);
    },
    [dndBoxes, incrementCorrect, incrementQuestions],
  );

  // check if all terms are correctly placed into appropriate containers
  useEffect(() => {
    if (checkGameCorrectness()) {
      setTimeout(getActiveTerms, 1000);
    }
  }, [checkGameCorrectness, getActiveTerms]);

  return dndBoxes.length !== Object.keys(dndTerms).length ? null : (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <div className="picture-boxes scrollbar">
        {dndBoxes.map(
          ({ id, accepts, termId, picture }, index) =>
            picture && (
              <DndBox
                key={id}
                accept={accepts}
                termId={termId}
                onDrop={item => {
                  handleDrop(index, item);
                }}
                picture={picture}
                isCorrect={checkCorrectness(index)}
                pictureClass={dndBoxes.length <= 4 ? 'picture picture__two-terms' : 'picture'}
              >
                <DndElement
                  id={termId}
                  label={dndTerms[termId].label}
                  type={dndTerms[termId].type}
                  isCorrect={checkCorrectness(index)}
                  key={dndTerms[termId].label}
                  dndBoxIndex={index}
                  hasPicture={!!picture}
                  colorIndex={dndTerms[termId].colorIndex}
                  canDrag={dndTerms[termId].canDrag}
                />
              </DndBox>
            ),
        )}
      </div>
      <div className="word-boxes scrollbar">
        {dndBoxes.map(
          ({ id, accepts, termId, picture }, index) =>
            !picture && (
              <DndBox
                key={id}
                accept={accepts}
                termId={termId}
                onDrop={item => {
                  handleDrop(index, item);
                }}
                picture={picture}
                isCorrect={checkCorrectness(index)}
              >
                <DndElement
                  key={dndTerms[termId].label}
                  id={termId}
                  type={dndTerms[termId].type}
                  label={dndTerms[termId].label}
                  isCorrect={checkCorrectness(index)}
                  dndBoxIndex={index}
                  hasPicture={!!picture}
                  colorIndex={dndTerms[termId].colorIndex}
                  canDrag={dndTerms[termId].canDrag}
                />
              </DndBox>
            ),
        )}
      </div>
      <DragLayerX />
    </DndProvider>
  );
};

export default DndGame;
