import { network } from '../../utils';
import { ACTION_BEGIN, ACTION_FAILURE } from '../app';

export const FETCH_SCORES_SUCCESS = 'FETCH_SCORES_SUCCESS';
export const FETCH_MONTH_LIMIT_SUCCESS = 'FETCH_MONTH_LIMIT_SUCCESS';

export const fetchScores = () =>
  network.fetchActionWrapper(
    'stats',
    {
      begin: ACTION_BEGIN,
      success: FETCH_SCORES_SUCCESS,
      failure: ACTION_FAILURE,
    },
    {},
  );

export const fetchMonthLimit = () =>
  network.fetchActionWrapper('stats/monthLimit', {
    begin: ACTION_BEGIN,
    success: FETCH_MONTH_LIMIT_SUCCESS,
    failure: ACTION_FAILURE,
  });
