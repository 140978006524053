import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { fetchMonths, selectMonth, deleteMonth, updateMonthsOrder } from '../../actions/months';
import { showNavigationBar } from '../../actions/app';
import AdminList from '../../components/AdminList';

class AdminMonthsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = { months: props.months };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMonths());
    dispatch(showNavigationBar('admin'));
  }

  componentWillReceiveProps(nextProps) {
    const { months } = this.props;

    if (months !== nextProps.months) {
      this.setState({ months: nextProps.months });
    }
  }

  selectMonthId = id => {
    const { dispatch } = this.props;
    dispatch(selectMonth(id));
  };

  deleteMonth = id => {
    const { dispatch } = this.props;
    dispatch(deleteMonth(id));
  };

  editMonthsOrder = months => {
    const { dispatch } = this.props;
    dispatch(updateMonthsOrder(months));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { months } = this.state;

    this.setState({
      months: arrayMove(months, oldIndex, newIndex),
    });
  };

  render() {
    const { match, t } = this.props;
    const { months } = this.state;

    return (
      <AdminList
        title="Mjeseci"
        itemColor="green"
        onClick={this.selectMonthId}
        onDelete={this.deleteMonth}
        to={match.url}
        items={months}
        addLink="/month"
        onSortEnd={this.onSortEnd}
        useDragHandle
        updateItemsOrder={this.editMonthsOrder}
        t={t}
      />
    );
  }
}

AdminMonthsScreen.propTypes = {
  months: PropTypes.array,
};

const mapStateToProps = state => ({
  months: state.months.items,
  loading: state.months.loading,
  error: state.months.error,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(AdminMonthsScreen);
