import { FETCH_REWARDS_SUCCESS } from '../../actions/rewards';

const initialState = {
  months: {},
};

export function getMonthsWithRewardImgs(months) {
  let monthsWithLessons = null;
  let monthsWithAtLeastOnePuzzleImg = null;

  if (months && months.length) {
    monthsWithLessons = months.filter(month => month.lessons && month.lessons.length);
  }

  if (monthsWithLessons && monthsWithLessons.length) {
    monthsWithAtLeastOnePuzzleImg = monthsWithLessons.filter(month =>
      month.lessons.find(lesson => lesson.puzzle_img_url),
    );
  }

  return monthsWithAtLeastOnePuzzleImg;
}

export default function rewardsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REWARDS_SUCCESS:
      return {
        ...state,
        months: getMonthsWithRewardImgs(action.payload.data),
      };

    default:
      return state;
  }
}
