import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import lessonReducer from './lessons/lessonReducer';
import monthReducer from './months/monthReducer';
import appReducer from './app/appReducer';
import sectionReducer from './sections/sectionReducer';
import termReducer from './terms/termReducer';
import languageReducer from './languages/languageReducer';
import customMultimediaReducer from './customMultimedia/customMultimediaReducer';
import rewardsReducer from './rewards/rewardsReducer';
import statsReducer from './stats/statsReducer';
import accountGeneratorReducer from './accountGenerators/accountGeneratorReducer';
import organizationManagementReducer from './organizationManagement/organizationManagementReducer';
import lessonsAttemptsReducer from './lessonsAttempts/lessonsAttemptsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  lessons: lessonReducer,
  months: monthReducer,
  app: appReducer,
  sections: sectionReducer,
  terms: termReducer,
  languages: languageReducer,
  customMultimedia: customMultimediaReducer,
  rewards: rewardsReducer,
  stats: statsReducer,
  accountGenerator: accountGeneratorReducer,
  organizationManagement: organizationManagementReducer,
  lessonsAttempts: lessonsAttemptsReducer,
});

export default rootReducer;
