import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { animated, Spring } from 'react-spring';
import { REWARD_DIM } from '../../../constants/rewards';
import checkmark from '../../../images/checkmark.svg';

import './index.css';

const CARD_LABEL_HEIGHT = 39;

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.cardElement = React.createRef();

    const { children } = this.props;

    this.state = {
      hovered: false,
      thisHeight: 0,
      thisWidth: 0,
      hasChildren: !!children,
    };
  }

  componentDidMount() {
    const { hasChildren } = this.state;

    if (!hasChildren) {
      return;
    }

    this.restrictCardDimensions();

    let resizeTaskId = null;

    window.addEventListener('resize', () => {
      if (resizeTaskId !== null) {
        clearTimeout(resizeTaskId);
      }

      resizeTaskId = setTimeout(() => {
        resizeTaskId = null;
        this.restrictCardDimensions();
      }, 50);
    });
  }

  closestNumberDivisibleByM = (num1, num2) => {
    const n = Math.floor(num1);
    const m = Math.floor(num2);

    // find the quotient
    const q = Math.floor(n / m);

    // 1st possible closest number
    const n1 = Math.floor(m * q);

    // 2nd possible closest number
    const n2 = Math.floor(n * m > 0 ? m * (q + 1) : m * (q - 1));

    // if true, then n1 is the required closest number
    if (Math.abs(n - n1) < Math.abs(n - n2)) {
      return n1;
    }
    // else n2 is the required closest number
    return n2;
  };

  restrictCardDimensions = () => {
    if (!this.cardElement.current) {
      return;
    }

    const cardScale = 0.2;
    const newWidth = this.closestNumberDivisibleByM(
      Math.round(document.documentElement.clientWidth * cardScale),
      REWARD_DIM.columns,
    );
    const newHeight = this.closestNumberDivisibleByM(Math.round(newWidth / 1.77), REWARD_DIM.rows);

    this.setState({
      thisWidth: newWidth,
      thisHeight: newHeight + CARD_LABEL_HEIGHT,
    });
  };

  handleClick = e => {
    const { disabled, onClick } = this.props;

    if (!disabled && onClick) {
      onClick(e);
    } else {
      e.preventDefault();
    }
  };

  setHover = () => this.setState({ hovered: true });

  cancelHover = () => this.setState({ hovered: false });

  render() {
    const {
      children,
      disabled,
      disabledWithCursor,
      label,
      label2num,
      label2days,
      img,
      iconSrc,
      highlighted,
      preloaded,
      t,
      bgColor,
      bgColorLighter,
      imgBgColor,
      rect,
      isPlayed,
    } = this.props;

    const { thisWidth, thisHeight, hasChildren } = this.state;

    if (preloaded === 'begin') {
      return (
        <div className={`card ${rect && 'card-rect'}`}>
          <div className="card--loading" />
        </div>
      );
    }

    if (preloaded === 'failed') {
      return <div className={`card ${rect && 'card-rect'}`}>{t('lesson.loadFailed')}</div>;
    }

    const sectionCardStyle = {};

    if (!label2num) {
      sectionCardStyle.borderStyle = 'none';
    }

    if (bgColor) {
      sectionCardStyle.background = `linear-gradient(to top, ${bgColor}, ${bgColorLighter})`;
    }

    const customWidthHeight = hasChildren ? { width: thisWidth, height: thisHeight } : {};

    return (
      <Spring
        native
        to={{
          transform: `scale(${this.state.hovered ? 1.1 : 1})`,
        }}
      >
        {props => (
          <animated.div
            className={`card ${rect && 'card-rect'} preload-${preloaded} ${
              highlighted ? 'card--highlighted' : ''
            } ${disabled ? 'card--disabled' : ''}`}
            ref={this.cardElement}
            onClick={this.handleClick}
            onMouseOver={this.setHover}
            onFocus={this.setHover}
            onMouseOut={this.cancelHover}
            onBlur={this.cancelHover}
            style={
              imgBgColor
                ? { background: imgBgColor, ...customWidthHeight, ...props }
                : { ...customWidthHeight, ...props }
            }
          >
            {isPlayed && <img className="card-rect--icon checkmark" alt="icon" src={checkmark} />}
            {iconSrc && <img className="card-rect--icon" alt="icon" src={iconSrc} />}

            {children ? (
              <div
                className="card__img--reward"
                style={{ width: thisWidth, height: thisHeight - CARD_LABEL_HEIGHT }}
              >
                {children}
              </div>
            ) : (
              <img
                alt=""
                className={`card__img ${disabledWithCursor ? 'card--disabled-with-cursor' : ''}`}
                src={img}
              />
            )}

            {label2num ? (
              <span className="card__label2">
                {label2num}
                <br />
                <span className="card__label2__days">{label2days}</span>
              </span>
            ) : (
              ''
            )}
            <div style={sectionCardStyle} className="card__label">
              {label}
            </div>
          </animated.div>
        )}
      </Spring>
    );
  }
}

Card.propTypes = {
  disabled: PropTypes.bool,
  disabledWithCursor: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  label2num: PropTypes.string,
  label2days: PropTypes.string,
  highlighted: PropTypes.bool,
  img: PropTypes.string,
  iconSrc: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  bgColorLighter: PropTypes.string.isRequired,
  imgBgColor: PropTypes.string,
  rect: PropTypes.bool,
  isPlayed: PropTypes.bool,
};

Card.defaultProps = {
  disabled: false,
  disabledWithCursor: false,
  label2num: '',
  label2days: '',
  highlighted: false,
  img: '',
  iconSrc: '',
  imgBgColor: '#FFFFFF',
  rect: false,
};

export default withNamespaces('translation')(Card);
