import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import {
  createSection,
  fetchSection,
  updateSection,
  fetchSectionTypes,
} from '../../actions/sections';
import './index.css';

class SectionFormScreen extends React.Component {
  constructor() {
    super();
    this.state = { sectionTypeId: '' };
  }

  componentWillMount() {
    const { dispatch, match, sections } = this.props;
    const { sectionId } = match.params;

    dispatch(fetchSectionTypes());

    if (sections.length > 0 && sectionId) {
      const section = sections.find(section2 => section2.id.toString() === sectionId);
      this.setState({ sectionTypeId: section.section_type_id });
    } else if (sectionId) {
      dispatch(fetchSection(sectionId));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { sections, match, dispatch } = this.props;
    const { sectionId } = match.params;

    if (sections !== nextProps.sections && sectionId) {
      if (nextProps.sections.length > 0) {
        const section = nextProps.sections.find(section2 => section2.id.toString() === sectionId);
        this.setState({
          sectionTypeId: section && section.section_type_id,
        });
      } else {
        dispatch(fetchSection(sectionId));
      }
    }
  }

  handleChange = ({ target }) => this.setState({ sectionTypeId: target.value });

  handleSubmit = e => {
    const { dispatch, history, match, sectionTypes } = this.props;
    const { sectionId, lessonId, monthId } = match.params;
    const { sectionTypeId } = this.state;

    e.preventDefault();
    if (sectionId) {
      dispatch(updateSection(this.state, sectionId));
    } else {
      const sectionType = sectionTypes.find(section => section.id.toString() === sectionTypeId);

      dispatch(
        createSection({
          lessonId,
          label: sectionType.label,
          sectionTypeId,
        }),
      );
    }
    history.replace(`/admin/${monthId}/${lessonId}`);
  };

  renderSectionType = ({ id, label }) => (
    <option key={id} value={id}>
      {this.props.t(`lesson.sectionType.${label}`)}
    </option>
  );

  render() {
    const { sectionTypes, match } = this.props;
    const { sectionTypeId } = this.state;

    return (
      <div className="admin--section">
        <form name="home-screen-form" className="admin--section__form" onSubmit={this.handleSubmit}>
          <select
            name="section_type"
            onChange={this.handleChange}
            value={sectionTypeId || 'default'}
            required
          >
            <option value="default" disabled>
              Izaberi tip
            </option>
            {sectionTypes.map(this.renderSectionType)}
          </select>

          <button className="admin--section__form-button" type="submit">
            {match.params.sectionId ? 'Izmijeni' : 'Dodaj'} sekciju
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sections: state.sections.items,
  sectionTypes: state.sections.item_types,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(SectionFormScreen);
