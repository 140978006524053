import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

import { fetchLanguages } from '../../actions/languages';
import {
  clearGeneratorStatus,
  createMultipleAccounts,
  createSingleAccount,
} from '../../actions/accountGenerator';
import common from '../../utils/common';

import forms from './forms.json';
import './index.css';
import { showNavigationBar } from '../../actions/app';

const ACTION_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export class AccountGenerationScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      singleAccForm: true,
      subscriptionUntil: moment()
        .add(1, 'y')
        .format('YYYY-MM-DD'),
    };

    this.actionStatusAlert = withReactContent(Swal);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchLanguages());
    dispatch(showNavigationBar('accountGeneration'));
  }

  fireAlertWindow = type => {
    const successMessage = `${
      this.state.singleAccForm ? 'Account was' : 'Accounts were'
    } successfully created!`;

    this.actionStatusAlert
      .fire({
        type,
        titleText: type === ACTION_STATUS.SUCCESS && successMessage,
        timer: 5000,
        showConfirmButton: false,
      })
      .then(() => this.props.dispatch(clearGeneratorStatus()));
  };

  handleChange = ({ target }) => this.setState({ [target.name]: target.value });

  handleSubmit = e => {
    const { dispatch } = this.props;
    const {
      singleAccForm,
      username,
      email,
      organizationName,
      lang,
      telephoneNumber,
      subscriptionUntil,
      accountsLimit,
      usernamePrefix,
    } = this.state;

    e.preventDefault();

    const formattedSubscriptionUntil = common.getSqlDateFormat(subscriptionUntil);

    if (singleAccForm) {
      dispatch(
        createSingleAccount({
          username,
          email,
          lang,
          subscription_until: formattedSubscriptionUntil,
        }),
      );
    } else {
      dispatch(
        createMultipleAccounts({
          organization_name: organizationName,
          username_prefix: usernamePrefix,
          email,
          lang,
          subscription_until: formattedSubscriptionUntil,
          account_limit: accountsLimit,
          telephone_number: telephoneNumber,
        }),
      );
    }
  };

  switchFormType = () => this.setState(prevState => ({ singleAccForm: !prevState.singleAccForm }));

  renderLanguages = ({ code, label }) => {
    // FIXME render only croatian language
    if (code === 'hr')
      return (
        <option key={code} value={code}>
          {label} ({code})
        </option>
      );
    return null;
  };

  renderInput = field => (
    <div>
      {field.label && <label className="account-generator__form__label">{field.label}</label>}
      <input
        className="account-generator__form__input"
        placeholder={field.placeholder}
        name={field.name}
        type={field.type}
        onChange={this.handleChange}
        value={this.state[field.name] || ''}
        required={field.required}
        min={field.min}
      />
    </div>
  );

  renderLanguageSelect = () => (
    <select
      name="lang"
      onChange={this.handleChange}
      value={this.state.lang || 'default'}
      className="account-generator__form__select"
      required
    >
      <option value="">Choose language</option>
      {this.props.languages.map(this.renderLanguages)}
    </select>
  );

  renderSingleAccountForm = () => {
    const { single } = forms.views;

    return (
      <form
        name="account-generator-form"
        className="account-generator__form"
        onSubmit={this.handleSubmit}
      >
        {single.map(this.renderInput)}
        {this.renderLanguageSelect()}

        <button className="account-generator__form__button" type="submit">
          Generate
        </button>
      </form>
    );
  };

  renderMultiAccountForm = () => {
    const { multiple } = forms.views;

    return (
      <form
        name="account-generator-form"
        className="account-generator__form"
        onSubmit={this.handleSubmit}
      >
        {multiple.map(this.renderInput)}
        {this.renderLanguageSelect()}

        <button className="account-generator__form__button" type="submit">
          Generate
        </button>
      </form>
    );
  };

  render() {
    const { singleAccForm } = this.state;
    const { generatorStatus } = this.props;

    if (generatorStatus === ACTION_STATUS.SUCCESS) {
      this.fireAlertWindow(generatorStatus);
    }

    return (
      <div className="account-generator">
        <div className="account-generator__header">
          <button
            type="button"
            className="account-generator__header__button"
            onClick={this.switchFormType}
          >
            {singleAccForm ? 'Multiple accounts' : 'Single account'}
          </button>
          <div className="account-generator__header__title">
            {singleAccForm ? 'Single' : 'Multi'} account form
          </div>
        </div>
        {singleAccForm ? this.renderSingleAccountForm() : this.renderMultiAccountForm()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languages.items,
  generatorStatus: state.accountGenerator.status,
});

export default connect(mapStateToProps)(AccountGenerationScreen);
