import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';

export default function i18nInit(lng) {
  i18n
    .use(Backend)
    .use(reactI18nextModule)
    .init({
      lng,
      backend: {
        loadPath: 'locales/{{lng}}/{{ns}}.json',
      },
      fallbackLng: 'de',
      debug: (process.env.REACT_APP_ENV || 'development') === 'development',
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    });
}
