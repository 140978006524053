import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ProgressBar from '../ProgressBar';
import auth from '../../utils/auth';
import roles from '../../constants/roles';

import './index.css';

function InlineProgressBar({ progressBarInfo, lessonsAttempts }) {
  if (
    !lessonsAttempts.isDailyLimitEnabled ||
    !auth.isRole([roles.REGULAR_ROLE]) ||
    !progressBarInfo
  ) {
    return null;
  }

  return (
    <div className="progress__container">
      <div className="progress__table">
        <div className="progress__table-cell">
          <p>{progressBarInfo.text}</p>
        </div>
        <div className="progress__table-cell progress__table-cell--bar">
          <ProgressBar
            info={progressBarInfo}
            containerClassName="progress"
            labelClassName="progress__table-cell--label"
          />
        </div>
      </div>
    </div>
  );
}

InlineProgressBar.propTypes = {
  progressBarInfo: PropTypes.object,
  lessonsAttempts: PropTypes.object,
};

const mapStateToProps = state => ({
  lessonsAttempts: state.lessonsAttempts.attemptsInfo,
});

export default connect(mapStateToProps)(InlineProgressBar);
