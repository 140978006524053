import { network } from '../../utils';

export const SELECT_SECTION = 'SELECT_SECTION';
export const FETCH_SECTIONS_BEGIN = 'FETCH_SECTIONS_BEGIN';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_FAILURE = 'FETCH_SECTIONS_FAILURE';
export const CREATE_SECTION_BEGIN = 'CREATE_SECTION_BEGIN';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_FAILURE = 'CREATE_SECTION_FAILURE';
export const FETCH_SECTION_TYPES_BEGIN = 'FETCH_SECTION_TYPES_BEGIN';
export const FETCH_SECTION_TYPES_SUCCESS = 'FETCH_SECTION_TYPES_SUCCESS';
export const FETCH_SECTION_TYPES_FAILURE = 'FETCH_SECTION_TYPES_FAILURE';
export const DELETE_SECTION_BEGIN = 'DELETE_SECTION_BEGIN';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';
export const REORDER_SECTIONS_BEGIN = 'REORDER_SECTIONS_BEGIN';
export const REORDER_SECTIONS_SUCCESS = 'REORDER_SECTIONS_SUCCESS';
export const REORDER_SECTIONS_FAILURE = 'REORDER_SECTIONS_FAILURE';
export const FETCH_SECTION_BEGIN = 'FETCH_SECTION_BEGIN';
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS';
export const FETCH_SECTION_FAILURE = 'FETCH_SECTION_FAILURE';
export const EDIT_SECTION_BEGIN = 'EDIT_SECTION_BEGIN';
export const EDIT_SECTION_SUCCESS = 'EDIT_SECTION_SUCCESS';
export const EDIT_SECTION_FAILURE = 'EDIT_SECTION_FAILURE';
export const POST_USER_PLAYED_SECTION_BEGIN = 'POST_USER_PLAYED_SECTION_BEGIN';
export const POST_USER_PLAYED_SECTION_SUCCESS = 'POST_USER_PLAYED_SECTION_SUCCESS';
export const POST_USER_PLAYED_SECTION_FAILURE = 'POST_USER_PLAYED_SECTION_FAILURE';

export const fetchSection = id =>
  network.fetchActionWrapper(`sections/${id}`, {
    begin: FETCH_SECTION_BEGIN,
    success: FETCH_SECTION_SUCCESS,
    failure: FETCH_SECTION_FAILURE,
  });

export const fetchSections = lessonId =>
  network.fetchActionWrapper(`lessons/${lessonId}/sections`, {
    begin: FETCH_SECTIONS_BEGIN,
    success: FETCH_SECTIONS_SUCCESS,
    failure: FETCH_SECTIONS_FAILURE,
  });

export const fetchSectionTypes = () =>
  network.fetchActionWrapper('sectionTypes', {
    begin: FETCH_SECTION_TYPES_BEGIN,
    success: FETCH_SECTION_TYPES_SUCCESS,
    failure: FETCH_SECTION_TYPES_FAILURE,
  });

export const selectSection = id => ({
  type: SELECT_SECTION,
  id,
});

export const createSection = data =>
  network.createActionWrapper(
    'sections',
    {
      begin: CREATE_SECTION_BEGIN,
      success: CREATE_SECTION_SUCCESS,
      failure: CREATE_SECTION_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const deleteSection = sectionId =>
  network.fetchActionWrapper(
    `sections/${sectionId}`,
    {
      begin: DELETE_SECTION_BEGIN,
      success: DELETE_SECTION_SUCCESS,
      failure: DELETE_SECTION_FAILURE,
    },
    {
      method: 'DELETE',
    },
  );

export const updateSectionsOrder = (data, lessonId) =>
  network.fetchActionWrapper(
    `lessonHasSections/${lessonId}`,
    {
      begin: REORDER_SECTIONS_BEGIN,
      success: REORDER_SECTIONS_SUCCESS,
      failure: REORDER_SECTIONS_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const updateSection = (data, id) =>
  network.fetchActionWrapper(
    `sections/${id}`,
    {
      begin: EDIT_SECTION_BEGIN,
      success: EDIT_SECTION_SUCCESS,
      failure: EDIT_SECTION_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const createUserPlayedSectionEntry = data =>
  network.createActionWrapper(
    'userPlayedSection',
    {
      begin: POST_USER_PLAYED_SECTION_BEGIN,
      success: POST_USER_PLAYED_SECTION_SUCCESS,
      failure: POST_USER_PLAYED_SECTION_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );
