import { network } from '../../utils';
import { ACTION_BEGIN } from '../app';

export const SELECT_TERM = 'SELECT_TERM';
export const FETCH_TERMS_BEGIN = 'FETCH_TERMS_BEGIN';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const FETCH_TERMS_FAILURE = 'FETCH_TERMS_FAILURE';
export const CREATE_TERM_BEGIN = 'CREATE_TERM_BEGIN';
export const CREATE_TERM_SUCCESS = 'CREATE_TERM_SUCCESS';
export const CREATE_TERM_FAILURE = 'CREATE_TERM_FAILURE';
export const DELETE_TERM_BEGIN = 'DELETE_TERM_BEGIN';
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';
export const DELETE_TERM_FAILURE = 'DELETE_TERM_FAILURE';
export const REORDER_TERMS_BEGIN = 'REORDER_TERMS_BEGIN';
export const REORDER_TERMS_SUCCESS = 'REORDER_TERMS_SUCCESS';
export const REORDER_TERMS_FAILURE = 'REORDER_TERMS_FAILURE';
export const EDIT_TERM_SUCCESS = 'EDIT_TERM_SUCCESS';
export const EDIT_TERM_FAILURE = 'EDIT_TERM_FAILURE';
export const FETCH_TERM_BEGIN = 'FETCH_TERM_BEGIN';
export const FETCH_TERM_SUCCESS = 'FETCH_TERM_SUCCESS';
export const FETCH_TERM_FAILURE = 'FETCH_TERM_FAILURE';

export const fetchTerm = id =>
  network.fetchActionWrapper(`terms/${id}`, {
    begin: FETCH_TERM_BEGIN,
    success: FETCH_TERM_SUCCESS,
    failure: FETCH_TERM_FAILURE,
  });

export const fetchTerms = sectionId =>
  network.fetchActionWrapper(`sections/${sectionId}/terms`, {
    begin: FETCH_TERMS_BEGIN,
    success: FETCH_TERMS_SUCCESS,
    failure: FETCH_TERMS_FAILURE,
  });

export const selectTerm = id => ({
  type: SELECT_TERM,
  id,
});

export const createTerm = data => {
  const formData = new FormData();
  Object.keys(data).map(k => formData.append(k, data[k]));

  return network.createActionWrapper(
    'terms',
    {
      begin: CREATE_TERM_BEGIN,
      success: CREATE_TERM_SUCCESS,
      failure: CREATE_TERM_FAILURE,
    },
    {
      method: 'POST',
      body: formData,
    },
  );
};

export const deleteTerm = termId =>
  network.fetchActionWrapper(
    `terms/${termId}`,
    {
      begin: DELETE_TERM_BEGIN,
      success: DELETE_TERM_SUCCESS,
      failure: DELETE_TERM_FAILURE,
    },
    {
      method: 'DELETE',
    },
  );

export const updateTerm = (data, id) => {
  const formData = new FormData();
  Object.keys(data).map(k => formData.append(k, data[k]));

  return network.fetchActionWrapper(
    `terms/${id}`,
    {
      begin: ACTION_BEGIN,
      success: EDIT_TERM_SUCCESS,
      failure: EDIT_TERM_FAILURE,
    },
    {
      method: 'PUT',
      body: formData,
    },
  );
};

export const updateTermsOrder = (data, sectionId) =>
  network.fetchActionWrapper(
    `sectionHasTerms/${sectionId}`,
    {
      begin: REORDER_TERMS_BEGIN,
      success: REORDER_TERMS_SUCCESS,
      failure: REORDER_TERMS_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );
