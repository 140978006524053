import React from 'react';
import PropTypes from 'prop-types';
import { animated, Keyframes, Spring } from 'react-spring';

import './index.css';

import brushImg from '../../images/brush.png';
import questionMarkImg from '../../images/question_mark.png';
import { BLUR_AMOUNT } from '../../constants/rewards';

const IMAGE_SPLIT = {
  rows: 2,
  columns: 3,
};

const TRANSITION_DURATION = 4000;

const Container = Keyframes.Spring(async next => {
  let index = 0;
  // eslint-disable-next-line no-constant-condition,no-await-in-loop
  while (true) await next({ to: { radians: (index += 2) * Math.PI } });
});

class Reward extends React.Component {
  constructor(props) {
    super(props);

    this.rewardWrapperRef = React.createRef();
    this.state = {
      brushAnimated: true,
    };
  }

  getLevelProgressDifferenceIndex = () => {
    const { lessonCurrentGameProgress, lessonNextGameProgress } = this.props;

    if (
      !lessonCurrentGameProgress ||
      !lessonNextGameProgress ||
      lessonCurrentGameProgress.length !== lessonNextGameProgress.length
    ) {
      return -1;
    }

    const currentGameProgress = lessonCurrentGameProgress.split('');
    const nextGameProgress = lessonNextGameProgress.split('');

    for (let i = 0; i < currentGameProgress.length; i += 1) {
      if (currentGameProgress[i] !== nextGameProgress[i]) {
        return i;
      }
    }

    return -1;
  };

  renderBrushAnimation = () => {
    const { brushAnimated } = this.state;

    if (!brushAnimated) {
      return null;
    }

    const Content = ({ radians }) => (
      <animated.div
        className="reward__brush_div"
        style={{
          willChange: 'transform',
          transform: radians.interpolate(r => {
            const percent = ((r % 2.0) * Math.PI) / (2.0 * Math.PI);
            const degrees = percent <= 0.5 ? percent * 90.0 : 90.0 - percent * 90.0;
            return `rotate(${degrees}deg)`;
          }),
        }}
      >
        <img className="reward__brush" src={brushImg} alt="" />
      </animated.div>
    );

    return (
      <Container native from={{ radians: 0 }} config={{ duration: TRANSITION_DURATION }}>
        {Content}
      </Container>
    );
  };

  renderWithExistingFilter = () => {
    const { lessonCurrentGameProgress, lessonPuzzleImgUrl } = this.props;
    const { brushAnimated } = this.state;
    const currentGameProgress = lessonCurrentGameProgress.split('');
    const changedIndex = this.getLevelProgressDifferenceIndex();
    return currentGameProgress.map((currentLevelProgress, i) => {
      const row = Math.floor(i / IMAGE_SPLIT.columns);
      const column = i % IMAGE_SPLIT.columns;
      const isBlur = !(currentLevelProgress === '1');

      const position = {
        top: `calc(${row}*100% / 2`,
        left: `calc(${column}*100% / 3`,
      };

      const filter = {
        backgroundImage: `url("${lessonPuzzleImgUrl}")`,
        backgroundPositionX: `calc(${column * 50}%)`,
        backgroundPositionY: `calc(${row * 100}%)`,
      };

      if (i !== changedIndex) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className="reward__filter_container" key={i} style={position}>
            {!isBlur && <div className="reward__filter" style={filter} />}
          </div>
        );
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="reward__filter_container" key={i} style={position}>
          <Spring
            config={{ duration: TRANSITION_DURATION }}
            from={{ ...position, opacity: 0 }}
            to={{ ...position, opacity: 1 }}
          >
            {props => {
              if (props.opacity >= 0.9 && brushAnimated) {
                this.setState({ brushAnimated: false });
              }
              return <div className="reward__filter" style={{ ...props, ...filter }} />;
            }}
          </Spring>
          {this.renderBrushAnimation()}
        </div>
      );
    });
  };

  render() {
    const { lessonPuzzleImgUrl } = this.props;

    return (
      <div ref={this.rewardWrapperRef} className="reward__wrapper">
        <img
          className="reward__image"
          alt=""
          style={{
            filter: `blur(${lessonPuzzleImgUrl ? BLUR_AMOUNT : 0}px)`,
          }}
          src={lessonPuzzleImgUrl || questionMarkImg}
        />
        {this.renderWithExistingFilter()}
      </div>
    );
  }
}

Reward.propTypes = {
  lessonCurrentGameProgress: PropTypes.string.isRequired,
  lessonNextGameProgress: PropTypes.string,
  lessonPuzzleImgUrl: PropTypes.string,
};

export default Reward;
