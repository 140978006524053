import {
  FETCH_LESSON_BEGIN,
  FETCH_LESSON_SUCCESS,
  FETCH_LESSON_FAILURE,
  FETCH_LESSONS_BEGIN,
  FETCH_LESSONS_SUCCESS,
  FETCH_LESSONS_FAILURE,
  CREATE_LESSON_BEGIN,
  CREATE_LESSON_FAILURE,
  CREATE_LESSON_SUCCESS,
  REORDER_LESSONS_FAILURE,
  REORDER_LESSONS_SUCCESS,
  REORDER_LESSONS_BEGIN,
  PRELOAD_SINGLE_LESSON_MULTIMEDIA_BEGIN,
  PRELOAD_SINGLE_LESSON_MULTIMEDIA_FAILURE,
  PRELOAD_SINGLE_LESSON_MULTIMEDIA_SUCCESS,
} from '../../actions/lessons';

import {
  FETCH_MONTHS_BEGIN,
  FETCH_MONTHS_SUCCESS,
  FETCH_MONTHS_FAILURE,
  CREATE_MONTH_BEGIN,
  CREATE_MONTH_FAILURE,
  CREATE_MONTH_SUCCESS,
  FETCH_MONTH_BEGIN,
  FETCH_MONTH_FAILURE,
  FETCH_MONTH_SUCCESS,
  EDIT_MONTH_BEGIN,
  EDIT_MONTH_SUCCESS,
  EDIT_MONTH_FAILURE,
  REORDER_MONTHS_BEGIN,
  REORDER_MONTHS_FAILURE,
  REORDER_MONTHS_SUCCESS,
} from '../../actions/months';

import {
  FETCH_SECTIONS_BEGIN,
  FETCH_SECTIONS_SUCCESS,
  FETCH_SECTIONS_FAILURE,
  CREATE_SECTION_BEGIN,
  CREATE_SECTION_FAILURE,
  CREATE_SECTION_SUCCESS,
  FETCH_SECTION_TYPES_BEGIN,
  FETCH_SECTION_TYPES_FAILURE,
  FETCH_SECTION_TYPES_SUCCESS,
  REORDER_SECTIONS_BEGIN,
  REORDER_SECTIONS_FAILURE,
  REORDER_SECTIONS_SUCCESS,
} from '../../actions/sections';

import {
  FETCH_TERMS_BEGIN,
  FETCH_TERMS_SUCCESS,
  FETCH_TERMS_FAILURE,
  CREATE_TERM_BEGIN,
  CREATE_TERM_FAILURE,
  CREATE_TERM_SUCCESS,
  REORDER_TERMS_BEGIN,
  REORDER_TERMS_FAILURE,
  REORDER_TERMS_SUCCESS,
  EDIT_TERM_SUCCESS,
  EDIT_TERM_FAILURE,
} from '../../actions/terms';

import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  EDIT_FIRST_LOGIN_BEGIN,
  EDIT_FIRST_LOGIN_SUCCESS,
  EDIT_FIRST_LOGIN_FAILURE,
  SET_EMAIL_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_LINK_BEGIN,
  REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_LINK_FAILURE,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  EDIT_USER_SUCCESS,
  GET_USERS_SUCCESS,
} from '../../actions/auth';

import {
  ACTION_BEGIN,
  ACTION_FAILURE,
  SHOW_ERROR,
  HIDE_ERROR,
  HIDE_NAVBAR,
  SHOW_NAVBAR,
  HIDE_INFO_MODAL,
  SHOW_INFO_MODAL,
} from '../../actions/app';

import {
  EDIT_USER_LANG_BEGIN,
  EDIT_USER_LANG_SUCCESS,
  EDIT_USER_LANG_FAILURE,
  FETCH_LANGUAGES_BEGIN,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_FAILURE,
} from '../../actions/languages';

import {
  CREATE_CUSTOM_MULTIMEDIA_BEGIN,
  CREATE_CUSTOM_MULTIMEDIA_SUCCESS,
  CREATE_CUSTOM_MULTIMEDIA_FAILURE,
  FETCH_CUSTOM_MULTIMEDIA_BEGIN,
  FETCH_CUSTOM_MULTIMEDIA_SUCCESS,
  FETCH_CUSTOM_MULTIMEDIA_FAILURE,
  SET_DEFAULT_IMAGE_BEGIN,
  SET_DEFAULT_IMAGE_SUCCESS,
  SET_DEFAULT_IMAGE_FAILURE,
  HIDE_TERM_SUCCESS,
} from '../../actions/customMultimedia';

import {
  FETCH_REWARDS_BEGIN,
  FETCH_REWARDS_SUCCESS,
  FETCH_REWARDS_FAILURE,
} from '../../actions/rewards';

import {
  ACCOUNT_GENERATOR_BEGIN,
  ACCOUNT_GENERATOR_FAILURE,
  ACCOUNT_GENERATOR_SUCCESS,
} from '../../actions/accountGenerator';

import {
  CREATE_ORGANIZATION_USERS_SUCCESS,
  DEACTIVATE_ORGANIZATION_USERS_SUCCESS,
  FETCH_ORGANIZATION_USERS_SUCCESS,
} from '../../actions/organizationManagement';

import { FETCH_MONTH_LIMIT_SUCCESS, FETCH_SCORES_SUCCESS } from '../../actions/stats';

import { FETCH_ATTEMPTS_SUCCESS, INSERT_ATTEMPT_SUCCESS } from '../../actions/lessonsAttempts';

import { ACCOUNT_ADMINISTRATION_SUCCESS } from '../../actions/accountAdministration';

const initialState = {
  loading: false,
  errors: null,
  displayError: false,
  displayNavbar: true,
  displayInfoModal: false,
  startedActionsCounter: 0,
  selectedItem: '',
};

export default function appReducer(state = initialState, action) {
  let actionsCounter = 0;
  let errors;

  switch (action.type) {
    case ACTION_BEGIN:
    case FETCH_LESSON_BEGIN:
    case FETCH_LESSONS_BEGIN:
    case LOGIN_BEGIN:
    case REGISTER_BEGIN:
    case REQUEST_PASSWORD_RECOVERY_LINK_BEGIN:
    case RESET_PASSWORD_BEGIN:
    case LOGOUT_BEGIN:
    case EDIT_FIRST_LOGIN_BEGIN:
    case FETCH_MONTHS_BEGIN:
    case FETCH_SECTIONS_BEGIN:
    case FETCH_TERMS_BEGIN:
    case CREATE_MONTH_BEGIN:
    case REORDER_MONTHS_BEGIN:
    case REORDER_LESSONS_BEGIN:
    case REORDER_SECTIONS_BEGIN:
    case REORDER_TERMS_BEGIN:
    case CREATE_LESSON_BEGIN:
    case CREATE_SECTION_BEGIN:
    case CREATE_TERM_BEGIN:
    case FETCH_MONTH_BEGIN:
    case EDIT_MONTH_BEGIN:
    case FETCH_SECTION_TYPES_BEGIN:
    case EDIT_USER_LANG_BEGIN:
    case FETCH_LANGUAGES_BEGIN:
    case FETCH_CUSTOM_MULTIMEDIA_BEGIN:
    case SET_DEFAULT_IMAGE_BEGIN:
    case CREATE_CUSTOM_MULTIMEDIA_BEGIN:
    case FETCH_REWARDS_BEGIN:
    case PRELOAD_SINGLE_LESSON_MULTIMEDIA_BEGIN:
    case ACCOUNT_GENERATOR_BEGIN:
      return {
        ...state,
        loading: true,
        errors: null,
        displayError: false,
        startedActionsCounter: state.startedActionsCounter + 1,
      };

    case FETCH_ATTEMPTS_SUCCESS:
    case INSERT_ATTEMPT_SUCCESS:
    case FETCH_LESSON_SUCCESS:
    case FETCH_LESSONS_SUCCESS:
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
    case REQUEST_PASSWORD_RECOVERY_LINK_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case LOGOUT_SUCCESS:
    case EDIT_FIRST_LOGIN_SUCCESS:
    case SET_EMAIL_SUCCESS:
    case FETCH_MONTHS_SUCCESS:
    case FETCH_SECTIONS_SUCCESS:
    case FETCH_TERMS_SUCCESS:
    case CREATE_MONTH_SUCCESS:
    case CREATE_LESSON_SUCCESS:
    case CREATE_SECTION_SUCCESS:
    case CREATE_TERM_SUCCESS:
    case FETCH_MONTH_SUCCESS:
    case EDIT_MONTH_SUCCESS:
    case FETCH_SECTION_TYPES_SUCCESS:
    case REORDER_MONTHS_SUCCESS:
    case REORDER_LESSONS_SUCCESS:
    case REORDER_SECTIONS_SUCCESS:
    case REORDER_TERMS_SUCCESS:
    case EDIT_USER_LANG_SUCCESS:
    case EDIT_USER_SUCCESS:
    case FETCH_LANGUAGES_SUCCESS:
    case FETCH_CUSTOM_MULTIMEDIA_SUCCESS:
    case SET_DEFAULT_IMAGE_SUCCESS:
    case CREATE_CUSTOM_MULTIMEDIA_SUCCESS:
    case FETCH_REWARDS_SUCCESS:
    case PRELOAD_SINGLE_LESSON_MULTIMEDIA_SUCCESS:
    case ACCOUNT_GENERATOR_SUCCESS:
    case FETCH_ORGANIZATION_USERS_SUCCESS:
    case DEACTIVATE_ORGANIZATION_USERS_SUCCESS:
    case CREATE_ORGANIZATION_USERS_SUCCESS:
    case FETCH_SCORES_SUCCESS:
    case EDIT_TERM_SUCCESS:
    case HIDE_TERM_SUCCESS:
    case GET_USERS_SUCCESS:
    case FETCH_MONTH_LIMIT_SUCCESS:
    case ACCOUNT_ADMINISTRATION_SUCCESS:
      actionsCounter = state.startedActionsCounter - 1;

      return {
        ...state,
        loading: actionsCounter !== 0,
        startedActionsCounter: actionsCounter,
      };

    case ACTION_FAILURE:
    case FETCH_LESSON_FAILURE:
    case FETCH_LESSONS_FAILURE:
    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
    case REQUEST_PASSWORD_RECOVERY_LINK_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case LOGOUT_FAILURE:
    case EDIT_FIRST_LOGIN_FAILURE:
    case FETCH_MONTHS_FAILURE:
    case FETCH_SECTIONS_FAILURE:
    case FETCH_TERMS_FAILURE:
    case CREATE_MONTH_FAILURE:
    case CREATE_LESSON_FAILURE:
    case CREATE_SECTION_FAILURE:
    case CREATE_TERM_FAILURE:
    case FETCH_MONTH_FAILURE:
    case EDIT_MONTH_FAILURE:
    case FETCH_SECTION_TYPES_FAILURE:
    case REORDER_MONTHS_FAILURE:
    case REORDER_LESSONS_FAILURE:
    case REORDER_SECTIONS_FAILURE:
    case REORDER_TERMS_FAILURE:
    case EDIT_USER_LANG_FAILURE:
    case FETCH_LANGUAGES_FAILURE:
    case FETCH_CUSTOM_MULTIMEDIA_FAILURE:
    case SET_DEFAULT_IMAGE_FAILURE:
    case CREATE_CUSTOM_MULTIMEDIA_FAILURE:
    case FETCH_REWARDS_FAILURE:
    case PRELOAD_SINGLE_LESSON_MULTIMEDIA_FAILURE:
    case ACCOUNT_GENERATOR_FAILURE:
    case EDIT_TERM_FAILURE:
      actionsCounter = state.startedActionsCounter - 1;
      errors = action.payload.error.message.split(',');
      return {
        ...state,
        loading: actionsCounter !== 0,
        errors: Array.isArray(errors) ? errors : [errors],
        displayError: true,
        startedActionsCounter: actionsCounter,
      };

    case SHOW_ERROR:
      return {
        ...state,
        displayError: true,
      };

    case HIDE_ERROR:
      return {
        ...state,
        displayError: false,
      };

    case HIDE_NAVBAR:
      return {
        ...state,
        displayNavbar: false,
      };

    case SHOW_NAVBAR:
      return {
        ...state,
        displayNavbar: true,
        selectedItem: action.payload.selectedItem,
      };

    case HIDE_INFO_MODAL:
      return {
        ...state,
        displayInfoModal: false,
      };

    case SHOW_INFO_MODAL:
      return {
        ...state,
        displayInfoModal: true,
      };

    default:
      return state;
  }
}
