import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const PRELOAD_PROGRESS_BAR_MAX = 70;
const PRELOAD_PROGRESS_BAR_INTERVAL = 750;

/**
 * This is generic loading bar component. It shows the larger one of defaultProgress
 * or loadingProgress. defaultProgress increases periodically (interval length is defined
 * in PRELOAD_PROGRESS_BAR_INTERVAL) up to PRELOAD_PROGRESS_BAR_MAX. When loadingProgress
 * becomes higher than defaultProgress it will overtake the control of the loading bar.
 */
class LoadingBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultProgress: 1,
    };

    this.defaultProgressInterval = setInterval(() => {
      const oldDefaultProgress = this.state.defaultProgress;
      if (oldDefaultProgress < PRELOAD_PROGRESS_BAR_MAX) {
        this.setState({
          // increase it by random number between 0 - 15 each interval
          defaultProgress: oldDefaultProgress + Math.random() * 15,
        });
      }
    }, PRELOAD_PROGRESS_BAR_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.defaultProgressInterval);
  }

  render() {
    const { loadingProgress } = this.props;
    const { defaultProgress } = this.state;

    return (
      <div className="loading-div">
        <div
          className="loading-div__bar"
          style={{ width: `${Math.max(loadingProgress, defaultProgress)}%` }}
        />
      </div>
    );
  }
}

LoadingBar.propTypes = {
  loadingProgress: PropTypes.number.isRequired,
};

export default LoadingBar;
