import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import validation from '../../utils/validation';
import common from '../../utils/common';
import CropperModal from '../CropperModal';
import TermMultimediaEdit from '../TermMultimediaEdit';

import './index.css';

class CustomTermModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showROIEdit: false,
    };

    this.imageFileInput = React.createRef();
    this.cropper = React.createRef();
    this.messageModal = withReactContent(Swal);
  }

  onImageChange = e => {
    const { t } = this.props;
    if (validation.validFileType(e.target.files[0], validation.VALID_IMAGE_TYPES)) {
      this.setState({ showROIEdit: true });
    } else {
      common.renderMessageModal(this.messageModal, t('error.wrongImageType'), t('ok'));
      this.imageFileInput.current.value = null;
    }
  };

  selectImage = () => {
    this.imageFileInput.current.click();
  };

  getCroppedImage = () => {
    const croppedImage = this.cropper.current
      .getCroppedCanvas({ fillColor: '#fff' })
      .toDataURL('image/jpeg');

    this.props.onImageSelected({ imageRect: croppedImage }, this.props.termId);
    this.setState({ showROIEdit: false });
  };

  cancelCropping = () => {
    this.setState({ showROIEdit: false });
    this.imageFileInput.current.value = null;
  };

  renderTermPreview = () => {
    const {
      label,
      subLabel,
      multimediaUrl,
      month,
      onExit,
      onKeep,
      onHide,
      hideLabel,
      onDefault,
    } = this.props;

    return (
      <TermMultimediaEdit
        month={month}
        label={label}
        subLabel={subLabel}
        multimediaUrl={multimediaUrl}
        onSwitch={this.selectImage}
        onExit={onExit}
        hideLabel={hideLabel}
        onHide={onHide}
        onKeep={onKeep}
        onDefault={onDefault}
      />
    );
  };

  renderCropper = () => (
    <CropperModal
      cropperRef={this.cropper}
      aspectRatioW={16}
      aspectRatioH={9}
      src={window.URL.createObjectURL(this.imageFileInput.current.files[0])}
      onCropCancel={this.cancelCropping}
      onCropFinished={this.getCroppedImage}
    />
  );

  render() {
    const { show } = this.props;
    const { showROIEdit } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="modal">
        {showROIEdit ? this.renderCropper() : this.renderTermPreview()}
        <form id="custom-image-form">
          <input
            className="hidden"
            ref={this.imageFileInput}
            type="file"
            accept="image/jpeg"
            onClick={() => {
              this.imageFileInput.current.value = '';
            }}
            onChange={this.onImageChange}
          />
        </form>
      </div>
    );
  }
}

CustomTermModal.propTypes = {
  show: PropTypes.bool,
  month: PropTypes.string.isRequired,
  termId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  multimediaUrl: PropTypes.string.isRequired,
  onExit: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  hideLabel: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onKeep: PropTypes.func.isRequired,
  onDefault: PropTypes.func,
};

export default withNamespaces('translation')(CustomTermModal);
