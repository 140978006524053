import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import AuthScreen from '../AuthScreen';
import HomeScreen from '../HomeScreen';
import InstructionsScreen from '../InstructionsScreen';
import LessonScreen from '../LessonScreen';
import UsersScreen from '../UsersScreen';
import AdminMonthsScreen from '../AdminMonthsScreen';
import AdminLessonsScreen from '../AdminLessonsScreen';
import AdminSectionsScreen from '../AdminSectionsScreen';
import AdminTermsScreen from '../AdminTermsScreen';
import MonthFormScreen from '../MonthFormScreen';
import LessonFormScreen from '../LessonFormScreen';
import SectionFormScreen from '../SectionFormScreen';
import TermFormScreen from '../TermFormScreen';
import SettingsScreen from '../SettingsScreen';
import CustomMultimediaScreen from '../CustomMultimediaScreen';
import RewardsScreen from '../RewardsScreen';
import StatsScreen from '../StatsScreen';
import AccountGenerationScreen from '../AccountGenerationScreen';
import OrganizationManagementScreen from '../OrganizationManagementScreen';
import NotFound404 from '../../staticComponents/NotFound404';

import PrivateRoute from '../../components/common/PrivateRoute';
import App from '../../components/App';
import NavbarWrapper from '../NavbarWrapper';
import i18nInit from '../../config/i18n';
import roles from '../../constants/roles';

export class Root extends React.Component {
  componentDidMount() {
    i18nInit(this.props.lang);
  }

  render() {
    const { store, persistor } = this.props;
    const NORMAL_ROLES = [roles.REGULAR_ROLE, roles.DEMO_ROLE, roles.ADMIN_ROLE];

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <div>
              <Switch>
                <Route
                  exact
                  path="/:lang([a-z]{2})?/:authType(login|signup|forgotPassword|resetPassword)/:resetPasswordToken?"
                  component={AuthScreen}
                />
                <PrivateRoute
                  path="/admin/users"
                  component={UsersScreen}
                  allowedRoles={[roles.ADMIN_ROLE]}
                />
                <NavbarWrapper>
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/"
                      component={HomeScreen}
                      allowedRoles={NORMAL_ROLES}
                    />
                    <PrivateRoute
                      path="/lesson/:lessonId"
                      component={LessonScreen}
                      allowedRoles={NORMAL_ROLES}
                    />

                    <PrivateRoute
                      exact
                      path="/settings"
                      component={SettingsScreen}
                      allowedRoles={[...NORMAL_ROLES, roles.ORGANIZATION_MANAGER_ROLE]}
                    />

                    <PrivateRoute
                      exact
                      path="/custom-multimedia"
                      component={CustomMultimediaScreen}
                      allowedRoles={[roles.REGULAR_ROLE, roles.DEMO_ROLE, roles.ADMIN_ROLE]}
                    />

                    <PrivateRoute
                      exact
                      path="/rewards"
                      component={RewardsScreen}
                      allowedRoles={NORMAL_ROLES}
                    />

                    <PrivateRoute
                      exact
                      path="/instructions"
                      component={InstructionsScreen}
                      allowedRoles={NORMAL_ROLES}
                    />

                    <PrivateRoute
                      exact
                      path="/stats"
                      component={StatsScreen}
                      allowedRoles={NORMAL_ROLES}
                    />

                    <PrivateRoute
                      path="/admin/form/:monthId/:lessonId/:sectionId/term/:termId?"
                      component={TermFormScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />
                    <PrivateRoute
                      path="/admin/form/:monthId/:lessonId/section/:sectionId?"
                      component={SectionFormScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />
                    <PrivateRoute
                      path="/admin/form/:monthId/lesson/:lessonId?"
                      component={LessonFormScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />
                    <PrivateRoute
                      path="/admin/form/month/:monthId?"
                      component={MonthFormScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />

                    <PrivateRoute
                      path="/admin/:monthId/:lessonId/:sectionId"
                      component={AdminTermsScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />
                    <PrivateRoute
                      path="/admin/:monthId/:lessonId"
                      component={AdminSectionsScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />
                    <PrivateRoute
                      path="/admin/:monthId"
                      component={AdminLessonsScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />
                    <PrivateRoute
                      path="/admin"
                      component={AdminMonthsScreen}
                      allowedRoles={[roles.ADMIN_ROLE]}
                    />

                    <PrivateRoute
                      exact
                      path="/account-generator"
                      component={AccountGenerationScreen}
                      allowedRoles={[roles.ACCOUNT_GENERATOR_ROLE]}
                    />
                    <PrivateRoute
                      exact
                      path="/organization-management"
                      component={OrganizationManagementScreen}
                      allowedRoles={[roles.ORGANIZATION_MANAGER_ROLE]}
                    />
                    <Route component={NotFound404} />
                  </Switch>
                </NavbarWrapper>
              </Switch>
              <App />
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  lang: PropTypes.string,
};

const mapStateToProps = state => ({
  lang: state.auth.lang,
});

export default connect(mapStateToProps)(Root);
