import React from 'react';

import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchLanguages, updateUserLanguage } from '../../actions/languages';
import { showNavigationBar } from '../../actions/app';

import './index.css';

class LanguageSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      langId: '',
    };
  }

  componentWillMount() {
    const { dispatch, languages, selectedLang } = this.props;

    if (!languages || !languages.length) {
      dispatch(fetchLanguages());
    }
    this.setState({ langId: selectedLang });

    dispatch(showNavigationBar('settings'));
  }

  componentWillReceiveProps(nextProps) {
    const { selectedLang, i18n } = this.props;

    if (selectedLang !== nextProps.selectedLang) {
      i18n.changeLanguage(nextProps.selectedLang);
      this.setState({
        langId: nextProps.selectedLang,
      });
    }
  }

  handleChange = ({ target }) => this.setState({ langId: target.value });

  handleSubmit = e => {
    e.preventDefault();

    const { langId } = this.state;
    const { dispatch } = this.props;

    dispatch(updateUserLanguage({ langId }));
  };

  renderLanguages = ({ code, label }) => (
    <option key={code} value={code}>
      {label} ({code})
    </option>
  );

  render() {
    const { languages, t } = this.props;
    const { langId } = this.state;

    return (
      <form
        name="home-screen-form"
        className="language--section__form"
        onSubmit={this.handleSubmit}
      >
        <h1>{t('settings.lang.label')}</h1>
        <select name="langId" onChange={this.handleChange} value={langId || 'default'} required>
          <option value="default" disabled>
            {t('settings.lang.default')}
          </option>
          {languages.map(this.renderLanguages)}
        </select>

        <button className="language--section__form__button" type="submit">
          {t('settings.actions.edit')}
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  languages: state.languages.items,
  selectedLang: state.auth.lang,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(LanguageSetting);
