import React from 'react';

import './index.css';
import { withNamespaces } from 'react-i18next';

import forbiddenLogo from '../../images/staticPages/403/403_sign.png';

class Forbidden403 extends React.PureComponent {
  render() {
    const { t } = this.props;

    return (
      <div className="forbidden-page">
        <img className="forbidden-page__img" alt="Forbidden" src={forbiddenLogo} />
        <div className="forbidden-page__message">{t('error.youDoNotHavePermissionForThis')}</div>
      </div>
    );
  }
}

export default withNamespaces('translation')(Forbidden403);
