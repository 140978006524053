import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import { fetchRewards } from '../../actions/rewards';
import { showNavigationBar } from '../../actions/app';
import MonthNavigation from '../../components/MonthNavigation';
import CardList from '../../components/common/CardList';
import Card from '../../components/common/Card';
import Reward from '../../components/Reward';
import RewardModal from '../../components/RewardModal';

import './index.css';

class RewardsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonthIndex: 0,
      showRewardModal: false,
      monthForModal: '',
      lessonNameForModal: '',
      gameProgressForModal: '000000',
      puzzleImgUrlForModal: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchRewards());
    dispatch(showNavigationBar('rewards'));
  }

  nextMonth = () => {
    const { currentMonthIndex } = this.state;
    const { months } = this.props;

    if (currentMonthIndex === months.length - 1) {
      return;
    }

    this.setState({
      currentMonthIndex: currentMonthIndex + 1,
    });
  };

  previousMonth = () => {
    const { currentMonthIndex } = this.state;

    if (currentMonthIndex === 0) {
      return;
    }

    this.setState({
      currentMonthIndex: currentMonthIndex - 1,
    });
  };

  showRewardModal = (monthLabel, lessonLabel, gameProgress, puzzleImgUrl) => {
    this.setState({
      showRewardModal: true,
      monthForModal: monthLabel,
      lessonNameForModal: lessonLabel,
      gameProgressForModal: gameProgress,
      puzzleImgUrlForModal: puzzleImgUrl,
    });
  };

  renderLessonList = () => {
    const { months } = this.props;
    const { currentMonthIndex: index } = this.state;

    if (!months.length) {
      return null;
    }

    return (
      <div>
        <CardList>
          {!!months[index] &&
            !!months[index].lessons &&
            !!months[index].lessons.length &&
            months[index].lessons.map(
              lesson =>
                lesson.puzzle_img_url && (
                  <Card
                    rect
                    key={lesson.id}
                    label={lesson.label}
                    img={lesson.puzzle_img_url}
                    onClick={() =>
                      this.showRewardModal(
                        months[index].label,
                        lesson.label,
                        lesson.gameProgress,
                        lesson.puzzle_img_url,
                      )
                    }
                  >
                    <Reward
                      lessonCurrentGameProgress={
                        lesson.puzzle_img_url ? lesson.gameProgress : '111111'
                      }
                      lessonPuzzleImgUrl={lesson.puzzle_img_url}
                    />
                  </Card>
                ),
            )}
        </CardList>
      </div>
    );
  };

  render() {
    const {
      currentMonthIndex,
      showRewardModal,
      monthForModal,
      lessonNameForModal,
      gameProgressForModal,
      puzzleImgUrlForModal,
    } = this.state;
    const { t, months } = this.props;
    const currentMonthLabel = months[currentMonthIndex] && months[currentMonthIndex].label;

    return (
      <div className="rewards-screen">
        <div className="rewards-screen__header">
          <div className="rewards-screen__header__title">{t('navbar.rewards')}</div>
          <div className="rewards-screen__header__month-nav">
            <MonthNavigation
              label={currentMonthLabel}
              renderNavButtons
              goBack={this.previousMonth}
              goForward={this.nextMonth}
            />
          </div>
        </div>
        {this.renderLessonList()}
        {showRewardModal && (
          <RewardModal
            month={monthForModal}
            label={lessonNameForModal}
            onExit={() => this.setState({ showRewardModal: false })}
          >
            <Reward
              lessonCurrentGameProgress={gameProgressForModal}
              lessonPuzzleImgUrl={puzzleImgUrlForModal}
            />
          </RewardModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  months: state.rewards.months,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(RewardsScreen);
