import { network } from '../../utils';

export const SELECT_MONTH = 'SELECT_MONTH';
export const FETCH_MONTHS_BEGIN = 'FETCH_MONTHS_BEGIN';
export const FETCH_MONTHS_SUCCESS = 'FETCH_MONTHS_SUCCESS';
export const FETCH_MONTHS_FAILURE = 'FETCH_MONTHS_FAILURE';
export const CREATE_MONTH_BEGIN = 'CREATE_MONTH_BEGIN';
export const CREATE_MONTH_SUCCESS = 'CREATE_MONTH_SUCCESS';
export const CREATE_MONTH_FAILURE = 'CREATE_MONTH_FAILURE';
export const DELETE_MONTH_BEGIN = 'DELETE_MONTH_BEGIN';
export const DELETE_MONTH_SUCCESS = 'DELETE_MONTH_SUCCESS';
export const DELETE_MONTH_FAILURE = 'DELETE_MONTH_FAILURE';
export const EDIT_MONTH_FAILURE = 'EDIT_MONTH_FAILURE';
export const EDIT_MONTH_SUCCESS = 'EDIT_MONTH_SUCCESS';
export const EDIT_MONTH_BEGIN = 'EDIT_MONTH_BEGIN';
export const FETCH_MONTH_BEGIN = 'FETCH_MONTH_BEGIN';
export const FETCH_MONTH_FAILURE = 'FETCH_MONTH_FAILURE';
export const FETCH_MONTH_SUCCESS = 'FETCH_MONTH_SUCCESS';
export const REORDER_MONTHS_SUCCESS = 'REORDER_MONTHS_SUCCESS';
export const REORDER_MONTHS_BEGIN = 'REORDER_MONTHS_BEGIN';
export const REORDER_MONTHS_FAILURE = 'REORDER_MONTHS_FAILURE';

export const fetchMonths = () =>
  network.fetchActionWrapper(`months`, {
    begin: FETCH_MONTHS_BEGIN,
    success: FETCH_MONTHS_SUCCESS,
    failure: FETCH_MONTHS_FAILURE,
  });

export const fetchMonth = id =>
  network.fetchActionWrapper(`months/${id}`, {
    begin: FETCH_MONTH_BEGIN,
    success: FETCH_MONTH_SUCCESS,
    failure: FETCH_MONTH_FAILURE,
  });

export const selectMonth = id => ({
  type: SELECT_MONTH,
  id,
});

export const createMonth = data =>
  network.createActionWrapper(
    'months',
    {
      begin: CREATE_MONTH_BEGIN,
      success: CREATE_MONTH_SUCCESS,
      failure: CREATE_MONTH_FAILURE,
    },
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const updateMonth = (data, id) =>
  network.fetchActionWrapper(
    `months/${id}`,
    {
      begin: EDIT_MONTH_BEGIN,
      success: EDIT_MONTH_SUCCESS,
      failure: EDIT_MONTH_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );

export const deleteMonth = monthId =>
  network.fetchActionWrapper(
    `months/${monthId}`,
    {
      begin: DELETE_MONTH_BEGIN,
      success: DELETE_MONTH_SUCCESS,
      failure: DELETE_MONTH_FAILURE,
    },
    {
      method: 'DELETE',
    },
  );

export const updateMonthsOrder = data =>
  network.fetchActionWrapper(
    `yearHasMonths`,
    {
      begin: REORDER_MONTHS_BEGIN,
      success: REORDER_MONTHS_SUCCESS,
      failure: REORDER_MONTHS_FAILURE,
    },
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    },
  );
