import React from 'react';
import { useDrop } from 'react-dnd';

import './index.css';

export const DndContext = React.createContext({ isActive: false, draggedColor: '' });

const DndBox = ({ accept, onDrop, picture, pictureClass, children }) => {
  const [{ hoovered, canDrop, item }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: monitor => ({
      hoovered: monitor.isOver(),
      canDrop: monitor.canDrop(),
      item: monitor.getItem(),
    }),
  });
  const isActive = hoovered && canDrop;
  const draggedColor = item && item.colorIndex;

  return (
    <div ref={drop} className={picture ? 'picture-box' : ''}>
      {picture && <div className={pictureClass} style={{ backgroundImage: `url(${picture})` }} />}
      <DndContext.Provider value={{ isActive, draggedColor }}>{children}</DndContext.Provider>
    </div>
  );
};

export default DndBox;
