const TABLE_DROPDOWN = {
  YES_NO: [
    {
      value: 0,
      label: 'Ne',
    },
    {
      value: 1,
      label: 'Da',
    },
  ],
};

export default TABLE_DROPDOWN;
