import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { auth } from '../../utils';
import NAVBAR_ITEMS from './navbarItems';
import './index.css';

import logo from '../../images/logo.svg';

class Navbar extends React.PureComponent {
  renderNavbarItem = item =>
    auth.isRole(item.allowedRoles) && (
      <Link
        key={item.name}
        className={`navbar-links__item ${
          this.props.selected === item.name ? 'navbar-links__item--selected' : ''
        }`}
        to={item.destination}
      >
        {this.props.t(`navbar.${item.name}`)}
      </Link>
    );

  render() {
    const { t, lang, logout } = this.props;
    return (
      <div className="navbar">
        <img alt="logo" className="navbar-logo" src={logo} />
        <nav className="navbar-links">
          {NAVBAR_ITEMS.map(this.renderNavbarItem)}

          {auth.isAuthed() ? (
            <div
              className="navbar-links__item navbar-links__item--highlighted"
              onClick={logout}
              onKeyPress={logout}
              role="button"
            >
              {t('navbar.logout')}
            </div>
          ) : (
            <Link
              className="navbar-links__item navbar-links__item--highlighted"
              to={`${lang ? `/${lang}` : ''}/login`}
            >
              {t('navbar.login')}
            </Link>
          )}
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  selected: PropTypes.oneOf([
    'home',
    'myMultimedia',
    'instructions',
    'rewards',
    'admin',
    'settings',
    'stats',
    '',
    'user',
  ]),
  logout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Navbar;
