import { network } from '../../utils';

export const FETCH_REWARDS_BEGIN = 'FETCH_REWARDS_BEGIN';
export const FETCH_REWARDS_SUCCESS = 'FETCH_REWARDS_SUCCESS';
export const FETCH_REWARDS_FAILURE = 'FETCH_REWARDS_FAILURE';

export const fetchRewards = () =>
  network.fetchActionWrapper(
    'lessons/rewards',
    {
      begin: FETCH_REWARDS_BEGIN,
      success: FETCH_REWARDS_SUCCESS,
      failure: FETCH_REWARDS_FAILURE,
    },
    {},
  );
