import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';

class DragHandle extends React.PureComponent {
  render() {
    const { visible } = this.props;
    return <div className={`drag-handle ${!visible ? 'drag-handle--hidden' : ''}`}>::</div>;
  }
}

DragHandle.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default SortableHandle(DragHandle);
