import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import AdminList from '../../components/AdminList';
import { deleteTerm, fetchTerms, selectTerm, updateTermsOrder } from '../../actions/terms';

class AdminTermsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = { terms: props.terms };
  }

  componentWillMount() {
    const { dispatch, sectionId, match } = this.props;

    dispatch(fetchTerms(sectionId || match.params.sectionId));
  }

  componentWillReceiveProps(nextProps) {
    const { terms } = this.props;

    if (terms !== nextProps.terms) {
      this.setState({ terms: nextProps.terms });
    }
  }

  selectTermId = id => this.props.dispatch(selectTerm(id));

  deleteTerm = id => this.props.dispatch(deleteTerm(id));

  editTermsOrder = terms => {
    const { dispatch, match } = this.props;
    dispatch(updateTermsOrder(terms, match.params.sectionId));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { terms } = this.state;

    this.setState({
      terms: arrayMove(terms, oldIndex, newIndex),
    });
  };

  render() {
    const { match, t } = this.props;
    const { monthId, lessonId, sectionId } = match.params;
    const { terms } = this.state;

    return (
      <AdminList
        title="Pojmovi"
        itemColor="gray"
        onClick={this.selectTermId}
        onDelete={this.deleteTerm}
        to={match.url}
        items={terms}
        addLink={`/${monthId}/${lessonId}/${sectionId}/term`}
        onSortEnd={this.onSortEnd}
        useDragHandle
        updateItemsOrder={this.editTermsOrder}
        t={t}
      />
    );
  }
}

const mapStateToProps = state => ({
  terms: state.terms.items,
  sectionId: state.sections.selectedId,
});

export default compose(withNamespaces('translation'), connect(mapStateToProps))(AdminTermsScreen);
